
import { MessageSquare } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { ReviewsHeader } from "./ReviewsHeader";
import { Skeleton } from "@/components/ui/skeleton";

export const ReviewsComingSoon = () => {
  return (
    <div className="space-y-8">
      <ReviewsHeader onRefresh={() => {}} isDisabled={true} />
      
      <Card className="bg-background border-2 border-tertiary/10">
        <CardContent className="p-8 text-center space-y-6">
          <div className="animate-pulse w-24 h-24 mx-auto text-tertiary/50">
            <MessageSquare className="w-full h-full" />
          </div>
          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-secondary">Your reviews are coming soon...</h2>
            <p className="text-muted-foreground">
              Our team is working on setting up your campaign. Please check back soon.
            </p>
          </div>
        </CardContent>
      </Card>

      <div className="grid gap-4 md:grid-cols-3">
        {[1, 2, 3].map((i) => (
          <Card key={i} className="p-6">
            <div className="space-y-4">
              <Skeleton className="h-4 w-[100px]" />
              <Skeleton className="h-8 w-[120px]" />
            </div>
          </Card>
        ))}
      </div>

      <Card>
        <div className="p-6 space-y-6">
          {[1, 2, 3].map((i) => (
            <div key={i} className="space-y-2">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};
