
import BusinessInfoForm from "@/components/business/BusinessInfoForm";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const BusinessProfile = () => {
  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-4xl mx-auto">
              <BusinessInfoForm />
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default BusinessProfile;
