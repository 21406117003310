
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Subscription } from "@/integrations/supabase/types/subscriptions";

export const useSubscriptionData = (options: {
  enableAutoRecovery?: boolean;
  includeTxHistory?: boolean;
  refetchInterval?: number | false;
  email?: string;
} = {}) => {
  const { 
    enableAutoRecovery = true, 
    includeTxHistory = false,
    refetchInterval = false,
    email = null
  } = options;
  const { toast } = useToast();

  // Create the query with the provided options
  const {
    data: subscription,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: ['subscription-data', includeTxHistory, email],
    queryFn: async () => {
      console.log('[useSubscriptionData] Fetching subscription data...', { email });
      const { data: { user } } = await supabase.auth.getUser();
      if (!user && !email) {
        console.log('[useSubscriptionData] No authenticated user found and no email provided');
        return null;
      }

      try {
        // First attempt: Try to get user subscription directly as a profile user
        if (user) {
          console.log('[useSubscriptionData] Attempting to fetch profile subscription directly');
          
          // Build separate queries for subscription and related data to avoid type issues
          const { data: subscriptionData, error: subscriptionError } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('user_id', user.id)
            .eq('user_type', 'profile')
            .order('created_at', { ascending: false })
            .limit(1)
            .maybeSingle();
          
          if (subscriptionError) {
            console.error('[useSubscriptionData] Error fetching subscription:', subscriptionError);
            throw subscriptionError;
          }
          
          if (subscriptionData) {
            console.log('[useSubscriptionData] Found profile subscription:', subscriptionData);
            
            // If we need payment methods and transactions, fetch them separately
            let paymentMethodData = null;
            let transactionsData = [];
            
            if (subscriptionData.payment_method_id) {
              const { data: pmData } = await supabase
                .from('payment_methods')
                .select('card_type, last_four, expiry_month, expiry_year')
                .eq('id', subscriptionData.payment_method_id)
                .maybeSingle();
              
              paymentMethodData = pmData;
            }
            
            if (includeTxHistory) {
              const { data: txData } = await supabase
                .from('payment_transactions')
                .select('id, amount, status, created_at')
                .eq('subscription_id', subscriptionData.id)
                .order('created_at', { ascending: false });
              
              transactionsData = txData || [];
            }
            
            // Combine all the data
            return {
              ...subscriptionData,
              payment_methods: paymentMethodData,
              payment_transactions: transactionsData
            };
          }
          
          // If no profile subscription, check if there's a temp user reference and try that
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('temp_user_reference')
            .eq('id', user.id)
            .maybeSingle();
          
          if (profileError) {
            console.error('[useSubscriptionData] Error fetching profile:', profileError);
            throw profileError;
          }
          
          if (profileData && profileData.temp_user_reference) {
            console.log('[useSubscriptionData] Checking temp user reference:', profileData.temp_user_reference);
            
            const { data: tempSubscriptionData, error: tempSubError } = await supabase
              .from('subscriptions')
              .select('*')
              .eq('user_id', profileData.temp_user_reference)
              .eq('user_type', 'temp')
              .order('created_at', { ascending: false })
              .limit(1)
              .maybeSingle();
            
            if (tempSubError) {
              console.error('[useSubscriptionData] Error fetching temp subscription:', tempSubError);
              throw tempSubError;
            }
            
            if (tempSubscriptionData) {
              console.log('[useSubscriptionData] Found temp user subscription');
              
              // Fetch related data
              let paymentMethodData = null;
              let transactionsData = [];
              
              if (tempSubscriptionData.payment_method_id) {
                const { data: pmData } = await supabase
                  .from('payment_methods')
                  .select('card_type, last_four, expiry_month, expiry_year')
                  .eq('id', tempSubscriptionData.payment_method_id)
                  .maybeSingle();
                
                paymentMethodData = pmData;
              }
              
              if (includeTxHistory) {
                const { data: txData } = await supabase
                  .from('payment_transactions')
                  .select('id, amount, status, created_at')
                  .eq('subscription_id', tempSubscriptionData.id)
                  .order('created_at', { ascending: false });
                
                transactionsData = txData || [];
              }
              
              if (enableAutoRecovery) {
                console.log('[useSubscriptionData] Auto-recovering temp subscription');
                
                // Migrate the subscription to the profile user
                const { error: updateError } = await supabase
                  .from('subscriptions')
                  .update({
                    user_id: user.id,
                    user_type: 'profile',
                    updated_at: new Date().toISOString()
                  })
                  .eq('id', tempSubscriptionData.id);
                
                if (updateError) {
                  console.error('[useSubscriptionData] Error migrating subscription:', updateError);
                } else {
                  console.log('[useSubscriptionData] Successfully migrated subscription');
                  
                  // Also update related payment transactions
                  const { error: txUpdateError } = await supabase
                    .from('payment_transactions')
                    .update({
                      user_id: user.id,
                      user_type: 'profile',
                      updated_at: new Date().toISOString()
                    })
                    .eq('subscription_id', tempSubscriptionData.id);
                  
                  if (txUpdateError) {
                    console.error('[useSubscriptionData] Error updating transactions:', txUpdateError);
                  }
                }
              }
              
              // Return the subscription with the related data
              return {
                ...tempSubscriptionData,
                payment_methods: paymentMethodData,
                payment_transactions: transactionsData,
                ...(enableAutoRecovery ? { user_id: user.id, user_type: 'profile' } : {})
              };
            }
          }
        }
        
        // No subscription found
        console.log('[useSubscriptionData] No subscription found after all attempts');
        return null;
      } catch (error) {
        console.error('[useSubscriptionData] Error:', error);
        return null;
      }
    },
    refetchInterval,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000, // 1 minute
    retry: 2
  });

  // Function to manually recover subscriptions
  const recoverSubscription = async (options: { 
    tempUserId?: string; 
    email?: string;
  } = {}): Promise<{ success: boolean; message: string }> => {
    try {
      const { tempUserId, email } = options;
      console.log('[useSubscriptionData] Manual subscription recovery triggered', { tempUserId, email });
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        return { success: false, message: 'Not authenticated' };
      }
      
      // Get user profile for temp_user_reference
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('temp_user_reference, email')
        .eq('id', user.id)
        .maybeSingle();
        
      if (profileError) {
        console.error('[useSubscriptionData] Error fetching profile:', profileError);
        return { success: false, message: 'Error fetching user profile: ' + profileError.message };
      }
        
      console.log('[useSubscriptionData] User profile:', profileData);
        
      // Use either the provided tempUserId or the one from the profile
      let effectiveTempUserId = tempUserId || profileData?.temp_user_reference || null;
      const userEmail = email || profileData?.email || user.email;
      
      console.log('[useSubscriptionData] Recovery parameters:', { 
        effectiveTempUserId, 
        userEmail, 
        userId: user.id 
      });
      
      if (effectiveTempUserId) {
        // Find subscription with temp user ID
        const { data: tempSubscription, error: tempSubError } = await supabase
          .from('subscriptions')
          .select('*')
          .eq('user_id', effectiveTempUserId)
          .eq('user_type', 'temp')
          .maybeSingle();
          
        if (tempSubError) {
          console.error('[useSubscriptionData] Error finding temp subscription:', tempSubError);
          return { 
            success: false, 
            message: 'Error finding temporary subscription: ' + tempSubError.message 
          };
        } 
        
        if (tempSubscription) {
          console.log('[useSubscriptionData] Found temp subscription:', tempSubscription);
          
          // Migrate subscription to profile
          const { error: updateError } = await supabase
            .from('subscriptions')
            .update({
              user_id: user.id,
              user_type: 'profile',
              updated_at: new Date().toISOString()
            })
            .eq('id', tempSubscription.id);
            
          if (updateError) {
            console.error('[useSubscriptionData] Error migrating subscription:', updateError);
            return { 
              success: false, 
              message: 'Error migrating subscription: ' + updateError.message 
            };
          }
          
          // Update related payment transactions
          const { error: txUpdateError } = await supabase
            .from('payment_transactions')
            .update({
              user_id: user.id,
              user_type: 'profile',
              updated_at: new Date().toISOString()
            })
            .eq('subscription_id', tempSubscription.id);
            
          if (txUpdateError) {
            console.error('[useSubscriptionData] Error updating transactions:', txUpdateError);
          }
          
          // Refresh subscription data
          refetch();
          
          return { 
            success: true, 
            message: 'Successfully recovered your subscription!' 
          };
        }
      }
      
      // If we reach here, no subscription was found to recover
      return { 
        success: false, 
        message: 'Unable to recover subscription. No matching temporary subscription found.' 
      };
    } catch (error: any) {
      console.error('[useSubscriptionData] Recovery error:', error);
      return { 
        success: false, 
        message: error.message || 'An unexpected error occurred' 
      };
    }
  };

  return {
    subscription,
    isLoading,
    error,
    refetch,
    recoverSubscription
  };
};
