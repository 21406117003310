
import { useState } from "react";
import { PaymentForm } from "@/components/billing/PaymentForm";
import { usePackageData } from "@/hooks/usePackageData";
import { Card } from "@/components/ui/card";
import { TermsAgreement } from "./checkout/TermsAgreement";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface CheckoutStepProps {
  packageType: "maps_seo" | "maps_website_seo";
  onComplete: (refId?: string) => void;
  customerInfo?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
  businessInfo?: {
    name: string;
    category: string;
    websiteUrl?: string;
    phoneNumber?: string;
  };
}

export const CheckoutStep = ({ packageType, onComplete, customerInfo, businessInfo }: CheckoutStepProps) => {
  const { data: packageDetails, isLoading } = usePackageData(packageType);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", url: "" });

  const handleOpenModal = (title: string, url: string) => {
    setModalContent({ title, url });
    setModalOpen(true);
  };

  const handlePaymentStart = (refId: string) => {
    console.log("Payment started with reference ID:", refId);
    onComplete(refId);
  };

  if (isLoading || !packageDetails) {
    return <div>Loading package details...</div>;
  }

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl font-semibold">Billing & Payment Information</h1>
        <p className="text-muted-foreground">Enter your billing details to complete the purchase of your {packageDetails.name}.</p>
      </div>

      {/* Business Summary Section */}
      {businessInfo && (
        <Card className="p-4 bg-blue-50 border-blue-100">
          <h3 className="text-sm font-medium text-blue-800 mb-2">Business Information Summary</h3>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div><span className="text-blue-600 font-medium">Business:</span> {businessInfo.name}</div>
            <div><span className="text-blue-600 font-medium">Category:</span> {businessInfo.category}</div>
            {businessInfo.phoneNumber && (
              <div><span className="text-blue-600 font-medium">Phone:</span> {businessInfo.phoneNumber}</div>
            )}
          </div>
        </Card>
      )}

      <Card className="p-6 bg-secondary/10">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">{packageDetails.name}</h3>
            <ul className="mt-2 space-y-1">
              {packageDetails.features.map((feature, index) => (
                <li key={index} className="text-sm flex items-center gap-2">
                  <span className="text-green-500">✓</span> {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="text-right">
            <p className="text-2xl font-bold">${packageDetails.base_price}/mo</p>
            <p className="text-sm text-muted-foreground">Your business will be optimized by our experts—no DIY, no hassle, just results.</p>
          </div>
        </div>
      </Card>

      <TermsAgreement 
        termsAgreed={termsAgreed} 
        onTermsChange={setTermsAgreed} 
        onOpenModal={handleOpenModal}
      />

      {termsAgreed && (
        <PaymentForm
          packageType={packageType}
          amount={packageDetails.base_price}
          onPaymentStart={handlePaymentStart}
          isSignupFlow={true}
          customerInfo={customerInfo}
          businessInfo={businessInfo}
        />
      )}
      
      <Dialog open={modalOpen} onOpenChange={setModalOpen}>
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{modalContent.title}</DialogTitle>
          </DialogHeader>
          <iframe 
            src={modalContent.url} 
            className="w-full h-[60vh] border-0"
            title={modalContent.title}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CheckoutStep;
