
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SubscriptionRow } from "./SubscriptionRow";
import { useEffect, useState } from "react";
import { Loader2, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";

interface SubscriptionListProps {
  subscriptions: any[];
  activeTab: string;
  isLoading: boolean;
}

export const SubscriptionList = ({ subscriptions, activeTab, isLoading }: SubscriptionListProps) => {
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<any[]>([]);

  useEffect(() => {
    console.log('Filtering subscriptions for tab:', activeTab);
    console.log('Original subscriptions count:', subscriptions?.length);
    
    // Safely handle the case where subscriptions is undefined or not an array
    if (!subscriptions || !Array.isArray(subscriptions)) {
      setFilteredSubscriptions([]);
      return;
    }
    
    // Filter out any null/undefined items in the subscriptions array
    const validSubscriptions = subscriptions.filter(sub => sub !== null && sub !== undefined);
    
    const filtered = validSubscriptions.filter((sub) => {
      // Normalize and validate status values for consistent comparison
      const status = typeof sub.status === 'string' ? sub.status.toLowerCase() : '';
      const authorizeStatus = typeof sub.authorize_status === 'string' ? sub.authorize_status.toLowerCase() : '';
      
      // Combine statuses to check against either source (database or Authorize.net)
      const combinedStatus = [status, authorizeStatus].filter(Boolean); // Filter out empty status values
      
      // Skip items with no valid status at all
      if (combinedStatus.length === 0) return false;
      
      // Handle different tab filters
      if (activeTab === "active") {
        return combinedStatus.some(s => 
          ['active', 'trial'].includes(s)
        );
      }
      
      if (activeTab === "pending") {
        return combinedStatus.some(s => s === 'pending');
      }
      
      if (activeTab === "cancelled") {
        return combinedStatus.some(s => 
          ['cancelled', 'canceled', 'terminated', 'expired'].includes(s)
        );
      }
      
      if (activeTab === "expiring") {
        return !!sub.has_expiring_card;
      }
      
      if (activeTab === "declined") {
        return !!sub.has_declined_transaction;
      }
      
      // "all" tab - return all valid subscriptions
      return true;
    });
    
    console.log('Filtered subscriptions count:', filtered.length);
    setFilteredSubscriptions(filtered);
  }, [subscriptions, activeTab]);

  if (isLoading) {
    return (
      <div className="p-8 text-center flex justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (!subscriptions || subscriptions.length === 0) {
    return (
      <Alert>
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          No subscriptions found in the system
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Client</TableHead>
            <TableHead>Package</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Next Billing</TableHead>
            <TableHead>Payment Status</TableHead>
            <TableHead>Alerts</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredSubscriptions && filteredSubscriptions.length > 0 ? (
            filteredSubscriptions.map((subscription) => (
              <SubscriptionRow 
                key={subscription.id} 
                subscription={subscription} 
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center py-4">
                No subscriptions match the current filter
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
