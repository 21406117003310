
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface PaymentData {
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  billingInfo: {
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    phoneNumber?: string;
  };
  businessInfo?: {
    name: string;
    category: string;
    websiteUrl?: string;
    phoneNumber?: string;
  };
}

export const usePaymentSubmission = (
  packageType: string,
  amount: number,
  onStart: (refId: string) => void,
  isSignupFlow: boolean = false,
  isFreeTrial: boolean = false,
  trialDurationMonths: number = 0,
  postTrialDiscountPercentage: number | null = null,
  partnerCode: string = ""
) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { toast } = useToast();

  const updateSubscriptionPolicies = async (maxRetries = 3): Promise<boolean> => {
    let retries = 0;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        console.log(`Updating subscription RLS policies (attempt ${retries + 1})...`);
        const { data, error } = await supabase.functions.invoke('update-subscription-policy');
        
        if (error) {
          console.warn(`Error updating subscription policies (attempt ${retries + 1}):`, error);
          retries++;
          
          const delay = Math.pow(2, retries) * 1000;
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        }
        
        console.log('Subscription policies updated successfully:', data);
        success = true;
        return true;
      } catch (error) {
        console.warn(`Exception updating subscription policies (attempt ${retries + 1}):`, error);
        retries++;
        
        if (retries < maxRetries) {
          const delay = Math.pow(2, retries) * 1000;
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    }
    
    if (!success) {
      console.error('Failed to update subscription policies after all retry attempts');
    }
    
    return success;
  };

  const sendSubscriptionNotification = async (
    customerInfo: PaymentData['billingInfo'],
    businessInfo: PaymentData['businessInfo'] | undefined,
    refId: string,
    isFreeTrial: boolean,
    amount: number,
    trialDurationMonths: number = 0,
    postTrialDiscountPercentage: number | null = null,
    maxRetries = 3
  ) => {
    let retryCount = 0;
    let success = false;

    let paymentTerm = "Monthly";
    if (isFreeTrial) {
      if (postTrialDiscountPercentage) {
        const postTrialAmount = Number((amount * (1 - postTrialDiscountPercentage / 100)).toFixed(2));
        paymentTerm = `${trialDurationMonths}-month free trial, then $${postTrialAmount}/month (${postTrialDiscountPercentage}% off)`;
      } else {
        paymentTerm = `${trialDurationMonths}-month free trial, then $${amount}/month`;
      }
    }

    while (retryCount < maxRetries && !success) {
      try {
        console.log(`Sending subscription notification email (attempt ${retryCount + 1})...`);
        
        const defaultBusinessInfo = {
          name: customerInfo.firstName + " " + customerInfo.lastName + "'s Business",
          category: "Not Specified",
        };
        
        const { data, error } = await supabase.functions.invoke(
          'send-subscription-notification',
          {
            body: {
              customerInfo,
              businessInfo: businessInfo || defaultBusinessInfo,
              packageType,
              refId,
              isFreeTrial,
              amount,
              paymentTerm,
              trialDurationMonths,
              postTrialDiscountPercentage,
              partnerCode
            }
          }
        );

        if (error) {
          console.error(`Error sending notification email (attempt ${retryCount + 1}):`, error);
          retryCount++;
          
          if (retryCount < maxRetries) {
            const delay = Math.pow(2, retryCount) * 1000;
            console.log(`Retrying in ${delay}ms...`);
            await new Promise(resolve => setTimeout(resolve, delay));
          }
        } else {
          console.log('Notification email sent successfully:', data);
          success = true;
        }
      } catch (error) {
        console.error(`Exception sending notification email (attempt ${retryCount + 1}):`, error);
        retryCount++;
        
        if (retryCount < maxRetries) {
          const delay = Math.pow(2, retryCount) * 1000;
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    }
    
    if (!success) {
      console.error('Failed to send notification email after all retry attempts');
    }
  };

  const ensureTransactionIsLinked = async (refId: string, tempUserId?: string): Promise<boolean> => {
    try {
      console.log(`Ensuring transaction is properly linked for refId: ${refId}`);
      
      // First try to link the transaction
      const { data, error } = await supabase.functions.invoke(
        'fix-existing-subscriptions',
        {
          body: { 
            refId, 
            fixType: 'link_transaction',
            tempUserId 
          }
        }
      );

      if (error) {
        console.error('Error ensuring transaction is linked:', error);
        
        // If linking fails, try to recreate the subscription
        console.log('Attempting to recreate subscription as fallback');
        const { data: recreateData, error: recreateError } = await supabase.functions.invoke(
          'fix-existing-subscriptions',
          {
            body: { 
              refId, 
              fixType: 'recreate_subscription',
              tempUserId
            }
          }
        );
        
        if (recreateError) {
          console.error('Error recreating subscription:', recreateError);
          return false;
        }
        
        console.log('Recreate subscription result:', recreateData);
        return recreateData?.success || false;
      }

      console.log('Transaction link check result:', data);
      return data?.success || false;
    } catch (error) {
      console.error('Exception ensuring transaction is linked:', error);
      return false;
    }
  };

  const handlePayment = async (paymentData: PaymentData) => {
    setIsProcessing(true);
    console.log('Starting payment submission process...');
    console.log('Is signup flow:', isSignupFlow);
    console.log('Is free trial:', isFreeTrial);
    console.log('Trial duration (months):', trialDurationMonths);
    console.log('Post-trial discount percentage:', postTrialDiscountPercentage);
    console.log('Package type:', packageType);
    console.log('Partner code:', partnerCode);

    try {
      await updateSubscriptionPolicies();

      let userId: string;
      let userType: 'profile' | 'temp' = 'profile';

      if (!isSignupFlow) {
        // Get the current user session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw new Error('Authentication error: ' + sessionError.message);
        }

        if (!session) {
          console.error('No active session found');
          throw new Error('Not authenticated');
        }

        userId = session.user.id;
      } else {
        // Create a temporary user data record
        const { data: tempUser, error: tempUserError } = await supabase
          .from('temp_user_data')
          .insert({
            email: paymentData.billingInfo.email,
            first_name: paymentData.billingInfo.firstName,
            last_name: paymentData.billingInfo.lastName,
            street_address: paymentData.billingInfo.street,
            city: paymentData.billingInfo.city,
            state: paymentData.billingInfo.state,
            zip_code: paymentData.billingInfo.zipCode,
            phone_number: paymentData.billingInfo.phoneNumber,
            package_type: packageType,
            business_name: paymentData.businessInfo?.name,
            completed: false
          })
          .select()
          .single();

        if (tempUserError) {
          console.error('Error creating temporary user:', tempUserError);
          throw new Error('Failed to create temporary user record: ' + tempUserError.message);
        }

        userId = tempUser.id;
        userType = 'temp';
        console.log('Created temp user with ID:', userId);
      }

      // Create the subscription using our improved function
      try {
        console.log('Calling unified create-subscription edge function');
        
        const { data: subscriptionData, error: subscriptionError } = await supabase.functions.invoke(
          'create-subscription',
          {
            body: {
              cardData: {
                cardNumber: paymentData.cardNumber.replace(/\s/g, ''),
                expirationDate: paymentData.expiryDate.replace(/\//g, ''),
                cardCode: paymentData.cvv
              },
              billingInfo: {
                ...paymentData.billingInfo,
                userId,
                userType
              },
              amount,
              packageType,
              isFreeTrial,
              trialDurationMonths,
              postTrialDiscountPercentage,
              partnerCode
            }
          }
        );

        if (subscriptionError) {
          console.error('Subscription creation error:', subscriptionError);
          throw new Error(subscriptionError.message || 'Failed to create subscription');
        }

        console.log('Subscription created successfully:', subscriptionData);

        if (!subscriptionData.success) {
          console.error('Subscription creation failed:', subscriptionData.message);
          throw new Error(subscriptionData.message || 'Failed to create subscription');
        }

        // Send notification email in the background
        sendSubscriptionNotification(
          paymentData.billingInfo,
          paymentData.businessInfo,
          subscriptionData.refId,
          isFreeTrial,
          amount,
          trialDurationMonths,
          postTrialDiscountPercentage
        ).catch(error => {
          console.error('Failed to send subscription notification:', error);
        });
        
        // When in signup flow, store the temp user ID with the payment so it can be linked
        // to the profile later
        if (isSignupFlow && userType === 'temp') {
          try {
            // Store temporary user reference in session storage for later association
            sessionStorage.setItem('temp_user_id', userId);
            sessionStorage.setItem('payment_ref_id', subscriptionData.refId);
            console.log('Stored temp user ID in session storage for later profile linking:', {
              tempUserId: userId,
              refId: subscriptionData.refId
            });
          } catch (storageError) {
            console.error('Failed to store temp user ID in session storage:', storageError);
            // Non-critical error, continue anyway
          }
        }
        
        // Ensure the transaction is properly linked to the subscription
        ensureTransactionIsLinked(subscriptionData.refId, userType === 'temp' ? userId : undefined)
          .then(success => {
            if (success) {
              console.log('Successfully verified transaction linkage');
            } else {
              console.warn('Could not verify transaction linkage - may need manual fixing');
            }
          })
          .catch(error => {
            console.error('Error verifying transaction linkage:', error);
          });
        
        // Let the parent component know the subscription was created
        onStart(subscriptionData.refId);
      } catch (paymentError: any) {
        console.error('Payment processing error:', paymentError);
        throw paymentError;
      }
    } catch (error: any) {
      console.error('Payment error:', error);
      console.error('Full error details:', {
        message: error.message,
        code: error.code,
        details: error.details
      });
      
      toast({
        title: "Payment Failed",
        description: error.message || "There was an error processing your payment",
        variant: "destructive",
      });
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    handlePayment,
    isProcessing
  };
};
