
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { format } from 'date-fns';
import { User, UserCheck, UserCog, UserX } from 'lucide-react';
import { Profile } from "@/integrations/supabase/types/profiles";

type SimplifiedBusinessProfile = {
  business_name: string;
  category: string;
  city: string;
  state: string;
};

type SimplifiedSubscription = {
  status: string;
  package_type: string;
  price: number;
  created_at: string;
  next_billing_date: string | null;
  id: string;
};

type ExtendedProfile = Profile & {
  business_profiles?: SimplifiedBusinessProfile[];
  subscriptions?: SimplifiedSubscription[];
};

type UserDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: ExtendedProfile | null;
};

export function UserDetailsModal({ isOpen, onClose, user }: UserDetailsModalProps) {
  if (!user) return null;

  const getStatusIcon = (subscriptionStatus: string | null) => {
    switch (subscriptionStatus) {
      case 'active':
        return <UserCheck className="h-4 w-4 text-green-500" />;
      case 'suspended':
        return <UserX className="h-4 w-4 text-red-500" />;
      case 'pending':
        return <UserCog className="h-4 w-4 text-yellow-500" />;
      default:
        return <User className="h-4 w-4 text-gray-500" />;
    }
  };

  const getRoleBadge = (role: string | null, isAdmin: boolean | null, isPartner: boolean | null) => {
    if (isAdmin) {
      return <Badge className="bg-red-100 text-red-800">Admin</Badge>;
    }
    if (isPartner) {
      return <Badge className="bg-purple-100 text-purple-800">Partner</Badge>;
    }
    return <Badge className="bg-blue-100 text-blue-800">User</Badge>;
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>User Details</DialogTitle>
          <DialogDescription>
            Comprehensive information about {user.first_name} {user.last_name}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-sm font-medium text-gray-500">Personal Information</h3>
              <div className="mt-2 space-y-2">
                <p><span className="font-medium">Name:</span> {user.first_name} {user.last_name}</p>
                <p><span className="font-medium">Email:</span> {user.email}</p>
                <p><span className="font-medium">Phone:</span> {user.phone_number || 'N/A'}</p>
                <p><span className="font-medium">Role:</span> {getRoleBadge(user.role, user.is_admin, user.is_partner)}</p>
                <p><span className="font-medium">Joined:</span> {user.created_at ? format(new Date(user.created_at), 'MMM d, yyyy') : 'N/A'}</p>
              </div>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500">Business Details</h3>
              <div className="mt-2 space-y-2">
                {user.business_profiles && user.business_profiles.length > 0 ? (
                  <>
                    <p>
                      <span className="font-medium">Business Name:</span>{' '}
                      {user.business_profiles[0].business_name}
                    </p>
                    <p><span className="font-medium">Category:</span> {user.business_profiles[0].category}</p>
                    <p><span className="font-medium">Location:</span> {user.business_profiles[0].city}, {user.business_profiles[0].state}</p>
                  </>
                ) : (
                  <p>No business profile associated</p>
                )}
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Subscription History</h3>
            {user.subscriptions && user.subscriptions.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Status</TableHead>
                    <TableHead>Package</TableHead>
                    <TableHead>Price</TableHead>
                    <TableHead>Start Date</TableHead>
                    <TableHead>Next Billing</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {user.subscriptions.map((sub) => (
                    <TableRow key={sub.id}>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          {getStatusIcon(sub.status)}
                          {sub.status}
                        </div>
                      </TableCell>
                      <TableCell>{sub.package_type}</TableCell>
                      <TableCell>${sub.price}</TableCell>
                      <TableCell>
                        {sub.created_at ? format(new Date(sub.created_at), 'MMM d, yyyy') : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {sub.next_billing_date
                          ? format(new Date(sub.next_billing_date), 'MMM d, yyyy')
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="text-sm text-gray-500 italic">No subscription history available</div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
