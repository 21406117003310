
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { FormBusinessProfile } from "@/components/business/types";

interface AddressFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const AddressFields = ({ profile, setProfile }: AddressFieldsProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Address Information</h3>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="streetAddress">Street Address</Label>
          <Input
            id="streetAddress"
            value={profile.street_address}
            onChange={(e) => setProfile({ ...profile, street_address: e.target.value })}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="city">City</Label>
          <Input
            id="city"
            value={profile.city}
            onChange={(e) => setProfile({ ...profile, city: e.target.value })}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="state">State</Label>
          <Input
            id="state"
            value={profile.state}
            onChange={(e) => setProfile({ ...profile, state: e.target.value })}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="zipCode">ZIP Code</Label>
          <Input
            id="zipCode"
            value={profile.zip_code}
            onChange={(e) => setProfile({ ...profile, zip_code: e.target.value })}
            required
          />
        </div>
      </div>
    </div>
  );
};
