
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { supabase } from "@/integrations/supabase/client";
import { AlertTriangle, Check, CheckCircle, Clock, RefreshCw, RotateCcw } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useToast } from "@/hooks/use-toast";

export const SubscriptionFixer = () => {
  const [refId, setRefId] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBatchFixing, setIsBatchFixing] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState("fix-subscription");
  const { toast } = useToast();

  const fixSubscription = async (fixType: 'recreate_subscription' | 'link_transaction') => {
    if (!refId) {
      setError("Reference ID is required");
      return;
    }

    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      console.log(`Attempting to fix subscription with reference ID: ${refId}`);
      
      const { data, error } = await supabase.functions.invoke('fix-trial-subscription', {
        body: { refId, fixType }
      });

      if (error) {
        console.error('Error fixing subscription:', error);
        setError(error.message);
        toast({
          title: "Error",
          description: `Failed to fix subscription: ${error.message}`,
          variant: "destructive",
        });
      } else {
        console.log('Fix result:', data);
        setResult(data);

        if (data.success) {
          toast({
            title: "Success",
            description: data.message,
            variant: "default",
          });
        } else {
          toast({
            title: "Error",
            description: data.message,
            variant: "destructive",
          });
        }
      }
    } catch (err: any) {
      console.error('Exception fixing subscription:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: `An error occurred: ${err.message}`,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const batchFixTransactions = async () => {
    setIsBatchFixing(true);
    setError(null);
    setResult(null);

    try {
      console.log('Starting batch fix of orphaned transactions');
      
      const { data, error } = await supabase.functions.invoke('fix-trial-subscription', {
        body: { 
          fixType: 'check_subscription_status',
          // Don't specify a userId to check all users
        }
      });

      if (error) {
        console.error('Error during batch fix:', error);
        setError(error.message);
        toast({
          title: "Error",
          description: `Batch fix failed: ${error.message}`,
          variant: "destructive",
        });
      } else {
        console.log('Batch fix result:', data);
        setResult({
          ...data,
          batchFixResult: true
        });

        if (data.fixedCount > 0) {
          toast({
            title: "Success",
            description: `Fixed ${data.fixedCount} orphaned transactions out of ${data.orphanedCount} found`,
            variant: "default",
          });
        } else if (data.orphanedCount === 0) {
          toast({
            title: "Success",
            description: "No orphaned transactions found that need fixing",
            variant: "default",
          });
        } else {
          toast({
            title: "Warning",
            description: `Found ${data.orphanedCount} orphaned transactions but couldn't fix any automatically`,
            variant: "destructive",
          });
        }
      }
    } catch (err: any) {
      console.error('Exception during batch fix:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: `An error occurred: ${err.message}`,
        variant: "destructive",
      });
    } finally {
      setIsBatchFixing(false);
    }
  };

  const checkSubscriptionStatus = async () => {
    if (!userId) {
      setError("User ID is required");
      return;
    }

    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      console.log(`Checking subscription status for user: ${userId}`);
      
      const { data, error } = await supabase.functions.invoke('fix-trial-subscription', {
        body: { userId, fixType: 'check_subscription_status' }
      });

      if (error) {
        console.error('Error checking subscription status:', error);
        setError(error.message);
        toast({
          title: "Error",
          description: `Failed to check subscription status: ${error.message}`,
          variant: "destructive",
        });
      } else {
        console.log('Status check result:', data);
        setResult(data);

        if (data.success) {
          toast({
            title: "Success",
            description: `Found ${data.count} subscription(s) and ${data.orphanedCount} orphaned transaction(s)`,
            variant: "default",
          });
        } else {
          toast({
            title: "Error",
            description: data.message,
            variant: "destructive",
          });
        }
      }
    } catch (err: any) {
      console.error('Exception checking subscription status:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: `An error occurred: ${err.message}`,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Subscription Recovery Tools</CardTitle>
        <CardDescription>
          Fix issues with subscriptions and payment transactions
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="fix-subscription" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-4">
            <TabsTrigger value="fix-subscription">Fix Subscription</TabsTrigger>
            <TabsTrigger value="check-status">Check Status</TabsTrigger>
            <TabsTrigger value="batch-fix">Batch Fix</TabsTrigger>
          </TabsList>
          
          <TabsContent value="fix-subscription">
            <div className="space-y-4">
              <div>
                <label htmlFor="refId" className="block text-sm font-medium mb-1">
                  Payment Reference ID
                </label>
                <Input
                  id="refId"
                  value={refId}
                  onChange={(e) => setRefId(e.target.value)}
                  placeholder="sub_1234567890"
                  className="mb-4"
                  disabled={isLoading}
                />
              </div>
              
              <div className="flex gap-2">
                <Button 
                  onClick={() => fixSubscription('recreate_subscription')} 
                  disabled={isLoading}
                  className="flex items-center gap-2"
                >
                  {isLoading ? <RefreshCw className="h-4 w-4 animate-spin" /> : <Check className="h-4 w-4" />}
                  Recreate Subscription
                </Button>
                
                <Button 
                  onClick={() => fixSubscription('link_transaction')} 
                  disabled={isLoading}
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  {isLoading ? <RefreshCw className="h-4 w-4 animate-spin" /> : null}
                  Link to Existing Subscription
                </Button>
              </div>
              
              {error && (
                <Alert variant="destructive">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              
              {result && (
                <div className="mt-4 p-4 bg-muted rounded-md">
                  <h3 className="font-medium mb-2">Result</h3>
                  <pre className="text-xs overflow-auto whitespace-pre-wrap max-h-64">
                    {JSON.stringify(result, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </TabsContent>
          
          <TabsContent value="check-status">
            <div className="space-y-4">
              <div>
                <label htmlFor="userId" className="block text-sm font-medium mb-1">
                  User ID
                </label>
                <Input
                  id="userId"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  placeholder="User UUID"
                  className="mb-4"
                  disabled={isLoading}
                />
              </div>
              
              <Button 
                onClick={checkSubscriptionStatus} 
                disabled={isLoading}
                className="flex items-center gap-2"
              >
                {isLoading ? <RefreshCw className="h-4 w-4 animate-spin" /> : null}
                Check Subscription Status
              </Button>
              
              {error && (
                <Alert variant="destructive">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              
              {result && (
                <div className="mt-4">
                  {result.subscriptions && (
                    <div className="p-4 bg-muted rounded-md mb-4">
                      <h3 className="font-medium mb-2">Subscriptions ({result.subscriptions.length})</h3>
                      <pre className="text-xs overflow-auto whitespace-pre-wrap max-h-64">
                        {JSON.stringify(result.subscriptions, null, 2)}
                      </pre>
                    </div>
                  )}
                  
                  {result.orphanedTransactions && result.orphanedTransactions.length > 0 && (
                    <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-md">
                      <h3 className="font-medium mb-2 text-yellow-700">
                        Orphaned Transactions ({result.orphanedTransactions.length})
                      </h3>
                      <pre className="text-xs overflow-auto whitespace-pre-wrap max-h-64 text-yellow-800">
                        {JSON.stringify(result.orphanedTransactions, null, 2)}
                      </pre>
                      
                      {result.orphanedTransactions.length > 0 && (
                        <div className="mt-3">
                          <Button 
                            variant="outline" 
                            size="sm"
                            onClick={() => {
                              // Find the first orphaned transaction and use its ref_id
                              const firstTx = result.orphanedTransactions[0];
                              if (firstTx && firstTx.refId) {
                                setRefId(firstTx.refId);
                                setActiveTab("fix-subscription");
                                toast({
                                  title: "Reference ID Set",
                                  description: `Set reference ID to ${firstTx.refId} - you can now fix this transaction`,
                                });
                              }
                            }}
                          >
                            Fix First Orphaned Transaction
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </TabsContent>
          
          <TabsContent value="batch-fix">
            <div className="space-y-4">
              <Alert className="bg-blue-50 border-blue-200">
                <Clock className="h-4 w-4 text-blue-500" />
                <AlertDescription className="text-blue-700">
                  This will scan for all orphaned transactions in the system and attempt to automatically link them to subscriptions.
                </AlertDescription>
              </Alert>
              
              <Button 
                onClick={batchFixTransactions} 
                disabled={isBatchFixing}
                className="flex items-center gap-2"
              >
                {isBatchFixing ? 
                  <RefreshCw className="h-4 w-4 animate-spin" /> : 
                  <RotateCcw className="h-4 w-4" />
                }
                {isBatchFixing ? "Fixing Transactions..." : "Fix All Orphaned Transactions"}
              </Button>
              
              {error && (
                <Alert variant="destructive">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              
              {result && result.batchFixResult && (
                <div className="mt-4">
                  <Alert variant={result.fixedCount > 0 ? "default" : "warning"} className={result.fixedCount > 0 ? "bg-green-50 border-green-200" : ""}>
                    {result.fixedCount > 0 ? 
                      <CheckCircle className="h-4 w-4 text-green-500" /> : 
                      <AlertTriangle className="h-4 w-4 text-yellow-500" />
                    }
                    <AlertDescription className={result.fixedCount > 0 ? "text-green-700" : "text-yellow-700"}>
                      {result.orphanedCount === 0 ? 
                        "No orphaned transactions found." : 
                        `Fixed ${result.fixedCount} out of ${result.orphanedCount} orphaned transactions.`}
                    </AlertDescription>
                  </Alert>
                  
                  {result.results && result.results.length > 0 && (
                    <div className="mt-4 p-4 bg-muted rounded-md">
                      <h3 className="font-medium mb-2">Processing Results</h3>
                      <pre className="text-xs overflow-auto whitespace-pre-wrap max-h-64">
                        {JSON.stringify(result.results, null, 2)}
                      </pre>
                    </div>
                  )}
                </div>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};
