import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { GraduationCap, BookOpen } from "lucide-react";
import { Link } from "react-router-dom";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const Academy = () => {
  const { t } = useTranslation();
  
  const { data: courses, isLoading } = useQuery({
    queryKey: ['academy_courses'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('academy_courses')
        .select(`
          *,
          academy_modules (
            id,
            title
          )
        `)
        .eq('status', 'published')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-7xl mx-auto">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h1 className="text-2xl font-semibold text-gray-900">Academy</h1>
                  <p className="text-sm text-gray-500">Expand your knowledge with our courses</p>
                </div>
                {profile?.is_admin && (
                  <Button asChild>
                    <Link to="/academy/manage">
                      Manage Courses
                    </Link>
                  </Button>
                )}
              </div>

              {isLoading ? (
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {[1, 2, 3].map((i) => (
                    <Card key={i} className="animate-pulse">
                      <div className="h-48 bg-gray-200 rounded-t-lg" />
                      <div className="p-4">
                        <div className="h-6 bg-gray-200 rounded w-3/4 mb-2" />
                        <div className="h-4 bg-gray-200 rounded w-1/2" />
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {courses?.map((course) => (
                    <Link key={course.id} to={`/academy/courses/${course.id}`}>
                      <Card className="overflow-hidden hover:shadow-lg transition-shadow">
                        {course.thumbnail_url ? (
                          <img 
                            src={course.thumbnail_url} 
                            alt={course.title}
                            className="h-48 w-full object-cover"
                          />
                        ) : (
                          <div className="h-48 bg-gray-100 flex items-center justify-center">
                            <BookOpen className="h-12 w-12 text-gray-400" />
                          </div>
                        )}
                        <div className="p-4">
                          <h3 className="font-semibold text-lg mb-2">{course.title}</h3>
                          <p className="text-sm text-gray-500 line-clamp-2">{course.description}</p>
                          <div className="mt-4 flex items-center text-sm text-gray-500">
                            <GraduationCap className="h-4 w-4 mr-2" />
                            {course.academy_modules?.length || 0} modules
                          </div>
                        </div>
                      </Card>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Academy;
