
import { useTranslation } from "react-i18next";
import { Mail, Phone, MessageSquare } from "lucide-react";
import { ZohoContactForm } from "./ZohoContactForm";

export const ContactSection = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="py-16 bg-gray-50">
      <div className="container mx-auto max-w-6xl px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">{t('landing.contact.title')}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">{t('landing.contact.subtitle')}</p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-10 mb-10">
          {/* Contact Information */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">{t('landing.contact.headerTitle')}</h3>
            <p className="text-gray-600 mb-8">{t('landing.contact.headerSubtitle')}</p>
            
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="mr-4 mt-1">
                  <Mail className="w-5 h-5 text-primary" />
                </div>
                <p className="text-gray-700">{t('landing.contact.contactInfo.email')}</p>
              </div>
              
              <div className="flex items-start">
                <div className="mr-4 mt-1">
                  <Phone className="w-5 h-5 text-primary" />
                </div>
                <p className="text-gray-700">{t('landing.contact.contactInfo.phone')}</p>
              </div>
              
              <div className="flex items-start">
                <div className="mr-4 mt-1">
                  <MessageSquare className="w-5 h-5 text-primary" />
                </div>
                <p className="text-gray-700">{t('landing.contact.contactInfo.chat')}</p>
              </div>
            </div>
          </div>
          
          {/* Zoho Contact Form */}
          <ZohoContactForm />
        </div>
      </div>
    </section>
  );
};
