
import { Alert, AlertDescription } from "@/components/ui/alert";

interface ReviewsErrorProps {
  message: string;
}

export const ReviewsError = ({ message }: ReviewsErrorProps) => {
  return (
    <div className="text-center text-gray-500 py-4">
      <Alert variant="destructive">
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    </div>
  );
};
