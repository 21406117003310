
import { useState } from "react";
import { TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Edit2, Trash2, CheckCircle } from "lucide-react";
import { BusinessWithProfile } from "./types";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/hooks/use-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { BusinessEditForm } from "./business-edit/BusinessEditForm";
import { calculateProfileCompletion } from "@/utils/businessProfile";
import { Progress } from "@/components/ui/progress";
import { ImageDownloader } from "./business-row/ImageDownloader";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface BusinessListItemProps {
  business: BusinessWithProfile;
  onUpdate: () => void;
}

export const BusinessListItem = ({ business, onUpdate }: BusinessListItemProps) => {
  const { toast } = useToast();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const completionStatus = calculateProfileCompletion(business);

  return (
    <>
      <TableRow>
        <TableCell className="font-medium">
          <div className="space-y-4">
            <div className="flex items-center gap-1">
              {business.business_name}
              {business.gbp_verified && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CheckCircle className="h-4 w-4 text-green-500 ml-1" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Google Business Profile access verified</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <div className="flex flex-wrap gap-2">
              <ImageDownloader
                imageUrl={business.logo_url}
                fileName={`${business.business_name}_logo.png`}
                label="Logo"
              />
              <ImageDownloader
                imageUrl={business.square_logo_url}
                fileName={`${business.business_name}_square_logo.png`}
                label="Square Logo"
              />
              <ImageDownloader
                imageUrl={business.primary_image_url}
                fileName={`${business.business_name}_primary_image.png`}
                label="Header Image"
              />
            </div>
            {business.location_id && (
              <div className="text-sm text-muted-foreground">
                Location ID: {business.location_id}
              </div>
            )}
          </div>
        </TableCell>

        <TableCell>
          {business.profiles ? (
            <div className="text-sm">
              <div>{business.profiles.first_name} {business.profiles.last_name}</div>
              <div className="text-gray-500">{business.profiles.email}</div>
            </div>
          ) : (
            <span className="text-gray-500">No owner info</span>
          )}
        </TableCell>
        <TableCell>
          <div className="text-sm">
            <div>{business.phone_number}</div>
            <div className="text-gray-500">{business.business_email}</div>
          </div>
        </TableCell>
        <TableCell>
          <div className="text-sm">
            <div>{business.city}, {business.state}</div>
            <div className="text-gray-500">{business.zip_code}</div>
          </div>
        </TableCell>
        <TableCell>{business.category}</TableCell>
        <TableCell>
          <div className="space-y-2">
            <Badge
              variant={completionStatus.percentage === 100 ? 'default' : 'secondary'}
              className="font-normal"
            >
              {completionStatus.percentage}% Complete
            </Badge>
            <Progress value={completionStatus.percentage} className="h-1.5" />
            {completionStatus.missingFields.length > 0 && (
              <div className="text-xs text-muted-foreground">
                Missing: {completionStatus.missingFields.map(f => f.name).join(', ')}
              </div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className="flex items-center gap-2">
            <Button 
              variant="ghost" 
              size="icon"
              onClick={() => setIsEditDialogOpen(true)}
            >
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button variant="ghost" size="icon">
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </TableCell>
      </TableRow>

      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Edit Business Profile</DialogTitle>
          </DialogHeader>
          <BusinessEditForm 
            business={business} 
            onSuccess={() => {
              setIsEditDialogOpen(false);
              onUpdate();
            }} 
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
