
export const billing = {
  title: "Facturación y Suscripción",
  subtitle: "Administra tu suscripción y detalles de pago",
  
  currentPlan: {
    title: "Plan Actual",
    subtitle: "Ver y administrar los detalles de tu suscripción",
    package: "Paquete",
    monthlyPrice: "Precio Mensual",
    nextBillingDate: "Próxima Fecha de Facturación",
    noSubscription: "No tienes una suscripción activa",
    noSubscriptionMessage: "Contacta con soporte para suscribirte a un plan",
    noNextBillingDate: "No programado",
    cardExpiry: "Vence el {{month}}/{{year}}",
    subscriptionExpired: "Tu suscripción ha expirado. Por favor, actualiza tu método de pago.",
    loadError: "Error cargando la información de suscripción"
  },

  packages: {
    maps_seo: "Paquete Maps y SEO",
    basic: "Paquete Básico",
    premium: "Paquete Premium",
    maps_website_seo: "Paquete Maps y SEO Web"
  },

  billingAddress: {
    title: "Dirección de Facturación",
    subtitle: "Tu información de facturación",
    noAddress: "No hay dirección de facturación registrada"
  },

  paymentHistory: {
    title: "Historial de Pagos",
    subtitle: "Ver tus pagos anteriores",
    date: "Fecha",
    amount: "Cantidad",
    status: "Estado",
    paid: "Pagado",
    cardEnding: "Tarjeta terminada en {{lastFour}}",
    noPayments: "No hay historial de pagos"
  },
  
  trial: {
    status: "Suscripción de Prueba",
    daysRemaining: "{{days}} días restantes",
    endsOn: "La prueba termina el {{date}}",
    autoConvert: "Tu prueba se convertirá automáticamente en una suscripción de pago después de que termine.",
    freeTrialPeriod: "Gratis durante el período de prueba",
    firstPaymentDue: "Primer pago después de que termine la prueba"
  },
  
  noSubscription: {
    message: "No encontramos una suscripción activa para tu cuenta."
  },
  
  partialSubscription: {
    message: "Hemos detectado información de suscripción parcial. Haz clic para recuperarla."
  },
  
  recovering: "Recuperando...",
  recoverSubscription: "Recuperar Suscripción"
};
