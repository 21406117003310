
import { useEffect, useRef } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import useEmblaCarousel from "embla-carousel-react";
import AutoPlay from "embla-carousel-autoplay";
import { useTranslation } from "react-i18next";

export const LogoSlider = () => {
  const { t } = useTranslation();
  
  // Create a ref for the autoplay plugin to prevent it from being recreated on each render
  const autoplayPlugin = useRef(
    AutoPlay({ delay: 3000, stopOnInteraction: false })
  );
  
  // Initialize carousel with autoplay plugin
  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true,
    align: "start",
    skipSnaps: false
  }, [autoplayPlugin.current]);

  // Client logos in the exact order specified
  const logos = [
    { id: 1, src: "/lovable-uploads/3659a2a8-ca69-449a-a9c3-bbb687e9e522.png", alt: "Ford Logo" },
    { id: 2, src: "/lovable-uploads/40616e3c-354b-4731-997b-7c3cb54c63ef.png", alt: "GMC Logo" },
    { id: 3, src: "/lovable-uploads/043cbbc6-3c3e-497a-9515-3745b705b8a4.png", alt: "KIA Logo" },
    { id: 4, src: "/lovable-uploads/a85c4417-60ce-4f74-bedd-7ed75e51b8a5.png", alt: "Genesis Logo" },
    { id: 5, src: "/lovable-uploads/30405dc2-192b-462a-9df1-0fe1d3e820b1.png", alt: "Starr Law Firm Logo" },
    { id: 6, src: "/lovable-uploads/5f49b15b-168b-49cc-bcf9-b6ea21803c76.png", alt: "Catfish Parlour Logo" },
    { id: 7, src: "/lovable-uploads/99ccdebf-fa1d-40aa-ac79-9a2a01aad932.png", alt: "Jacobs Financial Services Logo" },
    { id: 8, src: "/lovable-uploads/a7745dbc-20b7-486c-a84c-537ba1ed82ed.png", alt: "Maximum Flavor Media Logo" },
    { id: 9, src: "/lovable-uploads/66c233a7-496b-4de2-b4d9-3aec403f2411.png", alt: "3 Blind Mice Window Coverings Logo" },
    { id: 10, src: "/lovable-uploads/25578cd8-6c26-460a-a2a6-d79dd4a429c2.png", alt: "MHM Living Logo" },
    { id: 11, src: "/lovable-uploads/e2b7d7fd-7d9a-4133-b024-343d4d5a2d1d.png", alt: "Texy Nails Logo" },
    { id: 12, src: "/lovable-uploads/25dc6c5b-90a9-4ed2-83e6-24bf0ef46479.png", alt: "Schibolo Nails Logo" },
  ];

  // Make sure the API initializes properly
  useEffect(() => {
    if (emblaApi) {
      // Force a reinitialization to ensure autoplay starts
      emblaApi.reInit();
    }
  }, [emblaApi]);

  return (
    <section className="py-10 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">{t('landing.logoSlider.title')}</h2>
          <p className="text-gray-500 mt-2">{t('landing.logoSlider.subtitle')}</p>
        </div>
        
        <div className="max-w-6xl mx-auto">
          <div className="w-full" ref={emblaRef}>
            <div className="-ml-4 flex">
              {logos.map((logo) => (
                <div key={logo.id} className="pl-4 md:basis-1/4 lg:basis-1/6 min-w-0 shrink-0 grow-0">
                  <div className="p-4 h-24 flex items-center justify-center">
                    <img 
                      src={logo.src} 
                      alt={logo.alt} 
                      className="max-h-12 max-w-full filter grayscale hover:grayscale-0 transition-all duration-300 opacity-70 hover:opacity-100"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
