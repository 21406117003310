
export const businessprofile = {
  title: "Business Profile",
  subtitle: "Reach 100% for maximum optimization.",
  form: {
    businessName: "Business Name",
    businessEmail: "Business Email",
    websiteUrl: "Website URL",
    phoneNumber: "Phone Number",
    category: "Category",
    openingDate: "Opening Date",
    openingHours: "Opening Hours",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "ZIP Code",
    shortDescription: "Brief description of your business",
    longDescription: "Detailed description of your business",
    facebookUrl: "Facebook URL",
    instagramUrl: "Instagram URL",
    businessServices: "Enter services separated by commas",
    seoCampaignGoal: "SEO Campaign Goal",
    desiredKeywords: "Desired Keywords",
    keywordsPlaceholder: "Enter keywords separated by commas",
    keywordsHelp: "Each keyword should be on a new line or separated by commas",
    paymentMethods: "Payment Methods",
    paymentMethodsPlaceholder: "Enter payment methods separated by commas",
    promotions: "Promotions",
    promotionsPlaceholder: "Enter your promotions",
    seoTitle: "SEO Information",
    saving: "Saving...",
    saveChanges: "Save Changes",
    success: "Business profile updated successfully",
    error: "Error updating business profile"
  },
  website_credentials: {
    title: "Website Admin Credentials",
    description: "Securely store your website admin login details",
    add_new: "Add New Credential",
    add_title: "Add New Website Credential",
    edit_title: "Edit Website Credential",
    admin_url: "Admin URL",
    username: "Username",
    password: "Password",
    new_password_optional: "New Password (leave blank to keep existing)",
    leave_blank: "Leave blank to keep existing password",
    enter_password: "Enter password",
    expiration_date: "Expiration Date (optional)",
    notes: "Notes",
    notes_placeholder: "Add any additional notes or information",
    last_updated: "Last Updated",
    expiration: "Expiration",
    no_expiration: "No expiration",
    view_notes: "View Notes",
    no_notes: "No notes",
    confirm_delete: "Confirm Deletion",
    delete_warning: "Are you sure you want to delete this credential? This action cannot be undone.",
    added: "Credential Added",
    add_success: "The website credential has been successfully added.",
    updated: "Credential Updated",
    update_success: "The website credential has been successfully updated.",
    deleted: "Credential Deleted",
    delete_success: "The website credential has been successfully deleted.",
    error: "Error",
    add_error: "There was an error adding the credential. Please try again.",
    update_error: "There was an error updating the credential. Please try again.",
    delete_error: "There was an error deleting the credential. Please try again.",
    load_error: "Error loading credentials",
    no_credentials: "No credentials added yet. Click 'Add New Credential' to get started.",
    copied: "{type} copied to clipboard",
    copy_username: "Copy username"
  }
};
