import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { format } from 'date-fns';
import { 
  AlertCircle, 
  Search, 
  User, 
  UserCheck, 
  UserCog, 
  UserX,
  Loader
} from 'lucide-react';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useDebounce } from "@/hooks/useDebounce";
import { UserDetailsModal } from "./UserDetailsModal";
import { Profile } from "@/integrations/supabase/types/profiles";
import { Pagination } from "@/components/ui/pagination";

type SimplifiedBusinessProfile = {
  business_name: string;
  category: string;
  city: string;
  state: string;
};

type SimplifiedSubscription = {
  status: string;
  package_type: string;
  price: number;
  created_at: string;
  next_billing_date: string | null;
  id: string;
};

type ExtendedProfile = Profile & {
  business_profiles?: SimplifiedBusinessProfile[];
  subscriptions?: SimplifiedSubscription[];
  is_partner?: boolean;
};

export function UserManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedUser, setSelectedUser] = useState<ExtendedProfile | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const debouncedSearch = useDebounce(searchTerm, 300);

  const { 
    data: profiles, 
    isLoading: profilesLoading, 
    error: profilesError 
  } = useQuery({
    queryKey: ['admin-profiles', debouncedSearch, roleFilter],
    queryFn: async () => {
      console.log('Fetching profiles with filters:', { debouncedSearch, roleFilter });
      try {
        let query = supabase
          .from('profiles')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (debouncedSearch) {
          query = query.or(`first_name.ilike.%${debouncedSearch}%,last_name.ilike.%${debouncedSearch}%,email.ilike.%${debouncedSearch}%`);
        }
        
        if (roleFilter !== 'all') {
          if (roleFilter === 'admin') {
            query = query.eq('is_admin', true);
          } else if (roleFilter === 'partner') {
            query = query.eq('is_partner', true);
          } else {
            query = query.eq('is_admin', false).eq('is_partner', false);
          }
        }
        
        const { data, error } = await query;
        
        if (error) {
          console.error('Error fetching profiles:', error);
          throw error;
        }
        
        console.log(`Fetched ${data?.length} profiles`);
        return data as Profile[];
      } catch (err) {
        console.error('Unexpected error in profiles fetch:', err);
        throw err;
      }
    }
  });

  const { 
    data: businessProfiles,
    isLoading: businessLoading
  } = useQuery({
    queryKey: ['admin-business-profiles', profiles?.map(p => p.id).join(',')],
    enabled: !!profiles && profiles.length > 0,
    queryFn: async () => {
      try {
        const userIds = profiles!.map(profile => profile.id);
        const { data, error } = await supabase
          .from('business_profiles')
          .select('business_name, category, city, state, user_id')
          .in('user_id', userIds);
        
        if (error) {
          console.error('Error fetching business profiles:', error);
          throw error;
        }
        
        return data.reduce((acc, item) => {
          if (!acc[item.user_id]) {
            acc[item.user_id] = [];
          }
          acc[item.user_id].push(item);
          return acc;
        }, {} as Record<string, SimplifiedBusinessProfile[]>);
      } catch (err) {
        console.error('Unexpected error in business profiles fetch:', err);
        return {};
      }
    }
  });

  const { 
    data: subscriptions,
    isLoading: subscriptionsLoading
  } = useQuery({
    queryKey: ['admin-subscriptions', profiles?.map(p => p.id).join(','), statusFilter],
    enabled: !!profiles && profiles.length > 0,
    queryFn: async () => {
      try {
        const userIds = profiles!.map(profile => profile.id);
        let query = supabase
          .from('subscriptions')
          .select('id, status, package_type, price, created_at, next_billing_date, user_id')
          .in('user_id', userIds)
          .order('created_at', { ascending: false });
        
        if (statusFilter !== 'all') {
          query = query.eq('status', statusFilter);
        }
        
        const { data, error } = await query;
        
        if (error) {
          console.error('Error fetching subscriptions:', error);
          throw error;
        }
        
        const userSubscriptions: Record<string, SimplifiedSubscription[]> = {};
        
        data.forEach(sub => {
          if (!userSubscriptions[sub.user_id]) {
            userSubscriptions[sub.user_id] = [];
          }
          userSubscriptions[sub.user_id].push(sub);
        });
        
        return userSubscriptions;
      } catch (err) {
        console.error('Unexpected error in subscriptions fetch:', err);
        return {};
      }
    }
  });

  const users = profiles?.map(profile => {
    const business = businessProfiles?.[profile.id] || [];
    const userSubscriptions = subscriptions?.[profile.id] || [];
    
    return {
      ...profile,
      business_profiles: business,
      subscriptions: userSubscriptions
    } as ExtendedProfile;
  }) || [];

  const filteredUsers = statusFilter !== 'all' && users.length > 0
    ? users.filter(user => 
        user.subscriptions && 
        user.subscriptions.length > 0 &&
        user.subscriptions.some(sub => sub.status === statusFilter))
    : users;
  
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage, 
    currentPage * itemsPerPage
  );

  const getRoleBadge = (role: string | null, isAdmin: boolean | null, isPartner: boolean | null) => {
    if (isAdmin) {
      return <Badge className="bg-red-100 text-red-800">Admin</Badge>;
    }
    if (isPartner) {
      return <Badge className="bg-purple-100 text-purple-800">Partner</Badge>;
    }
    return <Badge className="bg-blue-100 text-blue-800">User</Badge>;
  };

  const getStatusIcon = (subscriptionStatus: string | null) => {
    switch (subscriptionStatus) {
      case 'active':
        return <UserCheck className="h-4 w-4 text-green-500" />;
      case 'suspended':
        return <UserX className="h-4 w-4 text-red-500" />;
      case 'pending':
        return <UserCog className="h-4 w-4 text-yellow-500" />;
      default:
        return <User className="h-4 w-4 text-gray-500" />;
    }
  };

  const isLoading = profilesLoading || businessLoading || subscriptionsLoading;

  if (profilesError) {
    console.error('User Management Error:', profilesError);
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          Error loading user data: {profilesError instanceof Error 
            ? profilesError.message 
            : 'Unknown error'}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>User Management</CardTitle>
          <CardDescription>
            Manage and monitor user accounts, roles, and permissions
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <div className="relative flex-1">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input 
                placeholder="Search users..." 
                className="pl-8" 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Select value={roleFilter} onValueChange={setRoleFilter}>
              <SelectTrigger className="w-full md:w-[180px]">
                <SelectValue placeholder="Filter by role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Roles</SelectItem>
                <SelectItem value="admin">Admin</SelectItem>
                <SelectItem value="partner">Partner</SelectItem>
                <SelectItem value="user">User</SelectItem>
              </SelectContent>
            </Select>
            <Select value={statusFilter} onValueChange={setStatusFilter}>
              <SelectTrigger className="w-full md:w-[180px]">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Status</SelectItem>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="suspended">Suspended</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center p-10">
              <div className="flex flex-col items-center">
                <Loader className="h-8 w-8 animate-spin text-primary mb-2" />
                <p className="text-sm text-muted-foreground">Loading user data...</p>
              </div>
            </div>
          ) : paginatedUsers.length === 0 ? (
            <div className="p-10 text-center">
              <User className="h-10 w-10 mx-auto text-muted-foreground mb-4" />
              <h3 className="text-lg font-medium">No users found</h3>
              <p className="text-sm text-muted-foreground mt-1">
                Try adjusting your search or filter criteria
              </p>
            </div>
          ) : (
            <>
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead>Email</TableHead>
                      <TableHead>Role</TableHead>
                      <TableHead>Business</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Joined</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {paginatedUsers.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell className="font-medium">
                          {user.first_name} {user.last_name}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {getRoleBadge(user.role, user.is_admin, user.is_partner)}
                        </TableCell>
                        <TableCell>
                          {user.business_profiles && user.business_profiles.length > 0 
                            ? user.business_profiles[0].business_name 
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            {getStatusIcon(user.subscriptions && user.subscriptions.length > 0 
                              ? user.subscriptions[0].status 
                              : null)}
                            {user.subscriptions && user.subscriptions.length > 0 
                              ? user.subscriptions[0].status 
                              : 'No subscription'}
                          </div>
                        </TableCell>
                        <TableCell>
                          {user.created_at 
                            ? format(new Date(user.created_at), 'MMM d, yyyy') 
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Button 
                            variant="ghost" 
                            size="sm"
                            onClick={() => setSelectedUser(user)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              {totalPages > 1 && (
                <div className="flex justify-center mt-6">
                  <div className="flex space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <div className="flex items-center px-2 text-sm">
                      Page {currentPage} of {totalPages}
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {selectedUser && (
        <UserDetailsModal
          isOpen={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          user={selectedUser}
        />
      )}
    </div>
  );
}
