
import { Circle, CheckCircle2, ChevronRight } from "lucide-react";

interface SignupStepsIndicatorProps {
  currentStep: number;
  steps: { name: string; completed: boolean }[];
}

export const SignupStepsIndicator = ({ currentStep, steps }: SignupStepsIndicatorProps) => {
  return (
    <div className="mb-8">
      <div className="flex items-center justify-center w-full">
        {steps.map((step, index) => (
          <div key={step.name} className="flex items-center">
            <div 
              className={`flex items-center justify-center rounded-full w-9 h-9 
                ${index + 1 === currentStep ? 'bg-[#e0933c] text-white' : 
                  step.completed ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-500'}`}
            >
              {step.completed ? (
                <CheckCircle2 className="h-5 w-5" />
              ) : (
                index + 1 === currentStep ? (
                  <span className="text-sm font-medium">{index + 1}</span>
                ) : (
                  <Circle className="h-5 w-5" />
                )
              )}
            </div>
            
            <span 
              className={`ml-3 text-sm font-medium ${
                index + 1 === currentStep ? 'text-[#e0933c]' : 
                step.completed ? 'text-green-600' : 'text-gray-500'
              }`}
            >
              {step.name}
            </span>
            
            {/* Add chevron separator between steps */}
            {index < steps.length - 1 && (
              <ChevronRight className="mx-3 h-4 w-4 text-gray-400" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
