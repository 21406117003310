
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { ResourceList } from "@/components/resources/ResourceList";
import { useToast } from "@/hooks/use-toast";
import { Card } from "@/components/ui/card";
import { useState } from "react";
import { Pagination } from "@/components/ui/pagination";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { LoadingSpinner } from "@/components/routing/LoadingSpinner";

declare global {
  interface Window {
    SB_ARTICLES_PAGE: boolean;
  }
}

const Resources = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 6; // Number of articles per page

  useState(() => {
    window.SB_ARTICLES_PAGE = true;
    return () => {
      delete window.SB_ARTICLES_PAGE;
    };
  });

  const { data: articles, isLoading, isError, error } = useQuery({
    queryKey: ['articles', currentPage],
    queryFn: async () => {
      try {
        console.log('Fetching articles for page:', currentPage);
        
        // Get total count first to calculate pagination
        const { count, error: countError } = await supabase
          .from('articles')
          .select('*', { count: 'exact', head: true })
          .eq('status', 'published');
          
        if (countError) {
          console.error('Error counting articles:', countError);
          throw countError;
        }
        
        const totalArticles = count || 0;
        console.log('Total articles count:', totalArticles);
        const calculatedTotalPages = Math.ceil(totalArticles / pageSize);
        setTotalPages(calculatedTotalPages);
        
        // Then fetch the articles with their view data
        const { data: articleData, error } = await supabase
          .from('articles')
          .select(`
            *,
            article_views(completed, progress_percent, time_spent_seconds)
          `)
          .eq('status', 'published')
          .range((currentPage - 1) * pageSize, currentPage * pageSize - 1)
          .order('published_at', { ascending: false });

        if (error) {
          console.error('Error fetching articles:', error);
          throw error;
        }
        
        console.log('Articles fetched successfully:', articleData?.length);
        return { articles: articleData || [], totalCount: totalArticles };
      } catch (err) {
        console.error('Unexpected error in articles query:', err);
        // Rethrow to trigger error state in React Query
        throw err;
      }
    },
    retry: 1,
    retryDelay: 1000,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Better error handling
  if (isError) {
    console.error('Error in Articles query:', error);
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 overflow-x-auto md:pl-[12rem]">
            <div className="w-full px-8 py-6">
              <div className="max-w-6xl mx-auto">
                <div className="mb-8">
                  <h1 className="text-2xl font-semibold text-gray-900">{t('resources.title')}</h1>
                  <p className="text-sm text-gray-500">{t('resources.subtitle')}</p>
                </div>
                <Card className="p-6 text-center">
                  <p className="text-gray-500 mb-2">There was an error loading resources.</p>
                  <p className="text-sm text-red-500">
                    {error instanceof Error ? error.message : "Unknown error occurred"}
                  </p>
                </Card>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  if (isLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 overflow-x-auto md:pl-[12rem]">
            <div className="w-full px-8 py-6">
              <div className="max-w-6xl mx-auto">
                <div className="mb-8">
                  <h1 className="text-2xl font-semibold text-gray-900">{t('resources.title')}</h1>
                  <p className="text-sm text-gray-500">{t('resources.subtitle')}</p>
                </div>
                <div className="flex justify-center items-center py-12">
                  <LoadingSpinner />
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-6xl mx-auto">
              <div className="mb-8">
                <h1 className="text-2xl font-semibold text-gray-900">{t('resources.title')}</h1>
                <p className="text-sm text-gray-500">{t('resources.subtitle')}</p>
              </div>

              <ResourceList 
                articles={articles?.articles || []} 
                isLoading={isLoading} 
              />

              {!isLoading && articles?.articles.length > 0 && (
                <Pagination 
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  className="mt-8"
                />
              )}

              <div className="mt-12">
                <h2 className="text-xl font-semibold text-gray-900 mb-6">Articles</h2>
                <Card className="p-6">
                  <div id="sb-articles" className="sb-loading"></div>
                </Card>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Resources;
