
import { PersonalInfoForm } from "@/components/account/PersonalInfoForm";
import { useTranslation } from "react-i18next";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const MyAccount = () => {
  const { t } = useTranslation();

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-3xl mx-auto">
              <div className="mb-8">
                <h1 className="text-2xl font-semibold text-gray-900">{t('account.title')}</h1>
                <p className="text-sm text-gray-500">{t('account.subtitle')}</p>
              </div>
              <PersonalInfoForm />
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default MyAccount;
