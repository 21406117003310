import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Card } from "@/components/ui/card";
import { Star, ChevronRight } from "lucide-react";
import { featuredTestimonials } from "@/data/testimonials";
import Autoplay from "embla-carousel-autoplay";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

export const TestimonialSlider = () => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    pricingSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            {t('landing.testimonials.title')}
          </h2>
          <p className="text-xl text-gray-600">
            {t('landing.testimonials.subtitle')}
          </p>
        </div>

        <div className="max-w-7xl mx-auto mb-12">
          <Carousel
            opts={{
              align: "start",
              loop: true,
              slidesToScroll: 1,
            }}
            plugins={[
              Autoplay({
                delay: 3000,
              }),
            ]}
            className="w-full"
          >
            <CarouselContent className="-ml-2 md:-ml-4">
              {[
                {
                  icon: "🍷",
                  content: "150% increase in foot traffic—our liquor store is thriving!",
                  name: "James Carter",
                  role: "Owner - City Spirits Liquor Store",
                  rating: 5
                },
                {
                  icon: "🛍",
                  content: "Steady stream of new customers—our boutique's online presence is stronger than ever!",
                  name: "Sophia Martinez",
                  role: "Owner - Trendy Threads Boutique",
                  rating: 5
                },
                {
                  icon: "🦞",
                  content: "Fully booked weekends—our seafood restaurant is now a local favorite!",
                  name: "Mark Johnson",
                  role: "Owner - Ocean Breeze Seafood",
                  rating: 5
                },
                {
                  icon: "🦷",
                  content: "100% more new patients in just 6 months—remarkable ROI!",
                  name: "Michael Chen",
                  role: "Director - Pacific Dental Care",
                  rating: 5
                },
                {
                  icon: "🚗",
                  content: "200% increase in customer calls—our auto shop is busier than ever!",
                  name: "David Smith",
                  role: "Owner - Smith's Auto Repair",
                  rating: 5
                },
                {
                  icon: "🏪",
                  content: "50% more sales—our convenience store is seeing record-breaking months!",
                  name: "Jessica Lee",
                  role: "Owner - QuickStop Convenience",
                  rating: 5
                },
                {
                  icon: "🚙",
                  content: "More test drives, more deals—our dealership is closing more sales than ever!",
                  name: "Robert Green",
                  role: "Sales Manager - Greenlight Auto Dealer",
                  rating: 5
                }
              ].map((testimonial, index) => (
                <CarouselItem key={index} className="pl-2 md:pl-4 md:basis-1/3 lg:basis-1/3">
                  <Card className="p-6 h-full flex flex-col border border-gray-100 hover:shadow-md transition-shadow duration-300">
                    <div className="flex mb-4">
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <Star key={i} className="h-5 w-5 text-[#e0933c] fill-current" />
                      ))}
                    </div>
                    <div className="text-3xl mb-3">{testimonial.icon}</div>
                    <p className="text-gray-700 mb-6 flex-grow">"{testimonial.content}"</p>
                    <div className="border-t pt-4">
                      <p className="font-semibold text-gray-900">{testimonial.name}</p>
                      <p className="text-sm text-gray-500">{testimonial.role}</p>
                    </div>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
            <div className="flex justify-center mt-8">
              <CarouselPrevious className="relative static mx-2" />
              <CarouselNext className="relative static mx-2" />
            </div>
          </Carousel>
        </div>

        <div className="text-center mt-10">
          <p className="text-xl text-gray-800 mb-6 font-medium">
            {t('landing.testimonials.cta')}
          </p>
          <Button 
            onClick={scrollToPricing}
            className="bg-primary hover:bg-primary/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
          >
            {t('landing.testimonials.getStarted')}
            <ChevronRight className="ml-2 h-5 w-5" />
          </Button>
        </div>
      </div>
    </section>
  );
};
