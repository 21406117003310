import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

// Enforce TLS 1.2 minimum
const enforceSecureSettings = () => {
  // Check if connection is secure
  const isSecure = window.location.protocol === 'https:' || window.location.hostname === 'localhost';
  
  if (!isSecure) {
    console.warn("[useSessionInit] Insecure connection detected", {
      protocol: window.location.protocol,
      hostname: window.location.hostname
    });
    
    // Force HTTPS
    if (window.location.hostname !== 'localhost') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }
};

export const useSessionInit = () => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const initialized = useRef(false);
  const initializationTimeout = useRef<NodeJS.Timeout>();
  const retryAttempts = useRef(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 5000; // 5 seconds
  const SESSION_TIMEOUT = 30000; // 30 seconds

  useEffect(() => {
    // Enforce secure connection first
    enforceSecureSettings();
    
    const initializeSession = async () => {
      if (initialized.current) {
        console.log("[useSessionInit] Session already initialized, skipping");
        return;
      }

      console.log("[useSessionInit] Starting session initialization", {
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname,
        retryAttempt: retryAttempts.current
      });

      try {
        // First try to get session from storage
        const existingSession = localStorage.getItem('sb-auth-token');
        if (existingSession) {
          console.log("[useSessionInit] Found existing session in storage");
        }

        const { data: { session: initialSession }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error("[useSessionInit] Session error:", sessionError);
          if (retryAttempts.current < MAX_RETRIES) {
            retryAttempts.current++;
            console.log(`[useSessionInit] Retrying (${retryAttempts.current}/${MAX_RETRIES})`);
            setTimeout(initializeSession, RETRY_DELAY);
            return;
          }
          throw sessionError;
        }

        console.log("[useSessionInit] Initial session fetch:", { 
          hasSession: !!initialSession,
          userId: initialSession?.user?.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        
        setSession(initialSession);
        
        if (initialSession?.user) {
          const currentPath = window.location.pathname;
          if (['/login', '/', '/signup', '/resources'].includes(currentPath) || currentPath.startsWith('/signup/')) {
            console.log("[useSessionInit] Redirecting to dashboard", {
              from: currentPath,
              timestamp: new Date().toISOString()
            });
            navigate('/dashboard', { replace: true });
          }
        }

        setLoading(false);
      } catch (error: any) {
        console.error('[useSessionInit] Session initialization error:', {
          error,
          timestamp: new Date().toISOString(),
          stack: error.stack,
          currentPath: window.location.pathname
        });
        await handleSessionError();
      }
    };

    const handleSessionError = async () => {
      console.log('[useSessionInit] Handling session error');
      
      // Don't clear everything immediately
      setSession(null);
      setLoading(false);
      
      toast({
        title: "Session Error",
        description: "Please sign in again",
        variant: "destructive",
      });

      // Clear data after state updates
      setTimeout(() => {
        queryClient.clear();
        localStorage.clear();
        navigate('/login', { replace: true });
      }, 100);
    };

    // Set a longer timeout for initial session check
    initializationTimeout.current = setTimeout(() => {
      if (loading) {
        console.error("[useSessionInit] Session initialization timeout", {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname,
          retryAttempts: retryAttempts.current
        });
        
        if (retryAttempts.current < MAX_RETRIES) {
          retryAttempts.current++;
          console.log(`[useSessionInit] Retrying after timeout (${retryAttempts.current}/${MAX_RETRIES})`);
          initializeSession();
        } else {
          setLoading(false);
          handleSessionError();
        }
      }
    }, SESSION_TIMEOUT);

    initializeSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, newSession) => {
      console.log("[useSessionInit] Auth state change:", {
        event,
        hasSession: !!newSession,
        userId: newSession?.user?.id,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });

      if (event === 'SIGNED_OUT') {
        console.log("[useSessionInit] User signed out", {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        
        // Update state first
        setSession(null);
        setLoading(false);
        
        // Then clear data and navigate
        setTimeout(() => {
          queryClient.clear();
          localStorage.clear();
          navigate('/login', { replace: true });
        }, 100);
      } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        console.log("[useSessionInit] User signed in or token refreshed", {
          userId: newSession?.user?.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        setSession(newSession);
        queryClient.invalidateQueries();
        
        const currentPath = window.location.pathname;
        if (['/login', '/', '/signup', '/resources'].includes(currentPath) || currentPath.startsWith('/signup/')) {
          console.log("[useSessionInit] Redirecting to dashboard", {
            from: currentPath,
            timestamp: new Date().toISOString()
          });
          navigate('/dashboard', { replace: true });
        }
      }
      setLoading(false);
    });

    return () => {
      console.log("[useSessionInit] Cleaning up subscription", {
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname,
        isInitialized: initialized.current
      });
      if (initializationTimeout.current) {
        clearTimeout(initializationTimeout.current);
      }
      if (initialized.current) {
        subscription.unsubscribe();
      }
    };
  }, [navigate, queryClient, toast, loading]);

  return { session, loading, setLoading };
};
