
export const billing = {
  title: "Billing & Subscription",
  subtitle: "Manage your subscription and payment details",
  
  currentPlan: {
    title: "Current Plan",
    subtitle: "View and manage your subscription details",
    package: "Package",
    monthlyPrice: "Monthly Price",
    nextBillingDate: "Next Billing Date",
    noSubscription: "You don't have an active subscription",
    noSubscriptionMessage: "Contact support to sign up for a plan",
    noNextBillingDate: "Not scheduled",
    cardExpiry: "Expires {{month}}/{{year}}",
    subscriptionExpired: "Your subscription has expired. Please update your payment method.",
    loadError: "Error loading subscription information"
  },

  packages: {
    maps_seo: "Maps & SEO Package",
    basic: "Basic Package",
    premium: "Premium Package",
    maps_website_seo: "Maps & Website SEO Package"
  },

  billingAddress: {
    title: "Billing Address",
    subtitle: "Your billing information",
    noAddress: "No billing address on file"
  },

  paymentHistory: {
    title: "Payment History",
    subtitle: "View your past payments",
    date: "Date",
    amount: "Amount",
    status: "Status",
    paid: "Paid",
    cardEnding: "Card ending in {{lastFour}}"
  },
  
  trial: {
    status: "Trial Subscription",
    daysRemaining: "{{days}} days remaining",
    endsOn: "Trial ends on {{date}}",
    autoConvert: "Your trial will automatically convert to a paid subscription after it ends.",
    freeTrialPeriod: "Free during trial period",
    firstPaymentDue: "First payment due after trial ends"
  }
};
