
import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Eye, EyeOff, Edit, Trash, Calendar, Copy, RefreshCw, AlertTriangle } from 'lucide-react';
import { format, differenceInDays } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { useToast } from '@/hooks/use-toast';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { supabase } from '@/integrations/supabase/client';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Skeleton } from '@/components/ui/skeleton';

type WebsiteCredential = {
  id: string;
  business_id: string;
  business_name: string;
  admin_url: string;
  username: string;
  password_encrypted: string;
  password_decrypted: string;
  password_last_updated: string;
  expires_at: string | null;
  notes: string | null;
  password_status: 'valid' | 'expired' | 'expiring_soon';
};

const WebsiteCredentialsTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCredential, setSelectedCredential] = useState<WebsiteCredential | null>(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showPasswords, setShowPasswords] = useState<Record<string, boolean>>({});
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Fetch all credentials
  const { data: credentials, isLoading, error } = useQuery({
    queryKey: ['websiteCredentials'],
    queryFn: async () => {
      try {
        const { data, error } = await supabase
          .from('admin_website_credentials_decrypted')
          .select('*')
          .order('business_name', { ascending: true });

        if (error) throw error;
        return data as WebsiteCredential[];
      } catch (error) {
        console.error('Error fetching credentials:', error);
        throw error;
      }
    },
  });

  // Toggle password visibility
  const togglePasswordVisibility = (id: string) => {
    setShowPasswords(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Copy to clipboard
  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: `${type} copied to clipboard`,
      description: "The content has been copied to your clipboard.",
    });
  };

  // Form for adding/editing credentials
  const CredentialForm = ({ 
    credential, 
    mode, 
    onClose 
  }: { 
    credential?: WebsiteCredential; 
    mode: 'add' | 'edit'; 
    onClose: () => void;
  }) => {
    const form = useForm({
      defaultValues: credential ? {
        business_id: credential.business_id,
        admin_url: credential.admin_url,
        username: credential.username,
        password: '',
        notes: credential.notes || '',
        expires_at: credential.expires_at ? new Date(credential.expires_at).toISOString().slice(0, 10) : '',
      } : {
        business_id: '',
        admin_url: '',
        username: '',
        password: '',
        notes: '',
        expires_at: '',
      },
    });

    // Fetch all businesses for dropdown
    const { data: businesses, isLoading: isLoadingBusinesses } = useQuery({
      queryKey: ['businesses'],
      queryFn: async () => {
        const { data, error } = await supabase
          .from('business_profiles')
          .select('id, business_name')
          .order('business_name', { ascending: true });

        if (error) throw error;
        return data;
      },
    });

    // Mutation for adding a new credential
    const addCredentialMutation = useMutation({
      mutationFn: async (values: any) => {
        const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/website-credentials`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
          },
          body: JSON.stringify({
            action: 'add',
            data: {
              businessId: values.business_id,
              adminUrl: values.admin_url,
              username: values.username,
              password: values.password,
              notes: values.notes,
              expiresAt: values.expires_at ? new Date(values.expires_at).toISOString() : null,
            }
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to add credential');
        }

        return await response.json();
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['websiteCredentials'] });
        toast({
          title: "Credential added",
          description: "The website credential has been successfully added.",
        });
        onClose();
      },
      onError: (error) => {
        console.error('Error adding credential:', error);
        toast({
          title: "Error",
          description: "There was an error adding the credential. Please try again.",
          variant: "destructive",
        });
      }
    });

    // Mutation for updating a credential
    const updateCredentialMutation = useMutation({
      mutationFn: async (values: any) => {
        const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/website-credentials`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
          },
          body: JSON.stringify({
            action: 'update',
            data: {
              id: credential?.id,
              adminUrl: values.admin_url,
              username: values.username,
              password: values.password || null, // Only update if provided
              notes: values.notes,
              expiresAt: values.expires_at ? new Date(values.expires_at).toISOString() : null,
            }
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to update credential');
        }

        return await response.json();
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['websiteCredentials'] });
        toast({
          title: "Credential updated",
          description: "The website credential has been successfully updated.",
        });
        onClose();
      },
      onError: (error) => {
        console.error('Error updating credential:', error);
        toast({
          title: "Error",
          description: "There was an error updating the credential. Please try again.",
          variant: "destructive",
        });
      }
    });

    const onSubmit = (values: any) => {
      if (mode === 'add') {
        addCredentialMutation.mutate(values);
      } else {
        updateCredentialMutation.mutate(values);
      }
    };

    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          {mode === 'add' && (
            <FormField
              control={form.control}
              name="business_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Business</FormLabel>
                  <FormControl>
                    <select
                      className="w-full p-2 border rounded-md"
                      {...field}
                      disabled={isLoadingBusinesses}
                    >
                      <option value="">Select a business</option>
                      {businesses?.map((business) => (
                        <option key={business.id} value={business.id}>
                          {business.business_name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <FormField
            control={form.control}
            name="admin_url"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Admin URL</FormLabel>
                <FormControl>
                  <Input placeholder="https://example.com/wp-admin" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input placeholder="admin" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{mode === 'edit' ? 'New Password (leave blank to keep existing)' : 'Password'}</FormLabel>
                <FormControl>
                  <Input type="password" placeholder="Enter password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="expires_at"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Expiration Date (optional)</FormLabel>
                <FormControl>
                  <Input type="date" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Notes (optional)</FormLabel>
                <FormControl>
                  <Textarea placeholder="Add any additional notes or information" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <DialogFooter>
            <Button type="submit" disabled={addCredentialMutation.isPending || updateCredentialMutation.isPending}>
              {addCredentialMutation.isPending || updateCredentialMutation.isPending ? 'Saving...' : 'Save'}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    );
  };

  // Delete credential mutation
  const deleteCredentialMutation = useMutation({
    mutationFn: async (id: string) => {
      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/website-credentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
        body: JSON.stringify({
          action: 'delete',
          data: { id }
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete credential');
      }

      return await response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['websiteCredentials'] });
      toast({
        title: "Credential deleted",
        description: "The website credential has been successfully deleted.",
      });
    },
    onError: (error) => {
      console.error('Error deleting credential:', error);
      toast({
        title: "Error",
        description: "There was an error deleting the credential. Please try again.",
        variant: "destructive",
      });
    }
  });

  // Filter credentials by search term
  const filteredCredentials = credentials?.filter(cred => 
    cred.business_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    cred.admin_url.toLowerCase().includes(searchTerm.toLowerCase()) ||
    cred.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle credential status badge
  const renderStatusBadge = (status: string) => {
    switch (status) {
      case 'expired':
        return <Badge variant="destructive">Expired</Badge>;
      case 'expiring_soon':
        return <Badge variant="outline" className="bg-yellow-500 text-white border-yellow-600">Expiring Soon</Badge>;
      default:
        return <Badge variant="outline">Valid</Badge>;
    }
  };

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Website Admin Credentials</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-red-500">Error loading credentials: {(error as any).message}</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Website Admin Credentials</CardTitle>
        <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
          <DialogTrigger asChild>
            <Button>Add New Credential</Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[500px]">
            <DialogHeader>
              <DialogTitle>Add New Website Credential</DialogTitle>
            </DialogHeader>
            <CredentialForm mode="add" onClose={() => setIsAddDialogOpen(false)} />
          </DialogContent>
        </Dialog>
      </CardHeader>
      <CardContent>
        <div className="mb-4 flex items-center space-x-2">
          <Input
            placeholder="Search by business name, URL or username..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-xs"
          />
          {/* Filter by status dropdown could be added here */}
        </div>
        
        {isLoading ? (
          <div className="space-y-2">
            {[1, 2, 3, 4, 5].map(i => (
              <div key={i} className="flex items-center space-x-4">
                <Skeleton className="h-12 w-full rounded-md" />
              </div>
            ))}
          </div>
        ) : (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Business</TableHead>
                  <TableHead>Admin URL</TableHead>
                  <TableHead>Username</TableHead>
                  <TableHead>Password</TableHead>
                  <TableHead>Expiration</TableHead>
                  <TableHead>Notes</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredCredentials?.length ? (
                  filteredCredentials.map((credential) => (
                    <TableRow key={credential.id}>
                      <TableCell className="font-medium">{credential.business_name}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <a 
                            href={credential.admin_url.startsWith('http') ? credential.admin_url : `https://${credential.admin_url}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 underline"
                          >
                            {credential.admin_url}
                          </a>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-6 w-6"
                                  onClick={() => copyToClipboard(credential.admin_url, 'Admin URL')}
                                >
                                  <Copy className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Copy URL</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span>{credential.username}</span>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-6 w-6"
                                  onClick={() => copyToClipboard(credential.username, 'Username')}
                                >
                                  <Copy className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Copy username</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <span className="font-mono">
                            {showPasswords[credential.id] 
                              ? credential.password_decrypted 
                              : '••••••••'}
                          </span>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-6 w-6"
                                  onClick={() => togglePasswordVisibility(credential.id)}
                                >
                                  {showPasswords[credential.id] ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>{showPasswords[credential.id] ? 'Hide password' : 'Show password'}</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-6 w-6"
                                  onClick={() => copyToClipboard(credential.password_decrypted, 'Password')}
                                >
                                  <Copy className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Copy password</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TableCell>
                      <TableCell>
                        {credential.expires_at ? (
                          <div className="flex items-center space-x-2">
                            {renderStatusBadge(credential.password_status)}
                            <span>{format(new Date(credential.expires_at), 'MMM d, yyyy')}</span>
                          </div>
                        ) : (
                          <span className="text-gray-500">No expiration</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {credential.notes ? (
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button variant="outline" size="sm">View Notes</Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-80 p-4">
                              <h4 className="font-semibold mb-2">Notes</h4>
                              <p className="text-sm whitespace-pre-wrap">{credential.notes}</p>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          <span className="text-gray-500">No notes</span>
                        )}
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end space-x-2">
                          <Dialog open={isEditDialogOpen && selectedCredential?.id === credential.id} onOpenChange={(open) => {
                            if (!open) setSelectedCredential(null);
                            setIsEditDialogOpen(open);
                          }}>
                            <DialogTrigger asChild>
                              <Button
                                variant="outline"
                                size="icon"
                                onClick={() => setSelectedCredential(credential)}
                              >
                                <Edit className="h-4 w-4" />
                              </Button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[500px]">
                              <DialogHeader>
                                <DialogTitle>Edit Credential for {credential.business_name}</DialogTitle>
                              </DialogHeader>
                              {selectedCredential && (
                                <CredentialForm 
                                  credential={selectedCredential} 
                                  mode="edit" 
                                  onClose={() => {
                                    setSelectedCredential(null);
                                    setIsEditDialogOpen(false);
                                  }} 
                                />
                              )}
                            </DialogContent>
                          </Dialog>
                          
                          <Dialog>
                            <DialogTrigger asChild>
                              <Button variant="destructive" size="icon">
                                <Trash className="h-4 w-4" />
                              </Button>
                            </DialogTrigger>
                            <DialogContent>
                              <DialogHeader>
                                <DialogTitle>Confirm Deletion</DialogTitle>
                              </DialogHeader>
                              <p>Are you sure you want to delete the credentials for {credential.business_name}?</p>
                              <p className="text-red-500">This action cannot be undone.</p>
                              <DialogFooter>
                                <DialogClose asChild>
                                  <Button variant="outline">Cancel</Button>
                                </DialogClose>
                                <Button 
                                  variant="destructive" 
                                  onClick={() => deleteCredentialMutation.mutate(credential.id)}
                                  disabled={deleteCredentialMutation.isPending}
                                >
                                  {deleteCredentialMutation.isPending ? 'Deleting...' : 'Delete'}
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} className="text-center py-4">
                      {searchTerm 
                        ? 'No credentials match your search'
                        : 'No credentials added yet. Click "Add New Credential" to get started.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default WebsiteCredentialsTable;
