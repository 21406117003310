
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { SignupFlowSteps } from "@/components/onboarding/SignupFlowSteps";
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { LoadingSpinner } from "@/components/routing/LoadingSpinner";
import { PaymentSuccess } from "@/components/onboarding/steps/checkout/PaymentSuccess";

export const ChatSignupPage = () => {
  const { packageType } = useParams<{ packageType: string }>();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [tempUserId, setTempUserId] = useState<string | null>(null);
  const [paymentRefId, setPaymentRefId] = useState<string | null>(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  // Enhanced to check if we have a temp user from a previous session
  useEffect(() => {
    const checkTempUserData = async () => {
      try {
        setIsLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');

        // Check if we have a temp user ID or payment ref ID in session storage
        const storedTempUserId = sessionStorage.getItem('temp_user_id');
        const storedRefId = sessionStorage.getItem('payment_ref_id');
        
        console.log('Starting temp user check:', { 
          email,
          storedTempUserId,
          storedRefId
        });
        
        if (storedTempUserId && storedRefId) {
          console.log('Found stored temp user data:', { tempUserId: storedTempUserId, refId: storedRefId });
          setTempUserId(storedTempUserId);
          setPaymentRefId(storedRefId);
          
          // Verify the temp user exists
          const { data: tempData, error: tempUserError } = await supabase
            .from('temp_user_data')
            .select('*')
            .eq('id', storedTempUserId)
            .single();
            
          if (!tempUserError && tempData) {
            console.log('Verified temp user data:', tempData);
            setFormData({
              email: tempData.email,
              firstName: tempData.first_name,
              lastName: tempData.last_name,
              phoneNumber: tempData.phone_number,
              businessName: tempData.business_name,
              streetAddress: tempData.street_address,
              city: tempData.city,
              state: tempData.state,
              zipCode: tempData.zip_code,
            });
            setStep(2);
            setIsLoading(false);
            return;
          } else {
            console.log('Could not verify stored temp user, clearing session storage:', tempUserError);
            sessionStorage.removeItem('temp_user_id');
            sessionStorage.removeItem('payment_ref_id');
          }
        }

        if (email) {
          console.log('Looking up temp user by email:', email);
          const { data: tempData, error } = await supabase
            .from('temp_user_data')
            .select('*')
            .eq('email', email)
            .eq('completed', false)
            .single();

          if (error) {
            console.error('Error fetching temp user data:', error);
          } else if (tempData) {
            console.log('Found temp user by email:', tempData);
            setFormData({
              email: tempData.email,
              firstName: tempData.first_name,
              lastName: tempData.last_name,
              phoneNumber: tempData.phone_number,
              businessName: tempData.business_name,
              streetAddress: tempData.street_address,
              city: tempData.city,
              state: tempData.state,
              zipCode: tempData.zip_code,
            });
            setTempUserId(tempData.id);
            
            // Store the temp user ID in session storage
            try {
              sessionStorage.setItem('temp_user_id', tempData.id);
              console.log('Stored temp user ID in session storage:', tempData.id);
            } catch (storageError) {
              console.error('Failed to store temp user ID in session storage:', storageError);
            }
            
            setStep(2);
          } else {
            console.log('No existing temp user found for email:', email);
          }
        }
      } catch (error) {
        console.error('Error in checkTempUserData:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkTempUserData();
  }, []);

  const handleCombinedInfoComplete = async (data: any) => {
    console.log("Step 1 completed, storing form data:", data);
    setFormData(data);
    setStep(2);
  };

  const handleCheckoutComplete = async (refId?: string) => {
    console.log("Payment completed successfully, refId:", refId);
    if (refId) {
      setPaymentRefId(refId);
      try {
        sessionStorage.setItem('payment_ref_id', refId);
      } catch (storageError) {
        console.error('Failed to store payment_ref_id in session storage:', storageError);
      }
    }
    setShowPaymentSuccess(true);
  };

  const handleGoogleConnect = () => {
    window.open('https://woobound.digitalsero.app/clients/onboarding/c50a97dd-5503-4c9b-89a3-1f8c795a0b95', '_blank');
    setIsGoogleConnected(true);
  };

  const handleGoogleConnectComplete = async (connected: boolean) => {
    try {
      console.log("Starting user account creation...");
      
      // Ensure we have the temp user ID for reference
      const finalTempUserId = tempUserId;
      console.log("Temp user ID for account creation:", finalTempUserId);
      
      if (!finalTempUserId) {
        console.warn("No temp user ID available for profile linking");
        toast({
          title: "Warning",
          description: "Unable to link your subscription. Please contact support for assistance.",
          variant: "destructive",
        });
      }
      
      // Create the auth user account with temp user reference in metadata
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            temp_user_reference: finalTempUserId // Include in user metadata
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData.user) throw new Error("Failed to create user account");

      console.log("User account created, user ID:", authData.user.id);

      // Wait for the profile to be created via trigger
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Get the user session
      const { data: { user }, error: sessionError } = await supabase.auth.getUser();
      if (sessionError) throw sessionError;
      if (!user) throw new Error("No authenticated user found");

      // Check if the profile was correctly created with the temp_user_reference
      const { data: profileData, error: profileCheckError } = await supabase
        .from('profiles')
        .select('temp_user_reference')
        .eq('id', user.id)
        .single();
        
      if (profileCheckError) {
        console.error("Error checking profile:", profileCheckError);
      } else if (!profileData.temp_user_reference && finalTempUserId) {
        console.log("Profile missing temp_user_reference, updating manually:", finalTempUserId);
        
        // Profile was created but temp_user_reference wasn't set, update it manually
        const { error: profileUpdateError } = await supabase
          .from('profiles')
          .update({
            temp_user_reference: finalTempUserId
          })
          .eq('id', user.id);

        if (profileUpdateError) {
          console.error("Error updating profile with temp user reference:", profileUpdateError);
        } else {
          console.log("Profile updated with temp user reference");
        }
      } else {
        console.log("Profile already has temp_user_reference:", profileData.temp_user_reference);
      }

      console.log("Creating business profile...");

      // Now create the business profile
      const { error: businessError } = await supabase
        .from('business_profiles')
        .insert({
          user_id: user.id,
          business_name: formData.businessName,
          website_url: formData.websiteUrl || null,
          street_address: formData.streetAddress,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          category: formData.businessCategory,
          phone_number: formData.businessPhoneNumber || formData.phoneNumber,
          business_email: formData.email
        });

      if (businessError) {
        console.error("Business profile creation error:", businessError);
        throw businessError;
      }

      console.log("Creating onboarding session...");

      const { error: sessionError2 } = await supabase
        .from('onboarding_sessions')
        .insert({
          user_id: user.id,
          package_type: packageType,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          business_name: formData.businessName,
          website_url: formData.websiteUrl,
          business_category: formData.businessCategory,
          street_address: formData.streetAddress,
          google_business_connected: connected,
          completed: true,
          checkout_completed: true
        });

      if (sessionError2) {
        console.error("Onboarding session creation error:", sessionError2);
        throw sessionError2;
      }

      // Enhanced: Make multiple attempts to ensure subscriptions are migrated
      if (finalTempUserId) {
        console.log("Migrating subscriptions from temp user to profile...");
        
        // First try with the temp user ID
        let migrationSuccess = false;
        let retryCount = 0;
        const maxRetries = 5;
          
        while (!migrationSuccess && retryCount < maxRetries) {
          try {
            console.log(`Attempt ${retryCount + 1} to migrate subscriptions...`);
            
            const { data: migrationData, error: migrationError } = await supabase.functions.invoke(
              'fix-existing-subscriptions',
              {
                body: {
                  userId: user.id, 
                  tempUserId: finalTempUserId,
                  email: formData.email,
                  refId: paymentRefId || undefined,
                  fixType: 'migrate_temp_subscriptions'
                }
              }
            );
                
            if (migrationError) {
              console.error(`Attempt ${retryCount + 1}: Error migrating subscriptions:`, migrationError);
              retryCount++;
              await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount))); // Exponential backoff
            } else if (migrationData && migrationData.success) {
              console.log(`Attempt ${retryCount + 1}: Successfully migrated subscriptions:`, migrationData);
              migrationSuccess = true;
            } else {
              console.warn(`Attempt ${retryCount + 1}: Migration response unsuccessful:`, migrationData);
              retryCount++;
              await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
            }
          } catch (migrationExError) {
            console.error(`Attempt ${retryCount + 1}: Exception during subscription migration:`, migrationExError);
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
          }
        }
        
        if (!migrationSuccess) {
          // As a last resort, try a different approach - check subscription details
          try {
            console.log("Trying alternative subscription verification approach...");
            const { data: verifyData, error: verifyError } = await supabase.functions.invoke(
              'fix-existing-subscriptions',
              {
                body: {
                  userId: user.id, 
                  email: formData.email,
                  fixType: 'get_subscription_details'
                }
              }
            );
            
            if (!verifyError && verifyData && (verifyData.fromProfile || verifyData.autoMigrated)) {
              console.log("Subscription verification successful:", verifyData);
              migrationSuccess = true;
            } else {
              console.warn("Subscription verification unsuccessful:", verifyData);
            }
          } catch (verifyExError) {
            console.error("Exception during subscription verification:", verifyExError);
          }
        }
        
        if (!migrationSuccess) {
          toast({
            title: "Warning",
            description: "Your account was created, but we had trouble linking your subscription. Our system will try again automatically.",
            variant: "destructive",
          });
        }
        
        // Update temp user data as completed
        const { error: tempUpdateError } = await supabase
          .from('temp_user_data')
          .update({ completed: true })
          .eq('id', finalTempUserId);
            
        if (tempUpdateError) {
          console.error("Error marking temp user as completed:", tempUpdateError);
        }
      }

      // Clear the session storage after successful profile creation
      try {
        sessionStorage.removeItem('temp_user_id');
        sessionStorage.removeItem('payment_ref_id');
        console.log("Cleared session storage after successful signup");
      } catch (storageError) {
        console.error('Failed to clear session storage:', storageError);
      }

      console.log("All operations completed successfully, navigating to dashboard...");
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error completing onboarding:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  if (!packageType || !["maps_seo", "maps_website_seo"].includes(packageType)) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (showPaymentSuccess) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center p-4">
        <div className="w-full max-w-3xl">
          <PaymentSuccess
            onGoogleConnect={handleGoogleConnect}
            onSkip={() => handleGoogleConnectComplete(false)}
            isConnected={isGoogleConnected}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-3xl">
        <SignupFlowSteps
          step={step}
          personalInfo={formData}
          businessInfo={formData}
          onCombinedInfoComplete={handleCombinedInfoComplete}
          onCheckoutComplete={handleCheckoutComplete}
          onGoogleConnectComplete={handleGoogleConnectComplete}
          packageType={packageType as "maps_seo" | "maps_website_seo"}
        />
      </div>
    </div>
  );
};

export default ChatSignupPage;
