
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { CombinedFormData } from "../types";
import { CheckCircle, XCircle, Loader2 } from "lucide-react";

interface EmailValidationState {
  isCheckingEmail: boolean;
  emailExists: boolean;
  emailChecked: boolean;
}

interface PersonalInfoFieldsProps {
  formData: CombinedFormData;
  isSubmitting: boolean;
  onChange: (field: keyof CombinedFormData, value: string) => void;
  emailValidation: EmailValidationState;
}

export const PersonalInfoFields = ({ 
  formData, 
  isSubmitting, 
  onChange,
  emailValidation 
}: PersonalInfoFieldsProps) => {
  const { isCheckingEmail, emailExists, emailChecked } = emailValidation;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange('email', value);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Personal Information</h2>
      <p className="text-sm text-muted-foreground -mt-2 mb-4">
        This information will be used for us to contact you and collaborate on your campaign.
      </p>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="email" className="flex items-center">
            Email address <span className="text-red-500">*</span>
          </Label>
          <div className="relative">
            <Input
              id="email"
              type="email"
              value={formData.email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              disabled={isSubmitting}
              required
              className={emailExists && emailChecked ? "pr-10 border-red-500" : emailChecked && !isCheckingEmail && !emailExists && formData.email.length > 5 && formData.email.includes('@') ? "pr-10 border-green-500" : "pr-10"}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {isCheckingEmail && (
                <Loader2 className="h-4 w-4 animate-spin text-gray-400" />
              )}
              {emailChecked && !isCheckingEmail && emailExists && (
                <XCircle className="h-5 w-5 text-red-500" />
              )}
              {emailChecked && !isCheckingEmail && !emailExists && formData.email.length > 5 && formData.email.includes('@') && (
                <CheckCircle className="h-5 w-5 text-green-500" />
              )}
            </div>
          </div>
          {emailExists && emailChecked && (
            <p className="text-sm text-red-500">This email address is already in use. Please use a different email or sign in with this email.</p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="password">Password <span className="text-red-500">*</span></Label>
          <Input
            id="password"
            type="password"
            value={formData.password}
            onChange={(e) => onChange('password', e.target.value)}
            placeholder="Create a password"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="firstName">First name <span className="text-red-500">*</span></Label>
          <Input
            id="firstName"
            type="text"
            value={formData.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            placeholder="Enter your first name"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="lastName">Last name <span className="text-red-500">*</span></Label>
          <Input
            id="lastName"
            type="text"
            value={formData.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            placeholder="Enter your last name"
            disabled={isSubmitting}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="phoneNumber">Mobile Phone (optional for texting)</Label>
          <Input
            id="phoneNumber"
            type="tel"
            value={formData.phoneNumber}
            onChange={(e) => onChange('phoneNumber', e.target.value)}
            placeholder="Enter your mobile phone number"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};
