
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Plus } from "lucide-react";
import { format } from "date-fns";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Loader2, Eye, EyeOff, Trash, Edit, Copy } from "lucide-react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

interface WebsiteCredential {
  id: string;
  business_id: string;
  admin_url: string;
  username: string;
  notes: string | null;
  created_at: string;
  updated_at: string;
  expires_at: string | null;
  password_last_updated: string;
}

interface CredentialFormData {
  id?: string;
  admin_url: string;
  username: string;
  password?: string;
  notes?: string;
  expires_at?: string | null;
}

interface WebsiteCredentialsSectionProps {
  businessId: string;
}

export const WebsiteCredentialsSection = ({ businessId }: WebsiteCredentialsSectionProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState<WebsiteCredential | null>(null);
  const [formData, setFormData] = useState<CredentialFormData>({
    admin_url: "",
    username: "",
    password: "",
    notes: "",
    expires_at: null,
  });
  const [showPassword, setShowPassword] = useState(false);

  // Fetch website credentials
  const {
    data: credentials,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["websiteCredentials", businessId],
    queryFn: async () => {
      const { data, error } = await supabase.functions.invoke("website-credentials", {
        body: {
          action: "get",
          data: { businessId },
        },
      });

      if (error) throw new Error(error.message);
      return data.data || [];
    },
  });

  // Add credential mutation
  const addCredentialMutation = useMutation({
    mutationFn: async (data: CredentialFormData) => {
      const { data: response, error } = await supabase.functions.invoke("website-credentials", {
        body: {
          action: "add",
          data: {
            businessId,
            adminUrl: data.admin_url,
            username: data.username,
            password: data.password,
            notes: data.notes || null,
            expiresAt: data.expires_at,
          },
        },
      });

      if (error) throw new Error(error.message);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["websiteCredentials", businessId] });
      setIsAddDialogOpen(false);
      toast({
        title: t("business.website_credentials.added"),
        description: t("business.website_credentials.add_success"),
      });
      resetForm();
    },
    onError: (error) => {
      console.error("Error adding credential:", error);
      toast({
        title: t("business.website_credentials.error"),
        description: t("business.website_credentials.add_error"),
        variant: "destructive",
      });
    },
  });

  // Update credential mutation
  const updateCredentialMutation = useMutation({
    mutationFn: async (data: CredentialFormData) => {
      if (!data.id) throw new Error("Credential ID is required");

      const { data: response, error } = await supabase.functions.invoke("website-credentials", {
        body: {
          action: "update",
          data: {
            id: data.id,
            adminUrl: data.admin_url,
            username: data.username,
            password: data.password, // if empty, the backend will keep existing password
            notes: data.notes || null,
            expiresAt: data.expires_at,
          },
        },
      });

      if (error) throw new Error(error.message);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["websiteCredentials", businessId] });
      setIsEditDialogOpen(false);
      toast({
        title: t("business.website_credentials.updated"),
        description: t("business.website_credentials.update_success"),
      });
      resetForm();
    },
    onError: (error) => {
      console.error("Error updating credential:", error);
      toast({
        title: t("business.website_credentials.error"),
        description: t("business.website_credentials.update_error"),
        variant: "destructive",
      });
    },
  });

  // Delete credential mutation
  const deleteCredentialMutation = useMutation({
    mutationFn: async (id: string) => {
      const { data: response, error } = await supabase.functions.invoke("website-credentials", {
        body: {
          action: "delete",
          data: { id },
        },
      });

      if (error) throw new Error(error.message);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["websiteCredentials", businessId] });
      setIsDeleteDialogOpen(false);
      toast({
        title: t("business.website_credentials.deleted"),
        description: t("business.website_credentials.delete_success"),
      });
      setSelectedCredential(null);
    },
    onError: (error) => {
      console.error("Error deleting credential:", error);
      toast({
        title: t("business.website_credentials.error"),
        description: t("business.website_credentials.delete_error"),
        variant: "destructive",
      });
    },
  });

  const handleInputChange = (
    field: keyof CredentialFormData,
    value: string | null
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      admin_url: "",
      username: "",
      password: "",
      notes: "",
      expires_at: null,
    });
    setShowPassword(false);
  };

  const handleAddSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addCredentialMutation.mutate(formData);
  };

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateCredentialMutation.mutate(formData);
  };

  const handleDelete = () => {
    if (selectedCredential) {
      deleteCredentialMutation.mutate(selectedCredential.id);
    }
  };

  const handleEdit = (credential: WebsiteCredential) => {
    setSelectedCredential(credential);
    setFormData({
      id: credential.id,
      admin_url: credential.admin_url,
      username: credential.username,
      password: "", // password is not returned from API, only stored
      notes: credential.notes || "",
      expires_at: credential.expires_at,
    });
    setIsEditDialogOpen(true);
  };

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: t("business.website_credentials.copied", { type }),
          duration: 2000,
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return t("business.website_credentials.no_expiration");
    return format(new Date(dateString), "PP");
  };

  return (
    <div className="mt-12">
      <Card>
        <CardHeader>
          <CardTitle>{t("business.website_credentials.title")}</CardTitle>
          <CardDescription>
            {t("business.website_credentials.description")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
            </div>
          ) : error ? (
            <div className="text-center py-6 text-destructive">
              {t("business.website_credentials.load_error")}
            </div>
          ) : credentials?.length === 0 ? (
            <div className="text-center py-6 text-muted-foreground">
              {t("business.website_credentials.no_credentials")}
            </div>
          ) : (
            <div className="space-y-4">
              {credentials?.map((credential: WebsiteCredential) => (
                <div
                  key={credential.id}
                  className="border rounded-lg p-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4"
                >
                  <div className="flex-grow space-y-1">
                    <div className="font-medium break-all">{credential.admin_url}</div>
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <span>{credential.username}</span>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-5 w-5"
                        onClick={() => copyToClipboard(credential.username, "Username")}
                        title={t("business.website_credentials.copy_username")}
                      >
                        <Copy className="h-3 w-3" />
                      </Button>
                    </div>
                    <div className="flex flex-wrap gap-x-4 gap-y-1 text-xs text-muted-foreground mt-2">
                      <div>
                        <span className="font-medium">
                          {t("business.website_credentials.last_updated")}:
                        </span>{" "}
                        {formatDate(credential.password_last_updated)}
                      </div>
                      {credential.expires_at && (
                        <div>
                          <span className="font-medium">
                            {t("business.website_credentials.expiration")}:
                          </span>{" "}
                          {formatDate(credential.expires_at)}
                        </div>
                      )}
                      {credential.notes && (
                        <Button
                          variant="link"
                          className="text-xs p-0 h-auto"
                          onClick={() => {
                            toast({
                              title: t("business.website_credentials.view_notes"),
                              description: credential.notes || t("business.website_credentials.no_notes"),
                              duration: 5000,
                            });
                          }}
                        >
                          {t("business.website_credentials.view_notes")}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="flex space-x-2 justify-end">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleEdit(credential)}
                    >
                      <Edit className="h-4 w-4 mr-1" />
                      <span className="sr-only md:not-sr-only md:inline-block">
                        Edit
                      </span>
                    </Button>
                    <AlertDialog open={isDeleteDialogOpen && selectedCredential?.id === credential.id} onOpenChange={(open) => !open && setSelectedCredential(null)}>
                      <AlertDialogTrigger asChild>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() => {
                            setSelectedCredential(credential);
                            setIsDeleteDialogOpen(true);
                          }}
                        >
                          <Trash className="h-4 w-4 mr-1" />
                          <span className="sr-only md:not-sr-only md:inline-block">
                            Delete
                          </span>
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>{t("business.website_credentials.confirm_delete")}</AlertDialogTitle>
                          <AlertDialogDescription>
                            {t("business.website_credentials.delete_warning")}
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel onClick={() => setIsDeleteDialogOpen(false)}>
                            Cancel
                          </AlertDialogCancel>
                          <AlertDialogAction onClick={handleDelete}>
                            Delete
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="mt-6">
            <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
              <DialogTrigger asChild>
                <Button className="w-full sm:w-auto" onClick={resetForm}>
                  <Plus className="h-4 w-4 mr-2" />
                  {t("business.website_credentials.add_new")}
                </Button>
              </DialogTrigger>
              <DialogContent>
                <form onSubmit={handleAddSubmit}>
                  <DialogHeader>
                    <DialogTitle>{t("business.website_credentials.add_title")}</DialogTitle>
                    <DialogDescription>
                      {t("business.website_credentials.description")}
                    </DialogDescription>
                  </DialogHeader>
                  <div className="space-y-4 py-4">
                    <div className="space-y-2">
                      <Label htmlFor="admin_url">
                        {t("business.website_credentials.admin_url")}*
                      </Label>
                      <Input
                        id="admin_url"
                        placeholder="https://admin.example.com"
                        value={formData.admin_url}
                        onChange={(e) => handleInputChange("admin_url", e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="username">
                        {t("business.website_credentials.username")}*
                      </Label>
                      <Input
                        id="username"
                        placeholder="username"
                        value={formData.username}
                        onChange={(e) => handleInputChange("username", e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="password">
                        {t("business.website_credentials.password")}*
                      </Label>
                      <div className="relative">
                        <Input
                          id="password"
                          type={showPassword ? "text" : "password"}
                          placeholder={t("business.website_credentials.enter_password")}
                          value={formData.password}
                          onChange={(e) => handleInputChange("password", e.target.value)}
                          required
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="absolute right-0 top-0 h-full"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeOff className="h-4 w-4" />
                          ) : (
                            <Eye className="h-4 w-4" />
                          )}
                          <span className="sr-only">
                            {showPassword ? "Hide password" : "Show password"}
                          </span>
                        </Button>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="expires_at">
                        {t("business.website_credentials.expiration_date")}
                      </Label>
                      <Input
                        id="expires_at"
                        type="date"
                        value={formData.expires_at || ""}
                        onChange={(e) => {
                          const value = e.target.value ? e.target.value : null;
                          handleInputChange("expires_at", value);
                        }}
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="notes">
                        {t("business.website_credentials.notes")}
                      </Label>
                      <Textarea
                        id="notes"
                        placeholder={t("business.website_credentials.notes_placeholder")}
                        value={formData.notes || ""}
                        onChange={(e) => handleInputChange("notes", e.target.value)}
                        rows={3}
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setIsAddDialogOpen(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={addCredentialMutation.isPending}
                    >
                      {addCredentialMutation.isPending && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Save
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>

            <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
              <DialogContent>
                <form onSubmit={handleEditSubmit}>
                  <DialogHeader>
                    <DialogTitle>{t("business.website_credentials.edit_title")}</DialogTitle>
                    <DialogDescription>
                      {t("business.website_credentials.description")}
                    </DialogDescription>
                  </DialogHeader>
                  <div className="space-y-4 py-4">
                    <div className="space-y-2">
                      <Label htmlFor="edit_admin_url">
                        {t("business.website_credentials.admin_url")}*
                      </Label>
                      <Input
                        id="edit_admin_url"
                        placeholder="https://admin.example.com"
                        value={formData.admin_url}
                        onChange={(e) => handleInputChange("admin_url", e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="edit_username">
                        {t("business.website_credentials.username")}*
                      </Label>
                      <Input
                        id="edit_username"
                        placeholder="username"
                        value={formData.username}
                        onChange={(e) => handleInputChange("username", e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="edit_password">
                        {t("business.website_credentials.new_password_optional")}
                      </Label>
                      <div className="relative">
                        <Input
                          id="edit_password"
                          type={showPassword ? "text" : "password"}
                          placeholder={t("business.website_credentials.leave_blank")}
                          value={formData.password}
                          onChange={(e) => handleInputChange("password", e.target.value)}
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="absolute right-0 top-0 h-full"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeOff className="h-4 w-4" />
                          ) : (
                            <Eye className="h-4 w-4" />
                          )}
                          <span className="sr-only">
                            {showPassword ? "Hide password" : "Show password"}
                          </span>
                        </Button>
                      </div>
                      <p className="text-sm text-muted-foreground">
                        {t("business.website_credentials.leave_blank")}
                      </p>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="edit_expires_at">
                        {t("business.website_credentials.expiration_date")}
                      </Label>
                      <Input
                        id="edit_expires_at"
                        type="date"
                        value={formData.expires_at || ""}
                        onChange={(e) => {
                          const value = e.target.value ? e.target.value : null;
                          handleInputChange("expires_at", value);
                        }}
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="edit_notes">
                        {t("business.website_credentials.notes")}
                      </Label>
                      <Textarea
                        id="edit_notes"
                        placeholder={t("business.website_credentials.notes_placeholder")}
                        value={formData.notes || ""}
                        onChange={(e) => handleInputChange("notes", e.target.value)}
                        rows={3}
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setIsEditDialogOpen(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={updateCredentialMutation.isPending}
                    >
                      {updateCredentialMutation.isPending && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Update
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
