
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CheckCircle, ChevronRight, Star, Award } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { usePackageData } from "@/hooks/usePackageData";
import { Skeleton } from "@/components/ui/skeleton";
import { useTranslation } from "react-i18next";
import { Badge } from "@/components/ui/badge";

export const Pricing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: mapsSEOPackage, isLoading: isLoadingMapsSEO } = usePackageData("maps_seo");
  const { data: mapsWebsitePackage, isLoading: isLoadingMapsWebsite } = usePackageData("maps_website_seo");

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    contactSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return <section id="pricing" className="py-20 px-4 bg-[#DBAE7B]/10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12 text-[#333333]">{t('landing.pricing.title')}</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {/* Maps SEO Card */}
          <Card className="hover:shadow-lg transition-shadow border-[#DBAE7B]/20">
            <CardHeader>
              <div className="flex items-center gap-2 mb-2">
                <Star className="text-[#e0933c]" />
                <CardTitle className="text-[#333333]">{t('landing.pricing.mapsSeo.title')}</CardTitle>
              </div>
              {isLoadingMapsSEO ? (
                <Skeleton className="h-8 w-24" />
              ) : (
                <CardDescription>
                  <span className="text-2xl font-bold text-[#e0933c]">${mapsSEOPackage?.base_price}</span>/month
                </CardDescription>
              )}
              <p className="text-sm text-[#333333]/80 mt-2">
                {t('landing.pricing.mapsSeo.description')}
              </p>
            </CardHeader>
            <CardContent>
              <p className="text-sm font-medium text-[#333333] mb-2">{t('landing.pricing.mapsSeo.includes')}</p>
              {isLoadingMapsSEO ? (
                <div className="space-y-3 mb-6">
                  {[1, 2, 3, 4, 5].map(i => (
                    <Skeleton key={i} className="h-5 w-full" />
                  ))}
                </div>
              ) : (
                <ul className="space-y-3 mb-6">
                  {[
                    "Google & Apple Maps Optimization",
                    "Local Listing Management",
                    "Maps Keyword Optimization",
                    "Photo & Video Optimization",
                    "Performance Analytics & Reporting"
                  ].map(feature => (
                    <li key={feature} className="flex items-center gap-2">
                      <CheckCircle className="text-[#e0933c] h-5 w-5 flex-shrink-0" />
                      <span className="text-[#333333]">{feature}</span>
                    </li>
                  ))}
                </ul>
              )}
              
              <div className="mb-6">
                <p className="text-sm font-medium text-[#333333] mb-2">{t('landing.pricing.mapsSeo.whyItMatters')}</p>
                <ul className="space-y-3">
                  {[
                    "More Customers Find You First – Show up when people search for your services nearby.",
                    "Increase Calls & Walk-ins – Make it easy for customers to contact or visit you.",
                    "Stay Active & Visible – Regular updates keep your business fresh in search results."
                  ].map(point => (
                    <li key={point} className="flex items-start gap-2">
                      <CheckCircle className="text-[#e0933c] h-5 w-5 flex-shrink-0 mt-0.5" />
                      <span className="text-[#333333] text-sm">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <p className="text-sm text-[#333333]/80 mb-6">
                {t('landing.pricing.mapsSeo.bestFor')}
              </p>
              <Button className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90" onClick={() => navigate('/signup/maps_seo')}>
                {t('landing.pricing.mapsSeo.getStarted')} <ChevronRight />
              </Button>
            </CardContent>
          </Card>

          {/* Maps + Website SEO Card - FEATURED */}
          <Card className="hover:shadow-xl transition-all border-[#e0933c] md:scale-105 relative md:transform-none bg-gradient-to-br from-white to-[#DBAE7B]/10">
            <div className="absolute -top-3 left-0 right-0 flex justify-center">
              <Badge variant="outline" className="bg-[#e0933c] text-white border-[#e0933c] px-3 py-1 text-xs font-semibold">
                <Star className="h-3.5 w-3.5 mr-1" /> {t('landing.pricing.mapsWebsiteSeo.mostPopular')}
              </Badge>
            </div>
            <CardHeader className="pt-8">
              <div className="flex items-center gap-2 mb-2">
                <Award className="text-[#e0933c]" />
                <CardTitle className="text-[#333333]">{t('landing.pricing.mapsWebsiteSeo.title')}</CardTitle>
              </div>
              {isLoadingMapsWebsite ? (
                <Skeleton className="h-8 w-24" />
              ) : (
                <CardDescription>
                  <span className="text-2xl font-bold text-[#e0933c]">${mapsWebsitePackage?.base_price}</span>/month
                </CardDescription>
              )}
              <p className="text-sm text-[#333333]/80 mt-2">
                {t('landing.pricing.mapsWebsiteSeo.description')}
              </p>
            </CardHeader>
            <CardContent>
              <p className="text-sm font-medium text-[#333333] mb-2">{t('landing.pricing.mapsWebsiteSeo.includes')}</p>
              {isLoadingMapsWebsite ? (
                <div className="space-y-3 mb-6">
                  {[1, 2, 3, 4, 5].map(i => (
                    <Skeleton key={i} className="h-5 w-full" />
                  ))}
                </div>
              ) : (
                <ul className="space-y-3 mb-6">
                  {[
                    "Everything in Maps SEO, PLUS:",
                    "Website Optimization to Rank Higher on Google",
                    "More Website Visitors & Leads",
                    "Stronger Online Authority & Trust",
                    "Performance Analytics & Reporting"
                  ].map(feature => (
                    <li key={feature} className="flex items-center gap-2">
                      <CheckCircle className="text-[#e0933c] h-5 w-5 flex-shrink-0" />
                      <span className="text-[#333333]">{feature}</span>
                    </li>
                  ))}
                </ul>
              )}
              
              <div className="mb-6">
                <p className="text-sm font-medium text-[#333333] mb-2">{t('landing.pricing.mapsWebsiteSeo.whyItMatters')}</p>
                <ul className="space-y-3">
                  {[
                    "Show Up on Google & Get More Clicks – Appear higher when customers search for your services.",
                    "Turn More Visitors into Customers – Improve your website so people take action (call or buy).",
                    "Build Long-Term Growth – A stronger website means more customers over time."
                  ].map(point => (
                    <li key={point} className="flex items-start gap-2">
                      <CheckCircle className="text-[#e0933c] h-5 w-5 flex-shrink-0 mt-0.5" />
                      <span className="text-[#333333] text-sm">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <p className="text-sm text-[#333333]/80 mb-6">
                {t('landing.pricing.mapsWebsiteSeo.bestFor')}
              </p>
              <Button className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90 shadow-md" onClick={() => navigate('/signup/maps_website_seo')}>
                {t('landing.pricing.mapsWebsiteSeo.getStarted')} <ChevronRight />
              </Button>
            </CardContent>
          </Card>

          {/* Custom SEO Card */}
          <Card className="hover:shadow-lg transition-shadow border-[#DBAE7B]/20">
            <CardHeader>
              <div className="flex items-center gap-2 mb-2">
                <Star className="text-[#e0933c]" />
                <CardTitle className="text-[#333333]">{t('landing.pricing.customSeo.title')}</CardTitle>
              </div>
              <CardDescription>
                <span className="text-2xl font-bold text-[#e0933c]">{t('landing.pricing.customSeo.pricesVary')}</span>
                <p className="text-sm">{t('landing.pricing.customSeo.customQuotes')}</p>
              </CardDescription>
              <p className="text-sm text-[#333333]/80 mt-2">
                {t('landing.pricing.customSeo.description')}
              </p>
            </CardHeader>
            <CardContent>
              <p className="text-sm font-medium text-[#333333] mb-2">{t('landing.pricing.customSeo.includes')}</p>
              <ul className="space-y-3 mb-6">
                {[
                  "Advanced SEO for Competitive Industries", 
                  "Multi-City & Multi-Location Targeting", 
                  "Fully Customized Growth Strategies", 
                  "Ongoing Optimization & Adjustments",
                  "Monthly Strategy Calls with SEO Experts"
                ].map(feature => (
                  <li key={feature} className="flex items-center gap-2">
                    <CheckCircle className="text-[#e0933c] h-5 w-5 flex-shrink-0" />
                    <span className="text-[#333333]">{feature}</span>
                  </li>
                ))}
              </ul>
              
              <div className="mb-6">
                <p className="text-sm font-medium text-[#333333] mb-2">{t('landing.pricing.customSeo.whyItMatters')}</p>
                <ul className="space-y-3">
                  {[
                    "Outrank the Competition – Get a strategy built to dominate in competitive markets.",
                    "Expand to More Locations – Rank in multiple cities and attract customers from different areas.",
                    "SEO That Grows with You – A flexible, high-impact approach tailored to your business goals."
                  ].map(point => (
                    <li key={point} className="flex items-start gap-2">
                      <CheckCircle className="text-[#e0933c] h-5 w-5 flex-shrink-0 mt-0.5" />
                      <span className="text-[#333333] text-sm">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <p className="text-sm text-[#333333]/80 mb-6">
                {t('landing.pricing.customSeo.bestFor')}
              </p>
              <Button className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90" onClick={scrollToContact}>
                {t('landing.pricing.customSeo.contactUs')} <ChevronRight />
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>;
};
