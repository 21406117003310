
import { useState } from "react";
import { CheckoutStep } from "./steps/CheckoutStep";
import { CombinedInfoStep } from "./steps/CombinedInfoStep";
import { GoogleConnectStep } from "./steps/GoogleConnectStep";
import { usePackageData } from "@/hooks/usePackageData";
import { SignupHeader } from "./SignupHeader";
import { SignupStepsIndicator } from "./SignupStepsIndicator";

interface SignupFlowStepsProps {
  step: number;
  personalInfo?: any;
  businessInfo?: any;
  onCombinedInfoComplete: (data: any) => void;
  onCheckoutComplete: () => void;
  onGoogleConnectComplete: (connected: boolean) => void;
  packageType: "maps_seo" | "maps_website_seo";
}

export const SignupFlowSteps = ({ 
  step, 
  onCombinedInfoComplete, 
  onCheckoutComplete,
  onGoogleConnectComplete,
  personalInfo,
  businessInfo,
  packageType
}: SignupFlowStepsProps) => {
  const packageDetails = usePackageData(packageType);
  
  const [isLoading, setIsLoading] = useState(false);

  // Define the steps for the signup process
  const steps = [
    { name: "Info", completed: step > 1 },
    { name: "Payment", completed: step > 2 },
    { name: "Connect", completed: false }
  ];

  const renderCurrentStep = () => {
    switch (step) {
      case 1:
        return (
          <CombinedInfoStep
            onComplete={onCombinedInfoComplete}
            formData={{
              ...personalInfo,
              packageType
            }}
          />
        );
      case 2:
        // Pass the business information to the PaymentForm
        return (
          <CheckoutStep
            packageType={packageType}
            onComplete={onCheckoutComplete}
            customerInfo={{
              firstName: personalInfo?.firstName,
              lastName: personalInfo?.lastName,
              email: personalInfo?.email,
              phoneNumber: personalInfo?.phoneNumber,
              street: personalInfo?.streetAddress,
              city: personalInfo?.city,
              state: personalInfo?.state,
              zipCode: personalInfo?.zipCode
            }}
            businessInfo={{
              name: personalInfo?.businessName,
              category: personalInfo?.businessCategory,
              websiteUrl: personalInfo?.websiteUrl,
              phoneNumber: personalInfo?.businessPhoneNumber
            }}
          />
        );
      case 3:
        return (
          <GoogleConnectStep
            onComplete={(connected) => onGoogleConnectComplete(connected)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-6">
      <SignupHeader />
      <SignupStepsIndicator currentStep={step} steps={steps} />
      <div className="bg-white p-6 rounded-lg shadow-sm border">
        {renderCurrentStep()}
      </div>
    </div>
  );
};
