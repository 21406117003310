
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PartnerCodesList } from "./PartnerCodesList";
import { CommissionRecords } from "./CommissionRecords";
import { CreateTestPartnerCode } from "./CreateTestPartnerCode";

export const PartnerCodeAnalytics = () => {
  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="md:w-3/4">
          <Tabs defaultValue="partner-codes" className="w-full">
            <TabsList>
              <TabsTrigger value="partner-codes">Partner Codes</TabsTrigger>
              <TabsTrigger value="commissions">Commissions</TabsTrigger>
            </TabsList>
            <TabsContent value="partner-codes" className="mt-4">
              <PartnerCodesList />
            </TabsContent>
            <TabsContent value="commissions" className="mt-4">
              <CommissionRecords />
            </TabsContent>
          </Tabs>
        </div>
        <div className="md:w-1/4">
          <CreateTestPartnerCode />
        </div>
      </div>
    </div>
  );
};
