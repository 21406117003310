
import { CalendarIcon } from 'lucide-react';
import { format, startOfDay } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

interface DateRangePickerProps {
  dateRange: {
    from: Date;
    to: Date;
  };
  setDateRange: (dateRange: { from: Date; to: Date }) => void;
  className?: string;
  disabled?: boolean;
}

export function DateRangePicker({
  dateRange,
  setDateRange,
  className,
  disabled = false,
}: DateRangePickerProps) {
  // Normalize dates to start of day
  const from = startOfDay(dateRange.from);
  const to = startOfDay(dateRange.to);

  console.log('DateRangePicker - Current dates:', {
    from: from.toISOString(),
    to: to.toISOString(),
  });

  return (
    <div className={cn('grid gap-4', className)}>
      <div className="flex flex-col md:flex-row gap-4">
        {/* Start Date Picker */}
        <div className="flex-1">
          <Label htmlFor="start-date" className="mb-2 block">
            Start Date
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="start-date"
                variant="outline"
                className={cn(
                  'w-full justify-start text-left font-normal',
                  disabled && 'opacity-50 cursor-not-allowed'
                )}
                disabled={disabled}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {format(from, 'LLL dd, y')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={from}
                onSelect={(date) => {
                  if (date && !disabled) {
                    const normalizedDate = startOfDay(date);
                    console.log('Start date selected:', normalizedDate.toISOString());
                    setDateRange({
                      from: normalizedDate,
                      to: dateRange.to,
                    });
                  }
                }}
                initialFocus
                disabled={(date) =>
                  disabled || date > dateRange.to || date > new Date()
                }
              />
              <div className="p-3 border-t border-border">
                <p className="text-sm text-muted-foreground">
                  Select start date
                </p>
              </div>
            </PopoverContent>
          </Popover>
        </div>

        {/* End Date Picker */}
        <div className="flex-1">
          <Label htmlFor="end-date" className="mb-2 block">
            End Date
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="end-date"
                variant="outline"
                className={cn(
                  'w-full justify-start text-left font-normal',
                  disabled && 'opacity-50 cursor-not-allowed'
                )}
                disabled={disabled}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {format(to, 'LLL dd, y')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={to}
                onSelect={(date) => {
                  if (date && !disabled) {
                    const normalizedDate = startOfDay(date);
                    console.log('End date selected:', normalizedDate.toISOString());
                    setDateRange({
                      from: dateRange.from,
                      to: normalizedDate,
                    });
                  }
                }}
                initialFocus
                disabled={(date) =>
                  disabled || date < dateRange.from || date > new Date()
                }
              />
              <div className="p-3 border-t border-border">
                <p className="text-sm text-muted-foreground">
                  Select end date
                </p>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  );
}
