import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Building2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { BusinessFormFields } from "./BusinessFormFields";
import { ImageGallery } from "./ImageGallery";
import { WebsiteCredentialsSection } from "./WebsiteCredentialsSection";
import { useTranslation } from "react-i18next";
import { FormBusinessProfile } from "./types";
import { useQuery } from "@tanstack/react-query";

const defaultProfile: FormBusinessProfile = {
  id: "",
  user_id: null,
  business_name: "",
  website_url: null,
  opening_date: null,
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  category: "",
  phone_number: "",
  opening_hours: "9:00-17:00",
  opening_hours_json: {
    monday: { open: "09:00", close: "17:00" },
    tuesday: { open: "09:00", close: "17:00" },
    wednesday: { open: "09:00", close: "17:00" },
    thursday: { open: "09:00", close: "17:00" },
    friday: { open: "09:00", close: "17:00" },
    saturday: { open: "09:00", close: "17:00" },
    sunday: { open: "09:00", close: "17:00" }
  },
  seo_campaign_goal: "",
  desired_keywords: [],
  desired_keywords_text: "",
  business_services: [],
  business_services_text: "",
  payment_methods: [],
  payment_methods_text: "",
  promotions: null,
  business_email: null,
  short_description: null,
  long_description: null,
  facebook_url: null,
  instagram_url: null,
  created_at: null,
  updated_at: null,
  admin_notes: null,
  logo_url: null,
  square_logo_url: null,
  primary_image_url: null,
};

const BusinessInfoForm = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<FormBusinessProfile>(defaultProfile);
  const { toast } = useToast();
  const { t } = useTranslation();

  const { data: businessProfile, isLoading } = useQuery({
    queryKey: ['businessProfile'],
    queryFn: async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw new Error('Authentication failed');
        }

        if (!session?.user?.id) {
          console.error('No authenticated user found');
          throw new Error('Authentication required');
        }

        const { data, error } = await supabase
          .from('business_profiles')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching business profile:', error);
          throw error;
        }

        console.log('Fetched business profile:', data);

        const transformedData: FormBusinessProfile = {
          ...data,
          opening_hours_json: typeof data.opening_hours_json === 'string' 
            ? JSON.parse(data.opening_hours_json)
            : data.opening_hours_json,
          desired_keywords: Array.isArray(data.desired_keywords) 
            ? data.desired_keywords 
            : (data.desired_keywords ? String(data.desired_keywords).split(',') : []),
          desired_keywords_text: Array.isArray(data.desired_keywords)
            ? data.desired_keywords.join('\n')
            : (data.desired_keywords || ''),
          business_services: Array.isArray(data.business_services)
            ? data.business_services 
            : (data.business_services ? String(data.business_services).split(',') : []),
          business_services_text: Array.isArray(data.business_services)
            ? data.business_services.join('\n')
            : (data.business_services || ''),
          payment_methods: Array.isArray(data.payment_methods) 
            ? data.payment_methods 
            : (data.payment_methods ? String(data.payment_methods).split(',') : []),
          payment_methods_text: Array.isArray(data.payment_methods)
            ? data.payment_methods.join('\n')
            : (data.payment_methods || '')
        };

        console.log('Transformed business profile:', transformedData);
        return transformedData;
      } catch (error: any) {
        console.error('Error fetching profile:', error);
        toast({
          title: "Error",
          description: error.message || "Failed to load business profile",
          variant: "destructive",
        });
        throw error;
      }
    },
    enabled: true,
  });

  useEffect(() => {
    if (businessProfile) {
      console.log('Setting profile from query data:', businessProfile);
      setProfile(businessProfile);
      setLoading(false);
    }
  }, [businessProfile]);

  const processFieldsBeforeSubmit = () => {
    const processedProfile = { ...profile };
    
    if (processedProfile.desired_keywords_text) {
      processedProfile.desired_keywords = processedProfile.desired_keywords_text
        .split(/[\n,]/)
        .map(keyword => keyword.trim())
        .filter(keyword => keyword.length > 0);
    }
    
    if (processedProfile.business_services_text) {
      processedProfile.business_services = processedProfile.business_services_text
        .split(/[\n,]/)
        .map(service => service.trim())
        .filter(service => service.length > 0);
    }
    
    if (processedProfile.payment_methods_text) {
      processedProfile.payment_methods = processedProfile.payment_methods_text
        .split(/[\n,]/)
        .map(method => method.trim())
        .filter(method => method.length > 0);
    }
    
    console.log("Processing fields before submit:", {
      desiredKeywords: processedProfile.desired_keywords,
      businessServices: processedProfile.business_services,
      paymentMethods: processedProfile.payment_methods
    });
    
    return processedProfile;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error(t('You must be logged in to update your profile'));

      const processedProfile = processFieldsBeforeSubmit();

      console.log('Submitting profile update with data:', {
        ...processedProfile,
        opening_hours_json: JSON.stringify(processedProfile.opening_hours_json),
        desired_keywords: processedProfile.desired_keywords,
        business_services: processedProfile.business_services,
        payment_methods: processedProfile.payment_methods
      });

      const { 
        desired_keywords_text, 
        business_services_text, 
        payment_methods_text, 
        ...profileToSubmit 
      } = processedProfile;

      const { error } = await supabase
        .from('business_profiles')
        .update({
          ...profileToSubmit,
          opening_hours_json: JSON.stringify(profileToSubmit.opening_hours_json),
        })
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: t('business.form.success'),
      });
    } catch (error: any) {
      console.error('Error updating profile:', error);
      toast({
        title: t('business.form.error'),
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpdate = (field: string, value: string | null) => {
    setProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  if (isLoading) return <div>{t('Loading...')}</div>;

  return (
    <div className="w-full">
      <div className="mb-8">
        <h1 className="text-2xl font-semibold text-gray-900">{t('business.title')}</h1>
        <p className="text-sm text-gray-500">{t('business.subtitle')}</p>
      </div>
      
      <Card className="p-8 bg-white shadow-sm border border-gray-100">
        <div className="flex items-center gap-3 mb-8">
          <div className="p-3 rounded-full bg-primary/10">
            <Building2 className="h-6 w-6 text-primary" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">{t('business.title')}</h2>
            <p className="text-sm text-gray-500">{t('business.subtitle')}</p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <BusinessFormFields 
            profile={profile} 
            setProfile={setProfile}
          />
          
          <ImageGallery
            businessId={profile.id}
            logoUrl={profile.logo_url}
            squareLogoUrl={profile.square_logo_url}
            primaryImageUrl={profile.primary_image_url}
            onUpdate={handleImageUpdate}
          />

          <div className="pt-4">
            <Button 
              type="submit" 
              className="w-full md:w-auto bg-primary hover:bg-primary/90"
              disabled={loading}
            >
              {loading ? t('business.form.saving') : t('business.form.saveChanges')}
            </Button>
          </div>
        </form>

        {profile.id && <WebsiteCredentialsSection businessId={profile.id} />}
      </Card>
    </div>
  );
};

export default BusinessInfoForm;
