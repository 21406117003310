
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const CancelSubscription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleCancel = async () => {
    if (!confirm("Are you sure you want to cancel your subscription? This action cannot be undone.")) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(false);
    
    try {
      // Get the current user
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      console.log("Calling cancel-subscription function with user ID:", user.id);
      
      // Cancel the subscription via Edge Function
      const response = await supabase.functions.invoke('cancel-subscription', {
        body: { 
          userId: user.id
        }
      });

      // Check if there was an error with the function call itself
      if (response.error) {
        console.error("Edge function error:", response.error);
        throw new Error(response.error.message || "Failed to cancel subscription");
      }

      // Check if there was an error in the function's response
      const data = response.data;
      if (!data || !data.success) {
        console.error("Function returned error:", data);
        throw new Error(data?.message || "Failed to cancel subscription");
      }

      // Success! Update UI
      setSuccess(true);
      toast({
        title: "Subscription Cancelled",
        description: "Your subscription has been cancelled successfully.",
      });

      // Refresh subscription data
      queryClient.invalidateQueries({ queryKey: ['subscription'] });
      queryClient.invalidateQueries({ queryKey: ['subscription-status'] });
    } catch (error: any) {
      console.error("Subscription cancellation error:", error);
      
      const errorMessage = error.message || "Failed to cancel subscription";
      setError(errorMessage);
      
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100 h-full flex flex-col">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-destructive/10">
          <AlertCircle className="h-6 w-6 text-destructive" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Cancel Subscription</h2>
          <p className="text-sm text-gray-500">Cancel your current subscription plan</p>
        </div>
      </div>

      <div className="space-y-4 flex-grow">
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        {success ? (
          <Alert variant="default" className="bg-green-50 border-green-200">
            <AlertDescription className="text-green-700">
              Your subscription has been successfully cancelled. Your access will remain active until the end of your current billing period.
            </AlertDescription>
          </Alert>
        ) : (
          <>
            <p className="text-gray-600">
              Warning: Cancelling your subscription will immediately stop all services. 
              This action cannot be undone.
            </p>
            
            <div className="mt-auto pt-4">
              <Button
                variant="destructive"
                onClick={handleCancel}
                disabled={isLoading}
                className="w-full"
              >
                {isLoading ? "Cancelling..." : "Cancel Subscription"}
              </Button>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
