
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enTranslations } from './translations/en';
import { esTranslations } from './translations/es';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          ...enTranslations.common,
          dashboard: enTranslations.dashboard,
          landing: enTranslations.landing,
          settings: enTranslations.settings,
          business: enTranslations.business,
          integrations: enTranslations.integrations,
          billing: enTranslations.billing,
          account: enTranslations.account,
          resources: enTranslations.resources,
          videos: enTranslations.videos,
        },
        googletrends: enTranslations.googletrends
      },
      es: {
        translation: {
          ...esTranslations.common,
          dashboard: esTranslations.dashboard,
          landing: esTranslations.landing,
          settings: esTranslations.settings,
          business: esTranslations.business,
          billing: esTranslations.billing,
        },
        googletrends: esTranslations.googletrends
      }
    },
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng') || 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
