
import { MetricsData, UberallMetricsResponse } from './types';
import { supabase } from '@/integrations/supabase/client';

const sumMetricCounts = (metricData: { period: string; count: number }[] | undefined): number => {
  if (!metricData) {
    console.log('No metric data available');
    return 0;
  }

  // Get the last 30 days of data
  const sortedData = [...metricData].sort((a, b) => 
    new Date(b.period).getTime() - new Date(a.period).getTime()
  );
  const last30Days = sortedData.slice(0, 30);

  const total = last30Days.reduce((sum, point) => {
    console.log(`Adding count for ${point.period}: ${point.count}`);
    return sum + (point.count || 0);
  }, 0);

  console.log(`Total sum for metric: ${total}`);
  return total;
};

async function fetchSingleMetric(
  locationId: string,
  metric: string,
  dateRange: { startDate: string; endDate: string }
): Promise<number> {
  console.log(`Fetching metric ${metric}`);
  
  const { data: functionData, error: functionError } = await supabase.functions.invoke('uberall', {
    body: {
      action: 'getMetric',
      locationId,
      metric,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    }
  });

  if (functionError) {
    console.error(`Error fetching metric ${metric}:`, functionError);
    return 0;
  }

  if (!functionData) {
    console.log(`No data returned for metric ${metric}`);
    return 0;
  }

  console.log(`Response for ${metric}:`, functionData);

  const data = functionData as UberallMetricsResponse;
  const metricData = data.response?.metrics?.[0]?.data;
  
  return sumMetricCounts(metricData);
}

export async function fetchLocationMetrics(
  locationId: string | null | undefined,
  dateRange: { startDate: string; endDate: string }
): Promise<MetricsData> {
  if (!locationId) {
    throw new Error('Location ID is required');
  }

  const [
    mobileMapImpressions,
    mobileSearchImpressions,
    desktopMapImpressions,
    desktopSearchImpressions,
    callClicks,
    websiteClicks,
    directionRequests
  ] = await Promise.all([
    fetchSingleMetric(locationId, 'BUSINESS_IMPRESSIONS_MOBILE_MAPS', dateRange),
    fetchSingleMetric(locationId, 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH', dateRange),
    fetchSingleMetric(locationId, 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS', dateRange),
    fetchSingleMetric(locationId, 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH', dateRange),
    fetchSingleMetric(locationId, 'CALL_CLICKS', dateRange),
    fetchSingleMetric(locationId, 'WEBSITE_CLICKS', dateRange),
    fetchSingleMetric(locationId, 'BUSINESS_DIRECTION_REQUESTS', dateRange)
  ]);

  const metricsResult = {
    mobileImpressions: mobileMapImpressions + mobileSearchImpressions,
    desktopImpressions: desktopMapImpressions + desktopSearchImpressions,
    callClicks,
    websiteClicks,
    directionRequests,
  };

  console.log('Final metrics result:', metricsResult);
  return metricsResult;
}

// Generate daily dummy data for chart visualization
export async function fetchMetricsData(locationId: string, dateRange: { from: Date; to: Date }): Promise<{ 
  summary: MetricsData;
  dailyData: Array<{
    date: string;
    mobileImpressions: number;
    desktopImpressions: number;
  }>;
}> {
  // Convert to expected format for the API
  const apiDateRange = {
    startDate: dateRange.from.toISOString().split('T')[0],
    endDate: dateRange.to.toISOString().split('T')[0]
  };
  
  // Fetch the real metrics from the API
  const summary = await fetchLocationMetrics(locationId, apiDateRange);
  
  // Generate daily data for the chart (simplified approach for now)
  const days = Math.ceil((dateRange.to.getTime() - dateRange.from.getTime()) / (1000 * 60 * 60 * 24)) + 1;
  const dailyData = [];
  
  for (let i = 0; i < days; i++) {
    const date = new Date(dateRange.from);
    date.setDate(date.getDate() + i);
    
    // Create a distribution of the total values across days
    // This is simplified - in a real app, you would fetch actual daily data
    const mobileFactor = 0.5 + (Math.random() * 0.5);
    const desktopFactor = 0.5 + (Math.random() * 0.5);
    
    dailyData.push({
      date: date.toISOString().split('T')[0],
      mobileImpressions: Math.round((summary.mobileImpressions / days) * mobileFactor),
      desktopImpressions: Math.round((summary.desktopImpressions / days) * desktopFactor)
    });
  }
  
  return {
    summary,
    dailyData
  };
}
