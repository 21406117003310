
import { common } from "./common";

export const landing = {
  nav: {
    caseStudies: "Case Studies",
    pricing: "Pricing",
    freeAudit: "Free Audit",
    login: "Login",
    signup: "Sign Up",
    contact: "Contact",
  },
  hero: {
    title: "Launchpad: Where Your Business Growth Takes Off.",
    subtitle: "📍 Your customers are searching—Launch your business to the top and get found.",
    getStarted: "Get Started",
    learnMore: "Learn More",
  },
  logoSlider: {
    title: "Trusted By Businesses Across The Nation",
    subtitle: "We've helped companies in 500+ locations improve their local visibility",
  },
  doneForYou: {
    title: "We Launch Your Business—So You Can Focus on Running It",
    description: "Running a business is hard. Getting found online shouldn't be.\n\nWoobound Launchpad is your done-for-you growth engine, putting your business right where customers are searching—Google Maps, local search, and beyond.",
    tagline: "👉 No DIY. No guesswork. Just real results.",
    cta: "Boost My Business Now"
  },
  features: {
    title: "Show Up in Local Searches & Get More Customers",
    subtitle: "🔎 Your business deserves to be the first thing customers see. We make it happen.",
    whatWeDo: "What We Do for You:",
    items: {
      localSeo: {
        title: "Google Maps & Local SEO Optimization",
        description: "Dominate local search results and attract nearby customers",
      },
      analytics: {
        title: "Performance Analytics",
        description: "Track your progress with clear insights and real results",
      },
      dashboard: {
        title: "Business Dashboard",
        description: "Stay informed with a central hub for monitoring your SEO campaign",
      },
      expertise: {
        title: "Dedicated SEO Experts",
        description: "We manage everything so you can focus on running your business",
      },
      roi: {
        title: "ROI-Focused Strategy",
        description: "More visibility. More leads. More revenue",
      },
    },
    cta: "Start Your SEO Journey",
  },
  testimonials: {
    title: "🌟 Real Stories. Real Results.",
    subtitle: "Our clients trust Woobound to deliver tangible results that drive growth.",
    cta: "📣 Want to be our next success story? Let's make it happen.",
    getStarted: "Get Started Today",
    items: {
      restaurant: {
        content: "The Maps SEO package helped us increase our local visibility significantly. Our restaurant bookings have increased by 40%!",
        name: "Sarah Johnson",
        role: "Restaurant Owner",
      },
      retail: {
        content: "Comprehensive SEO solution that delivered real results. Our online presence has never been stronger.",
        name: "Mike Thompson",
        role: "Retail Store Manager",
      },
      service: {
        content: "Great service and amazing results. Our Google Maps rankings improved within weeks!",
        name: "Lisa Chen",
        role: "Service Business Owner",
      },
    },
  },
  pricing: {
    title: "💰 Simple, Transparent Pricing – Choose Your Growth Plan",
    subtitle: "Choose the plan that's right for your business",
    mapsSeo: {
      title: "Maps SEO",
      price: "$498",
      period: "/month",
      description: "Perfect for businesses wanting to dominate local search results",
      features: [
        "Google Business Profile Optimization",
        "Local SEO Strategy",
        "Review Management",
        "Analytics Dashboard",
        "Monthly Performance Reports"
      ],
      includes: "Includes:",
      whyItMatters: "Why It Matters:",
      bestFor: "Best for: Businesses that rely on foot traffic, phone calls, or local customers to grow.",
      getStarted: "Get Started"
    },
    mapsWebsiteSeo: {
      title: "Maps + Website SEO",
      price: "$998",
      period: "/month",
      description: "Complete solution for businesses wanting to dominate both local and organic search results",
      features: [
        "Everything in Maps SEO",
        "Website SEO Optimization",
        "Content Strategy",
        "Backlink Building",
        "Technical SEO",
        "Competitor Analysis"
      ],
      mostPopular: "MOST POPULAR",
      includes: "Includes:",
      whyItMatters: "Why It Matters:",
      bestFor: "Best for: Businesses wanting to rank higher, generate more leads, and convert searches to real customers.",
      getStarted: "Get Started"
    },
    customSeo: {
      title: "Custom SEO",
      pricesVary: "Prices vary",
      customQuotes: "Custom quotes based on your needs",
      description: "Tailor-made SEO solutions for businesses in competitive industries or expanding to multiple locations.",
      includes: "Includes:",
      whyItMatters: "Why It Matters:",
      bestFor: "Best for: Businesses looking to scale, compete aggressively, or expand across multiple locations.",
      contactUs: "Contact Us"
    }
  },
  whyChoose: {
    title: "🎯 Why Businesses Choose Woobound Launchpad",
    subtitle: "We make business growth easy—and fast.",
    benefits: [
      {
        title: "We Do It for You",
        description: "Unlike DIY tools, our team handles everything from A to Z."
      },
      {
        title: "Proven Strategies",
        description: "12+ years of marketing success for local businesses."
      },
      {
        title: "Dedicated Support",
        description: "Expert guidance from marketing specialists."
      },
      {
        title: "No Long-Term Contracts",
        description: "Stay with us because you love the results."
      }
    ],
    tagline: "🚀 Ready to take off?",
    cta: "Let's Get Started"
  },
  contact: {
    title: "Get in Touch",
    subtitle: "Have questions about our services? Our team is here to help you.",
    headerTitle: "📩 Have Questions? Let's Talk.",
    headerSubtitle: "Need more info? Our team is ready to help. Get in touch today!",
    contactInfo: {
      email: "📧 Email: sales@woobound.com",
      phone: "📞 Call Us: (469) 607-1400",
      chat: "Chat: Click the chat icon to start"
    },
    nameLabel: "Name",
    namePlaceholder: "Your name",
    emailLabel: "Email",
    emailPlaceholder: "your.email@example.com",
    phoneLabel: "Phone Number",
    phonePlaceholder: "(123) 456-7890",
    businessNameLabel: "Business Name",
    businessNamePlaceholder: "Your business name",
    messageLabel: "Message",
    messagePlaceholder: "How can we help you?",
    customerStatusLabel: "Are you an existing Woobound customer?",
    customerStatusYes: "Yes",
    customerStatusNo: "No",
    submit: "Send Message",
    submitting: "Sending...",
    successTitle: "Message Sent",
    successMessage: "We'll get back to you as soon as possible.",
    errorTitle: "Error Sending Message",
    errorMessage: "There was a problem sending your message. Please try again later."
  },
  footer: {
    company: {
      title: "Company",
      about: "About Us",
      contact: "Contact",
      careers: "Careers",
    },
    resources: {
      title: "Resources",
      blog: "Blog",
      caseStudies: "Case Studies",
      faqs: "FAQs",
    },
    legal: {
      title: "Legal",
      privacy: "Privacy Policy",
      terms: "Terms of Service",
      cookies: "Cookie Policy",
    },
    contact: {
      title: "Get in Touch",
      description: "Need help with your SEO strategy? Our experts are here to help.",
      cta: "Contact Support",
    },
    copyright: "© 2025 Woobound Launchpad. All rights reserved.",
  },
};
