import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export interface Package {
  id: string;
  type: 'maps_seo' | 'maps_website_seo';
  name: string;
  description: string | null;
  base_price: number;
  features: string[];
  image_url: string | null;
}

export const usePackageData = (packageType?: string) => {
  const { toast } = useToast();

  return useQuery({
    queryKey: ['package', packageType],
    queryFn: async () => {
      if (!packageType) {
        throw new Error('Package type is required');
      }

      console.log('Fetching package data for type:', packageType);
      
      // First get all packages to debug
      const { data: allPackages, error: allPackagesError, count } = await supabase
        .from('packages')
        .select('*', { count: 'exact' });

      console.log('All packages:', allPackages);
      console.log('Total packages count:', count);

      if (allPackagesError) {
        console.error('Error fetching all packages:', allPackagesError);
        throw allPackagesError;
      }

      // Then get the specific package
      const { data: packageData, error } = await supabase
        .from('packages')
        .select('*')
        .eq('type', packageType)
        .maybeSingle();

      if (error) {
        console.error('Error fetching package:', error);
        toast({
          title: "Error",
          description: "Failed to load package information",
          variant: "destructive",
        });
        throw error;
      }

      if (!packageData) {
        console.error('No package found for type:', packageType);
        console.log('Available package types:', allPackages?.map(p => p.type));
        toast({
          title: "Error",
          description: `No package found for type: ${packageType}`,
          variant: "destructive",
        });
        throw new Error(`No package found for type: ${packageType}`);
      }

      return packageData as Package;
    },
    enabled: !!packageType,
    retry: 1,
  });
};