
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/integrations/supabase/client';

export const CreateTestPartnerCode = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [code, setCode] = useState('TEST90');
  const [discountPercentage, setDiscountPercentage] = useState(90);
  const [usageLimit, setUsageLimit] = useState(10);
  const { toast } = useToast();

  const handleCreateTestCode = async () => {
    if (isCreating) return;
    setIsCreating(true);

    try {
      // Get the current user's session
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: 'Authentication Error',
          description: 'You must be logged in to create a test partner code.',
          variant: 'destructive'
        });
        return;
      }

      // Call the function to create the test partner code
      const { data, error } = await supabase.functions.invoke('create-test-partner-code', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${session.access_token}`
        },
        body: {
          code,
          discountPercentage,
          usageLimit
        }
      });

      if (error) {
        console.error('Error creating test partner code:', error);
        toast({
          title: 'Error',
          description: 'Failed to create test partner code. Please try again.',
          variant: 'destructive'
        });
        return;
      }

      if (data.error) {
        console.error('API error:', data.error);
        toast({
          title: 'Error',
          description: data.message || 'Failed to create test partner code.',
          variant: 'destructive'
        });
        return;
      }

      toast({
        title: 'Success',
        description: data.message || 'Test partner code created successfully',
        variant: 'default'
      });

      // Reset the form after success
      setCode('TEST90');
      setDiscountPercentage(90);
      setUsageLimit(10);
    } catch (error) {
      console.error('Unexpected error:', error);
      toast({
        title: 'Error',
        description: 'An unexpected error occurred. Please try again.',
        variant: 'destructive'
      });
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Create Test Partner Code</CardTitle>
        <CardDescription>
          Create a special partner code for testing Authorize.net integration with minimal charges
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="code">Partner Code</Label>
          <Input
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            placeholder="TEST90"
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="discount">Discount Percentage</Label>
          <Input
            id="discount"
            type="number"
            min="1"
            max="99"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(parseInt(e.target.value))}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="usageLimit">Usage Limit</Label>
          <Input
            id="usageLimit"
            type="number"
            min="1"
            max="100"
            value={usageLimit}
            onChange={(e) => setUsageLimit(parseInt(e.target.value))}
          />
        </div>
      </CardContent>
      <CardFooter>
        <Button 
          onClick={handleCreateTestCode} 
          disabled={isCreating}
          className="w-full"
        >
          {isCreating ? 'Creating...' : 'Create Test Partner Code'}
        </Button>
      </CardFooter>
    </Card>
  );
};
