import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, Play, CheckCircle } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const Course = () => {
  const { courseId } = useParams();
  const { toast } = useToast();

  const { data: course, isLoading: courseLoading } = useQuery({
    queryKey: ['academy_course', courseId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('academy_courses')
        .select(`
          *,
          academy_modules (
            id,
            title,
            description,
            order_number,
            academy_lessons (
              id,
              title,
              description,
              duration_minutes,
              order_number
            )
          )
        `)
        .eq('id', courseId)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: enrollment } = useQuery({
    queryKey: ['academy_enrollment', courseId],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from('academy_enrollments')
        .select('*')
        .eq('course_id', courseId)
        .eq('user_id', session.user.id)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    },
  });

  const { data: lessonProgress } = useQuery({
    queryKey: ['academy_lesson_progress', courseId],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from('academy_lesson_progress')
        .select('*')
        .eq('user_id', session.user.id);

      if (error) throw error;
      return data;
    },
    enabled: !!enrollment,
  });

  const handleEnroll = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return;

      const { error } = await supabase
        .from('academy_enrollments')
        .insert({
          course_id: courseId,
          user_id: session.user.id,
        });

      if (error) throw error;

      toast({
        title: "Enrolled Successfully",
        description: "You have been enrolled in the course.",
      });
    } catch (error) {
      console.error('Error enrolling:', error);
      toast({
        title: "Error",
        description: "Failed to enroll in the course. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (courseLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 overflow-x-auto md:pl-[12rem]">
            <div className="w-full px-8 py-6">
              <div className="max-w-5xl mx-auto">
                <div className="space-y-8">
                  <div className="animate-pulse">
                    <div className="h-8 w-64 bg-gray-200 rounded mb-4"></div>
                    <div className="h-4 w-96 bg-gray-200 rounded mb-8"></div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-5xl mx-auto">
              <div className="space-y-8">
                <Button
                  variant="ghost"
                  className="mb-4"
                  asChild
                >
                  <Link to="/academy" className="flex items-center gap-2">
                    <ChevronLeft className="h-4 w-4" />
                    Back to Academy
                  </Link>
                </Button>

                <div className="grid gap-8 md:grid-cols-3">
                  <div className="md:col-span-2">
                    <h1 className="text-2xl font-semibold text-gray-900 mb-4">{course?.title}</h1>
                    <p className="text-gray-600 mb-6">{course?.description}</p>

                    {!enrollment && (
                      <Button onClick={handleEnroll} className="w-full md:w-auto">
                        Enroll Now
                      </Button>
                    )}
                  </div>

                  <Card className="p-6">
                    <h2 className="font-semibold text-lg mb-4">Course Content</h2>
                    <div className="space-y-6">
                      {course?.academy_modules
                        ?.sort((a, b) => a.order_number - b.order_number)
                        ?.map((module) => (
                          <div key={module.id}>
                            <h3 className="font-medium text-gray-900 mb-2">{module.title}</h3>
                            <div className="space-y-2">
                              {module.academy_lessons
                                ?.sort((a, b) => a.order_number - b.order_number)
                                ?.map((lesson) => {
                                  const progress = lessonProgress?.find(p => p.lesson_id === lesson.id);
                                  return (
                                    <Link
                                      key={lesson.id}
                                      to={enrollment ? `/academy/courses/${courseId}/lessons/${lesson.id}` : '#'}
                                      onClick={(e) => {
                                        if (!enrollment) {
                                          e.preventDefault();
                                          toast({
                                            title: "Enrollment Required",
                                            description: "Please enroll in the course to access lessons.",
                                          });
                                        }
                                      }}
                                      className={`flex items-center justify-between p-2 rounded-lg ${
                                        enrollment 
                                          ? 'hover:bg-gray-100 cursor-pointer' 
                                          : 'opacity-50 cursor-not-allowed'
                                      }`}
                                    >
                                      <div className="flex items-center gap-2">
                                        {progress?.completed ? (
                                          <CheckCircle className="h-4 w-4 text-green-500" />
                                        ) : (
                                          <Play className="h-4 w-4 text-gray-400" />
                                        )}
                                        <span className="text-sm">{lesson.title}</span>
                                      </div>
                                      {lesson.duration_minutes && (
                                        <span className="text-xs text-gray-500">
                                          {lesson.duration_minutes} min
                                        </span>
                                      )}
                                    </Link>
                                  );
                                })}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Course;
