
export const landing = {
  nav: {
    caseStudies: "Casos de Éxito",
    pricing: "Precios",
    freeAudit: "Auditoría Gratuita",
    login: "Iniciar Sesión",
    signup: "Registrarse",
    contact: "Contacto"
  },
  hero: {
    title: "Launchpad: Donde el Crecimiento de tu Negocio Despega.",
    subtitle: "📍 Sé Encontrado Donde Importa",
    cta: "Get Started",
    getStarted: "Comenzar",
    learnMore: "Saber Más",
  },
  logoSlider: {
    title: "Empresas Que Confían En Nosotros",
    subtitle: "Hemos ayudado a empresas en más de 500 ubicaciones a mejorar su visibilidad local",
  },
  doneForYou: {
    title: "Lo Hacemos por Ti",
    description: "Nuestros servicios de marketing local te ponen frente a clientes listos para comprar. Ya sea que dirijas un restaurante, consultorio dental, taller automotriz o tienda minorista, nos aseguramos de que aparezcas donde más importa: en Google Maps y búsquedas.",
    tagline: "👉 Sin hazlo tú mismo. Sin conjeturas. Solo resultados.",
    cta: "Impulsa Mi Negocio Ahora"
  },
  features: {
    title: "Aparece en Búsquedas Locales y Consigue Más Clientes",
    subtitle: "🔎 Tu negocio merece ser lo primero que los clientes vean. Nosotros lo hacemos realidad.",
    whatWeDo: "Lo Que Hacemos Por Ti:",
    items: {
      localSeo: {
        title: "Optimización de Google Maps y SEO Local",
        description: "Domina los resultados de búsqueda locales y atrae clientes cercanos",
      },
      analytics: {
        title: "Análisis de Rendimiento",
        description: "Sigue tu progreso con insights claros y resultados reales",
      },
      dashboard: {
        title: "Panel de Control",
        description: "Mantente informado con un centro para monitorear tu campaña SEO",
      },
      expertise: {
        title: "Expertos SEO Dedicados",
        description: "Nosotros gestionamos todo para que puedas concentrarte en tu negocio",
      },
      roi: {
        title: "Estrategia Enfocada en ROI",
        description: "Más visibilidad. Más leads. Más ingresos",
      },
    },
    cta: "Comienza Tu Viaje SEO",
  },
  testimonials: {
    title: "🌟 Historias Reales. Resultados Reales.",
    subtitle: "Nuestros clientes confían en Woobound para entregar resultados tangibles que impulsan el crecimiento.",
    cta: "📣 ¿Quieres ser nuestra próxima historia de éxito? Hagámoslo realidad.",
    getStarted: "Comienza Hoy Mismo",
    items: {
      restaurant: {
        content: "El paquete Maps SEO nos ayudó a aumentar significativamente nuestra visibilidad local. ¡Nuestras reservas de restaurante aumentaron un 40%!",
        name: "Sarah Johnson",
        role: "Propietaria de Restaurante",
      },
      retail: {
        content: "Solución SEO integral que entregó resultados reales. Nuestra presencia online nunca ha sido más fuerte.",
        name: "Mike Thompson",
        role: "Gerente de Tienda Minorista",
      },
      service: {
        content: "Gran servicio y resultados increíbles. ¡Nuestro ranking en Google Maps mejoró en semanas!",
        name: "Lisa Chen",
        role: "Propietaria de Negocio de Servicios",
      },
    },
  },
  pricing: {
    title: "💰 Simple, Transparent Pricing – Choose Your Growth Plan",
    mapsSeo: {
      title: "SEO para Maps",
      description: "Sé descubierto por más clientes locales y aparece más arriba en Google Maps.",
      includes: "Incluye:",
      whyItMatters: "Por Qué Es Importante:",
      bestFor: "Mejor para: Negocios que dependen del tráfico a pie, llamadas telefónicas o clientes locales para crecer.",
      getStarted: "Comenzar"
    },
    mapsWebsiteSeo: {
      title: "Maps + SEO de Sitio Web",
      description: "Sé encontrado en Google Maps Y convierte visitantes del sitio web en clientes que pagan.",
      mostPopular: "MÁS POPULAR",
      includes: "Incluye:",
      whyItMatters: "Por Qué Es Importante:",
      bestFor: "Mejor para: Negocios que quieren clasificar más alto, generar más leads y convertir búsquedas en clientes reales.",
      getStarted: "Comenzar"
    },
    customSeo: {
      title: "SEO Personalizado",
      description: "Soluciones SEO a medida para negocios en industrias competitivas o que se expanden a múltiples ubicaciones.",
      pricesVary: "Precios variables",
      customQuotes: "Presupuestos personalizados según tus necesidades",
      includes: "Incluye:",
      whyItMatters: "Por Qué Es Importante:",
      bestFor: "Mejor para: Negocios que buscan escalar, competir agresivamente o expandirse a través de múltiples ubicaciones.",
      contactUs: "Contáctanos"
    }
  },
  whyChoose: {
    title: "🎯 Why Businesses Choose Woobound Launchpad",
    subtitle: "We make business growth easy—and fast.",
    benefits: [
      {
        title: "Lo Hacemos por Ti",
        description: "A diferencia de las herramientas DIY, nuestro equipo maneja todo de A a Z."
      },
      {
        title: "Estrategias Probadas",
        description: "12+ años de éxito en marketing para negocios locales."
      },
      {
        title: "Soporte Dedicado",
        description: "Orientación experta de especialistas en marketing."
      },
      {
        title: "Sin Contratos a Largo Plazo",
        description: "Quédate con nosotros porque te encantan los resultados."
      }
    ],
    tagline: "🚀 Ready to take off?",
    cta: "Empecemos"
  },
  howItWorks: {
    title: "¿Por Qué Elegir Woobound?",
    subtitle: "Comienza con nuestro proceso simple de tres pasos",
    steps: {
      step1: {
        title: "Selecciona tu Paquete",
        description: "Elige el paquete SEO que mejor se adapte a las necesidades y objetivos de tu negocio",
      },
      step2: {
        title: "Incorporación Rápida",
        description: "Completa nuestro proceso de incorporación optimizado con guía experta",
      },
      step3: {
        title: "Observa Crecer tu Negocio",
        description: "Monitorea tu progreso mientras tu presencia online y base de clientes se expanden",
      },
    },
  },
  contact: {
    title: "Contáctanos",
    subtitle: "¿Tienes preguntas sobre nuestros servicios? Nuestro equipo está aquí para ayudarte.",
    headerTitle: "📩 ¿Tienes Preguntas? Hablemos.",
    headerSubtitle: "¿Necesitas más información? Nuestro equipo está listo para ayudarte. ¡Contáctanos hoy!",
    contactInfo: {
      email: "📧 Email: sales@woobound.com",
      phone: "📞 Llámanos: (469) 607-1400",
      chat: "Chat: Haz clic en el icono de chat para comenzar"
    },
    nameLabel: "Nombre",
    namePlaceholder: "Tu nombre",
    emailLabel: "Correo electrónico",
    emailPlaceholder: "tu.correo@ejemplo.com",
    phoneLabel: "Número de teléfono",
    phonePlaceholder: "(123) 456-7890",
    businessNameLabel: "Nombre de la empresa",
    businessNamePlaceholder: "Nombre de tu empresa",
    messageLabel: "Mensaje",
    messagePlaceholder: "¿Cómo podemos ayudarte?",
    customerStatusLabel: "¿Eres cliente existente de Woobound?",
    customerStatusYes: "Sí",
    customerStatusNo: "No",
    submit: "Enviar mensaje",
    submitting: "Enviando...",
    successTitle: "Mensaje enviado",
    successMessage: "Nos pondremos en contacto contigo lo antes posible.",
    errorTitle: "Error al enviar el mensaje",
    errorMessage: "Hubo un problema al enviar tu mensaje. Inténtalo de nuevo más tarde."
  },
  footer: {
    company: {
      title: "Empresa",
      about: "Sobre Nosotros",
      contact: "Contacto",
      careers: "Carreras",
    },
    resources: {
      title: "Recursos",
      blog: "Blog",
      caseStudies: "Casos de Éxito",
      faqs: "Preguntas Frecuentes",
    },
    legal: {
      title: "Legal",
      privacy: "Política de Privacidad",
      terms: "Términos de Servicio",
      cookies: "Política de Cookies",
    },
    contact: {
      title: "Contacta con Nosotros",
      description: "¿Necesitas ayuda con tu estrategia SEO? Nuestros expertos están aquí para ayudarte.",
      cta: "Contactar Soporte",
    },
    copyright: "© 2025 Woobound Launchpad. Todos los derechos reservados.",
  },
};
