
import { useState, useEffect } from 'react';
import { BusinessInfoFields } from './combined-info/BusinessInfoFields';
import { PersonalInfoFields } from './combined-info/PersonalInfoFields';
import { CombinedFormData, CombinedInfoStepProps } from './types';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/integrations/supabase/client';
import { useDebounce } from '@/hooks/useDebounce';

export const CombinedInfoStep = ({ onComplete, formData: initialData }: CombinedInfoStepProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const { toast } = useToast();
  const [formData, setFormData] = useState<CombinedFormData>({
    email: initialData?.email || '',
    firstName: initialData?.firstName || '',
    lastName: initialData?.lastName || '',
    password: initialData?.password || '',
    phoneNumber: initialData?.phoneNumber || '',
    businessPhoneNumber: initialData?.businessPhoneNumber || '',
    businessName: initialData?.businessName || '',
    businessCategory: initialData?.businessCategory || '',
    websiteUrl: initialData?.websiteUrl || '',
    streetAddress: initialData?.streetAddress || '',
    city: initialData?.city || '',
    state: initialData?.state || '',
    zipCode: initialData?.zipCode || '',
    packageType: initialData?.packageType || 'maps_seo',
  });

  const debouncedEmail = useDebounce(formData.email, 500);

  // Check email validity whenever debounced email changes
  useEffect(() => {
    const checkEmailValidity = async () => {
      if (!debouncedEmail || debouncedEmail.length < 5 || !debouncedEmail.includes('@')) {
        setEmailChecked(false);
        setEmailExists(false);
        return;
      }

      setIsCheckingEmail(true);
      try {
        const { data, error } = await supabase.functions.invoke('check-email-exists', {
          body: { email: debouncedEmail }
        });

        if (error) {
          console.error('Error checking email:', error);
          setEmailExists(false);
        } else {
          setEmailExists(data.exists);
        }
      } catch (error) {
        console.error('Error checking email:', error);
        setEmailExists(false);
      } finally {
        setIsCheckingEmail(false);
        setEmailChecked(true);
      }
    };

    if (debouncedEmail) {
      checkEmailValidity();
    }
  }, [debouncedEmail]);

  const handleFieldChange = (field: keyof CombinedFormData, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      await onComplete(formData);
    } catch (error) {
      console.error('Form submission error:', error);
      toast({
        title: "Error",
        description: "An error occurred while processing your information. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Determine if form can be submitted
  const canSubmit = !isSubmitting && !emailExists && emailChecked && formData.email.length > 0;

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <PersonalInfoFields
        formData={formData}
        isSubmitting={isSubmitting}
        onChange={handleFieldChange}
        emailValidation={{
          isCheckingEmail,
          emailExists,
          emailChecked
        }}
      />
      <BusinessInfoFields
        formData={formData}
        isSubmitting={isSubmitting}
        onChange={handleFieldChange}
      />
      <Button 
        type="submit" 
        className="w-full"
        disabled={!canSubmit}
      >
        {isSubmitting ? 'Processing...' : 'Next'}
      </Button>
    </form>
  );
};
