import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Check, Globe, BarChart3, LayoutDashboard, Users, TrendingUp } from "lucide-react";
import { useTranslation } from "react-i18next";

const featureIcons = {
  localSeo: Globe,
  analytics: BarChart3,
  dashboard: LayoutDashboard,
  expertise: Users,
  roi: TrendingUp,
};

export const Features = () => {
  const { t } = useTranslation();

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    pricingSection?.scrollIntoView({ behavior: 'smooth' });
  };

  const features = [
    {
      key: 'localSeo',
      icon: featureIcons.localSeo,
    },
    {
      key: 'analytics',
      icon: featureIcons.analytics,
    },
    {
      key: 'dashboard',
      icon: featureIcons.dashboard,
    },
    {
      key: 'expertise',
      icon: featureIcons.expertise,
    },
    {
      key: 'roi',
      icon: featureIcons.roi,
    },
  ];

  return (
    <section className="py-32 px-4 bg-white">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl font-bold mb-6 text-[#221F26]">{t('landing.features.title')}</h2>
          <p className="text-xl text-[#403E43]/80 leading-relaxed">
            {t('landing.features.subtitle')}
          </p>
        </div>

        <div className="max-w-4xl mx-auto mb-16">
          <h3 className="text-2xl font-semibold mb-8 text-[#221F26] text-center">
            {t('landing.features.whatWeDo')}
          </h3>
          
          <div className="space-y-5">
            {features.map((feature) => (
              <div 
                key={feature.key} 
                className="flex items-start p-4 rounded-lg bg-gradient-to-r from-white to-[#F6F6F7] border border-[#F1F0FB] hover:shadow-md transition-shadow"
              >
                <div className="mr-4 mt-1">
                  <Check className="w-6 h-6 text-[#e0933c]" />
                </div>
                <div>
                  <h4 className="text-lg font-medium text-[#221F26]">
                    {t(`landing.features.items.${feature.key}.title`)}
                  </h4>
                  <p className="text-[#403E43]/80 mt-1">
                    {t(`landing.features.items.${feature.key}.description`)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <Button 
            className="bg-primary hover:bg-primary/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
            onClick={scrollToPricing}
          >
            {t('landing.features.cta')}
          </Button>
        </div>
      </div>
    </section>
  );
};
