
import { useCallback } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { useBusinessSearch } from "./hooks/useBusinessSearch";
import { BusinessSearchBar } from "./business-table/BusinessSearchBar";
import { BusinessList } from "./business-table/BusinessList";
import { BusinessFilters } from "./business-table/BusinessFilters";
import { BusinessStats } from "./business-table/BusinessStats";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export const BusinessTable = () => {
  const { toast } = useToast();
  const {
    businesses,
    isLoading,
    error,
    searchQuery,
    setSearchQuery,
    searchField,
    setSearchField,
    filterOptions,
    setFilterOptions,
    handleSearch,
    totalResults,
    completionStats,
    refetch
  } = useBusinessSearch();

  const handleSearchSubmit = useCallback(() => {
    if (!searchQuery.trim()) {
      toast({
        title: "Search query required",
        description: "Please enter a search term",
        variant: "destructive",
      });
      return;
    }
    handleSearch();
  }, [searchQuery, handleSearch, toast]);

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          There was an error loading the business profiles. Please try again later.
          {error instanceof Error ? `: ${error.message}` : ''}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="shadow-sm">
      <CardHeader className="pb-3">
        <CardTitle>Business Profiles</CardTitle>
        <CardDescription>
          Search and manage business profiles
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <BusinessSearchBar
            searchQuery={searchQuery}
            searchField={searchField}
            onSearchQueryChange={setSearchQuery}
            onSearchFieldChange={setSearchField}
            onSearch={handleSearchSubmit}
            isLoading={isLoading}
          />

          <BusinessFilters
            filterOptions={filterOptions}
            onFilterChange={setFilterOptions}
          />

          <BusinessStats
            totalResults={totalResults}
            completionStats={completionStats}
          />

          <BusinessList
            businesses={businesses}
            isLoading={isLoading}
            onUpdate={refetch}
          />
        </div>
      </CardContent>
    </Card>
  );
};
