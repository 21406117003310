
import { BarChart2 } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

export const GoogleMetricsComingSoon = () => {
  return (
    <div className="space-y-6">
      <Card className="bg-background border-2 border-tertiary/10">
        <CardContent className="p-8 text-center space-y-6">
          <div className="animate-pulse w-24 h-24 mx-auto text-tertiary/50">
            <BarChart2 className="w-full h-full" />
          </div>
          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-secondary">Analytics coming soon...</h2>
            <p className="text-muted-foreground">
              We're working on connecting your Google Profile to Analytics. Please check back soon.
            </p>
          </div>
        </CardContent>
      </Card>

      <div className="grid gap-4 md:grid-cols-4">
        {[1, 2, 3, 4].map((i) => (
          <Card key={i} className="p-6">
            <div className="space-y-4">
              <Skeleton className="h-4 w-[100px]" />
              <Skeleton className="h-8 w-[120px]" />
            </div>
          </Card>
        ))}
      </div>

      <Card>
        <div className="p-6">
          <Skeleton className="h-4 w-[150px] mb-4" />
          <Skeleton className="h-[200px] w-full" />
        </div>
      </Card>
    </div>
  );
};
