
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { FormBusinessProfile } from "@/components/business/types";

interface BasicInfoFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const BasicInfoFields = ({ profile, setProfile }: BasicInfoFieldsProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Basic Information</h3>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="businessName">Business Name</Label>
          <Input
            id="businessName"
            value={profile.business_name}
            onChange={(e) => setProfile({ ...profile, business_name: e.target.value })}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="businessEmail">Business Email</Label>
          <Input
            id="businessEmail"
            type="email"
            value={profile.business_email || ''}
            onChange={(e) => setProfile({ ...profile, business_email: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="websiteUrl">Website URL</Label>
          <Input
            id="websiteUrl"
            value={profile.website_url || ''}
            onChange={(e) => setProfile({ ...profile, website_url: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Input
            id="phoneNumber"
            value={profile.phone_number}
            onChange={(e) => setProfile({ ...profile, phone_number: e.target.value })}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="category">Category</Label>
          <Input
            id="category"
            value={profile.category}
            onChange={(e) => setProfile({ ...profile, category: e.target.value })}
            required
          />
        </div>
      </div>
    </div>
  );
};
