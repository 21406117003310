
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { AlertCircle, CheckCircle2, HelpCircle, Info } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { calculateProfileCompletion } from "@/utils/businessProfile";
import { BusinessProfile } from "./types";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface ProfileCompletionCardProps {
  profile: BusinessProfile;
  className?: string;
}

export function ProfileCompletionCard({ profile, className }: ProfileCompletionCardProps) {
  const navigate = useNavigate();
  const status = calculateProfileCompletion(profile);

  return (
    <Card className={className}>
      <CardHeader className="pb-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <CardTitle className="text-sm font-medium">Profile Completion</CardTitle>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="h-4 w-4 text-muted-foreground cursor-help" />
                </TooltipTrigger>
                <TooltipContent className="max-w-xs">
                  Your business profile information is used to optimize your online presence.
                  The more complete your profile, the better results you'll see.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          {status.percentage === 100 && (
            <CheckCircle2 className="h-5 w-5 text-green-500" />
          )}
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-bold">{status.percentage}%</span>
            {status.percentage < 100 && (
              <AlertCircle className="h-5 w-5 text-amber-500" />
            )}
          </div>
          
          <Progress value={status.percentage} className="h-2" />
          
          {status.percentage === 100 ? (
            <div className="p-3 bg-green-50 border border-green-100 rounded-md">
              <p className="text-sm text-green-700 flex items-start gap-2">
                <CheckCircle2 className="h-4 w-4 mt-0.5 flex-shrink-0" />
                <span>Your profile is complete! This maximizes your visibility to potential customers and helps our team deliver the best results.</span>
              </p>
            </div>
          ) : (
            <div className="space-y-3">
              <div className="p-3 bg-blue-50 border border-blue-100 rounded-md">
                <h4 className="text-sm font-semibold text-blue-700 mb-1">Why This Matters</h4>
                <p className="text-xs text-blue-700">
                  A complete business profile significantly improves your SEO performance and customer engagement. 
                  Every field you complete helps potential customers find you and increases their confidence in your business.
                </p>
              </div>
              
              <div className="space-y-1">
                <h4 className="text-sm font-semibold">Missing Information</h4>
                {status.missingFields.map(({ name, field }) => (
                  <div key={field} className="text-sm text-red-500 flex items-center gap-2">
                    <AlertCircle className="h-4 w-4 flex-shrink-0" />
                    <div>
                      <span>{name} missing</span>
                      {field === 'logo_url' && (
                        <span className="text-xs text-red-400 block">Businesses with logos get 47% more engagement</span>
                      )}
                      {field === 'opening_hours_json' && (
                        <span className="text-xs text-red-400 block">Showing when you're open increases visits by 36%</span>
                      )}
                      {field === 'business_services' && (
                        <span className="text-xs text-red-400 block">Listing services helps customers find exactly what they need</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="p-3 bg-amber-50 border border-amber-100 rounded-md">
                <h4 className="text-sm font-semibold text-amber-700 mb-1">Quick Tips</h4>
                <ul className="text-xs text-amber-700 space-y-1 list-disc pl-4">
                  <li>Keep your business information accurate and up-to-date at all times</li>
                  <li>Add high-quality images to attract more customers</li>
                  <li>Include detailed descriptions that match what customers search for</li>
                  <li>List all your services to improve discoverability</li>
                </ul>
              </div>
              
              <Button 
                variant="default" 
                className="w-full"
                onClick={() => navigate('/business-profile')}
              >
                Complete Profile
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
