
import { BusinessHours, BusinessHoursSchedule } from "../types";

export const DEFAULT_BUSINESS_HOURS: BusinessHoursSchedule = {
  monday: { open: "09:00", close: "17:00" },
  tuesday: { open: "09:00", close: "17:00" },
  wednesday: { open: "09:00", close: "17:00" },
  thursday: { open: "09:00", close: "17:00" },
  friday: { open: "09:00", close: "17:00" },
  saturday: { open: "09:00", close: "17:00" },
  sunday: { open: "09:00", close: "17:00" }
};

// Using 'export type' instead of 'export' when re-exporting types
export type { BusinessHours, BusinessHoursSchedule };
