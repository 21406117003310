
import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { DateRangePicker } from './DateRangePicker';
import { MetricsGrid } from './MetricsGrid';
import { MetricsChart } from './MetricsChart';
import { useDateRange } from '@/lib/hooks/useDateRange';
import { fetchMetricsData } from './api';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@/components/ui/skeleton';
import { addDays, startOfDay, subDays } from 'date-fns';
import { useEffect } from 'react';

interface GoogleBusinessMetricsProps {
  locationId: string;
  onError?: () => void;
}

export const GoogleBusinessMetrics = ({ locationId, onError }: GoogleBusinessMetricsProps) => {
  // Convert string-based date range to Date objects
  const [dateRange, setDateRange] = useState({
    from: subDays(startOfDay(new Date()), 30),
    to: startOfDay(new Date()),
  });
  
  const { data, isLoading, error } = useQuery({
    queryKey: ['gbpMetrics', locationId, dateRange.from.toISOString(), dateRange.to.toISOString()],
    queryFn: () => fetchMetricsData(locationId, dateRange),
    enabled: !!locationId,
  });

  // Call onError when there's an error
  useEffect(() => {
    if (error && onError) {
      onError();
    }
  }, [error, onError]);

  if (isLoading) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Google Business Profile Metrics</h2>
          <Skeleton className="h-10 w-64" />
        </div>
        
        <div className="grid gap-4 md:grid-cols-4">
          {[1, 2, 3, 4].map((i) => (
            <Card key={i} className="p-6">
              <div className="space-y-4">
                <Skeleton className="h-4 w-[100px]" />
                <Skeleton className="h-8 w-[120px]" />
              </div>
            </Card>
          ))}
        </div>
        
        <Card>
          <div className="p-6">
            <Skeleton className="h-4 w-[150px] mb-4" />
            <Skeleton className="h-[200px] w-full" />
          </div>
        </Card>
      </div>
    );
  }

  if (error) {
    return (
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4">Error Loading Metrics</h2>
        <p className="text-gray-500">
          There was a problem loading your business metrics. Please try again later.
        </p>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <h2 className="text-xl font-semibold mb-2 md:mb-0">Google Business Profile Metrics</h2>
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
      </div>
      
      {data ? (
        <>
          <MetricsGrid metrics={data.summary} />
          <MetricsChart chartData={data.dailyData} />
        </>
      ) : (
        <Card className="p-6">
          <p className="text-center text-gray-500">No metrics data available for the selected date range.</p>
        </Card>
      )}
    </div>
  );
};
