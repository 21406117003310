
import { MainNav } from "@/components/MainNav";
import { Card, CardContent } from "@/components/ui/card";
import { Helmet } from "react-helmet";
import { AuditWidget } from "@/components/audit/AuditWidget";

const FreeAudit = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-[#221F26]/5 to-[#403E43]/5">
      <Helmet>
        <title>Free Local SEO Audit | Woobound Marketing</title>
        <meta name="description" content="Get a free, comprehensive audit of your local SEO performance and discover opportunities to improve your online visibility." />
      </Helmet>
      
      <div className="border-b">
        <div className="container flex h-16 items-center">
          <MainNav />
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-[#221F26] leading-tight mb-4">
            Free Local SEO <span className="text-primary">Audit</span>
          </h1>
          <p className="text-xl text-[#403E43]/80 leading-relaxed">
            Discover how your business appears online and get actionable insights to improve your local visibility.
          </p>
        </div>

        <Card className="bg-white/90 backdrop-blur-sm border border-gray-100 shadow-lg rounded-xl overflow-hidden">
          <CardContent className="p-8">
            <div className="grid md:grid-cols-2 gap-8 items-start">
              <div>
                <h2 className="text-2xl font-bold text-[#221F26] mb-4">
                  See How Your Business Appears Online
                </h2>
                <ul className="space-y-3">
                  {[
                    "Get a comprehensive analysis of your online presence",
                    "Identify critical issues affecting your local visibility",
                    "Discover opportunities to outrank your competitors",
                    "Receive actionable recommendations tailored to your business"
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <span className="text-primary font-bold mr-2">✓</span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="h-[650px] w-full">
                <AuditWidget widgetId="e19cc46e826b09a4c99a7ada61490cf944022e86" />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default FreeAudit;
