
import { FormBusinessProfile } from "@/components/business/types";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface AdminFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const AdminFields = ({ profile, setProfile }: AdminFieldsProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Admin Settings</h3>
      
      <div className="space-y-2">
        <Label htmlFor="dashboard_url">Dashboard URL</Label>
        <Input
          id="dashboard_url"
          value={profile.dashboard_url || ''}
          onChange={(e) => setProfile({ 
            ...profile, 
            dashboard_url: e.target.value 
          })}
          placeholder="https://www.local-marketing-reports.com/location-dashboard/..."
          className="w-full"
        />
        <p className="text-sm text-gray-500">
          Enter the URL for this business's marketing reports dashboard
        </p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="location_id">Location ID</Label>
        <Input
          id="location_id"
          value={profile.location_id || ''}
          onChange={(e) => setProfile({ 
            ...profile, 
            location_id: e.target.value 
          })}
          placeholder="Enter location ID"
          className="w-full"
        />
        <p className="text-sm text-gray-500">
          Enter the listings location ID for this business (admin use only)
        </p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="admin_notes">Admin Notes</Label>
        <Textarea
          id="admin_notes"
          value={profile.admin_notes || ''}
          onChange={(e) => setProfile({ ...profile, admin_notes: e.target.value })}
          placeholder="Internal notes about this business"
          className="min-h-[100px]"
        />
      </div>
    </div>
  );
};
