
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface HeaderProps {
  onGetStartedClick: () => void;
}

export const Header = ({ onGetStartedClick }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="relative bg-gradient-to-r from-[#221F26]/5 to-[#403E43]/5 py-32 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="text-left space-y-6">
            <h1 className="text-5xl md:text-6xl font-bold text-[#221F26] leading-tight animate-fade-in">
              <span className="text-primary block">Launchpad:</span>
              <span className="text-primary block">Where Your Business</span>
              <span className="text-primary block">Growth Takes Off.</span>
            </h1>
            <p className="text-xl text-[#403E43]/80 leading-relaxed max-w-xl animate-fade-in delay-100">
              📍 Your customers are searching—Launch your business to the top and get found.
            </p>
            <div className="flex flex-wrap gap-4 pt-4 animate-fade-in delay-200">
              <Button
                size="lg"
                className="bg-primary hover:bg-primary/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
                onClick={onGetStartedClick}
              >
                Boost My Business Now <ArrowRight className="ml-2" />
              </Button>
              
              <Link to="/free-audit">
                <Button
                  size="lg"
                  variant="outline"
                  className="bg-white hover:bg-white/90 text-primary px-8 py-6 text-lg border-primary border-3 border-[3px] shadow-lg hover:shadow-xl transition-all"
                >
                  Get a Free Audit
                </Button>
              </Link>
            </div>
          </div>
          
          <div className="hidden md:flex justify-center items-center animate-fade-in delay-150">
            <div className="relative w-full max-w-md">
              <img 
                src="/lovable-uploads/ca0c2816-5abb-4aaa-b478-3aa72943b737.png" 
                alt="Multi-platform business listing across Google Maps, Search and Social Media" 
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
