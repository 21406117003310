
import { useSubscriptionData } from "@/hooks/useSubscriptionData";
import { Card } from "@/components/ui/card";
import { CreditCard, Package } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";

export const BillingOverview = () => {
  const { t } = useTranslation();
  
  const { 
    subscription, 
    isLoading, 
    error 
  } = useSubscriptionData({ 
    includeTxHistory: true,
    enableAutoRecovery: true
  });

  const formatDate = (date: string) => {
    if (!date) return '';
    
    try {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      console.error('Error formatting date:', e);
      return 'Invalid date';
    }
  };

  const formatAddress = (subscription: any) => {
    if (!subscription) return '';
    const parts = [
      subscription.billing_street_address,
      subscription.billing_city,
      subscription.billing_state,
      subscription.billing_zip_code
    ].filter(Boolean);
    
    // Use a fallback if the address is empty
    return parts.join(', ') || t('billing.billingAddress.noAddress', 'No address on file');
  };

  const getTrialInfo = (subscription: any) => {
    if (!subscription || !subscription.trial) return null;
    
    const nextBillingDate = subscription.next_billing_date ? new Date(subscription.next_billing_date) : null;
    if (!nextBillingDate) return null;
    
    const now = new Date();
    const daysLeft = Math.ceil((nextBillingDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    
    return {
      daysLeft: daysLeft > 0 ? daysLeft : 0,
      endDate: formatDate(subscription.next_billing_date)
    };
  };

  if (isLoading) {
    return (
      <Card className="p-6 bg-white shadow-sm border border-gray-100">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-24 bg-gray-200 rounded"></div>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          {error instanceof Error ? error.message : String(t('billing.currentPlan.loadError', 'Error loading subscription information'))}
        </AlertDescription>
      </Alert>
    );
  }
  
  console.log('[BillingOverview] Rendering with subscription data:', subscription);
  
  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <Package className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{t('billing.currentPlan.title')}</h2>
          <p className="text-sm text-gray-500">{t('billing.currentPlan.subtitle')}</p>
        </div>
      </div>

      {subscription ? (
        <div className="space-y-4">
          <div className="flex justify-between items-center pb-4 border-b border-gray-100">
            <div>
              <p className="text-sm font-medium text-gray-700">{t('billing.currentPlan.package')}</p>
              <p className="text-lg font-semibold text-gray-900">
                {t(`billing.packages.${subscription.package_type}`, subscription.package_type)}
              </p>
              
              {subscription.trial && (
                <div className="mt-1.5">
                  <span className="text-sm px-2 py-1 bg-blue-100 text-blue-700 rounded-full">
                    {t('billing.trial.status', 'Trial Subscription')}
                  </span>
                </div>
              )}
            </div>
            <div className="text-right">
              <p className="text-sm font-medium text-gray-700">{t('billing.currentPlan.monthlyPrice')}</p>
              <p className="text-lg font-semibold text-primary">${subscription.price}</p>
              {subscription.trial && (
                <p className="text-xs text-green-600 font-medium mt-1">{t('billing.trial.freeTrialPeriod', 'Free during trial period')}</p>
              )}
            </div>
          </div>

          {subscription.trial && getTrialInfo(subscription) && (
            <div className="bg-blue-50 p-4 rounded-md">
              <h3 className="font-medium text-blue-800 mb-1">{t('billing.trial.status', 'Trial Subscription')}</h3>
              <p className="text-blue-700 text-sm mb-2">
                {t('billing.trial.autoConvert', 'Your trial will automatically convert to a paid subscription after it ends.')}
              </p>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-blue-900">
                  <span className="font-medium">
                    {t('billing.trial.daysRemaining', { days: getTrialInfo(subscription)?.daysLeft })}
                  </span>
                </p>
                <p className="text-sm text-blue-900">
                  <span className="font-medium">
                    {t('billing.trial.endsOn', { date: getTrialInfo(subscription)?.endDate })}
                  </span>
                </p>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center pb-4 border-b border-gray-100">
            <div>
              <p className="text-sm font-medium text-gray-700">{t('billing.currentPlan.nextBillingDate')}</p>
              <p className="text-gray-900">
                {subscription.next_billing_date 
                  ? formatDate(subscription.next_billing_date) 
                  : t('billing.currentPlan.noNextBillingDate')}
              </p>
              {subscription.trial && (
                <p className="text-xs text-blue-600">{t('billing.trial.firstPaymentDue')}</p>
              )}
            </div>
            {subscription.payment_methods && (
              <div className="flex items-center gap-2">
                <CreditCard className="h-5 w-5 text-gray-400" />
                <div className="text-gray-600">
                  <p className="font-medium">
                    {subscription.payment_methods.card_type} ending in {subscription.payment_methods.last_four}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t('billing.currentPlan.cardExpiry', {
                      month: subscription.payment_methods.expiry_month,
                      year: subscription.payment_methods.expiry_year
                    })}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="pb-4 border-b border-gray-100">
            <p className="text-sm font-medium text-gray-700 mb-2">{t('billing.billingAddress.title')}</p>
            <p className="text-gray-900">
              {formatAddress(subscription)}
            </p>
          </div>

          {subscription.payment_transactions && subscription.payment_transactions.length > 0 ? (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">{t('billing.paymentHistory.title')}</h3>
              <div className="space-y-3">
                {subscription.payment_transactions.map((payment: any, index: number) => (
                  <div key={payment.id || index} className="flex justify-between items-center py-2">
                    <div>
                      <p className="text-sm text-gray-600">{formatDate(payment.created_at)}</p>
                      {subscription.payment_methods && (
                        <p className="text-sm text-gray-500">
                          {t('billing.paymentHistory.cardEnding', {
                            lastFour: subscription.payment_methods.last_four
                          })}
                        </p>
                      )}
                    </div>
                    <div className="text-right">
                      <p className="font-medium">${payment.amount}</p>
                      <span className={`text-xs px-2 py-1 rounded-full ${
                        payment.status === 'completed' || payment.status === 'paid'
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {payment.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-2">{t('billing.paymentHistory.title')}</h3>
              <p className="text-sm text-gray-500">{t('billing.paymentHistory.noPayments')}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center py-8">
          <p className="text-gray-500 mb-2">{t('billing.currentPlan.noSubscription')}</p>
          <p className="text-sm text-gray-400">
            {t('billing.currentPlan.noSubscriptionMessage')}
          </p>
        </div>
      )}
    </Card>
  );
};
