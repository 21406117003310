
import { WooboundLogo } from "@/components/shared/WooboundLogo";

export const SignupHeader = () => {
  return (
    <div className="mb-8">
      <WooboundLogo />
    </div>
  );
};
