import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { BusinessWithProfile } from "../types";
import { Card } from "@/components/ui/card";
import { BasicInfoFields } from "./form-sections/BasicInfoFields";
import { AddressFields } from "./form-sections/AddressFields";
import { OpeningHoursFields } from "./form-sections/OpeningHoursFields";
import { SeoFields } from "./form-sections/SeoFields";
import { AdditionalInfoFields } from "./form-sections/AdditionalInfoFields";
import { AdminFields } from "./form-sections/AdminFields";
import { ImageGallery } from "./form-sections/ImageGallery";
import { GbpVerificationFields } from "./form-sections/GbpVerificationFields";
import { FormBusinessProfile } from "@/components/business/types";

interface BusinessEditFormProps {
  business: BusinessWithProfile;
  onSuccess: () => void;
}

export const BusinessEditForm = ({ business, onSuccess }: BusinessEditFormProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Transform business data to FormBusinessProfile format
  const [formData, setFormData] = useState<FormBusinessProfile>({
    ...business,
    opening_hours_json: typeof business.opening_hours_json === 'string' 
      ? JSON.parse(business.opening_hours_json as string)
      : business.opening_hours_json || {
          monday: { open: "--:--", close: "--:--" },
          tuesday: { open: "--:--", close: "--:--" },
          wednesday: { open: "--:--", close: "--:--" },
          thursday: { open: "--:--", close: "--:--" },
          friday: { open: "--:--", close: "--:--" },
          saturday: { open: "--:--", close: "--:--" },
          sunday: { open: "--:--", close: "--:--" },
        },
    desired_keywords: Array.isArray(business.desired_keywords) 
      ? business.desired_keywords 
      : (business.desired_keywords ? String(business.desired_keywords).split(',') : []),
    desired_keywords_text: Array.isArray(business.desired_keywords)
      ? business.desired_keywords.join('\n')
      : (business.desired_keywords || ''),
    business_services: Array.isArray(business.business_services) 
      ? business.business_services 
      : (business.business_services ? String(business.business_services).split(',') : []),
    business_services_text: Array.isArray(business.business_services)
      ? business.business_services.join('\n')
      : (business.business_services || ''),
    payment_methods: Array.isArray(business.payment_methods) 
      ? business.payment_methods 
      : (business.payment_methods ? String(business.payment_methods).split(',') : []),
    payment_methods_text: Array.isArray(business.payment_methods)
      ? business.payment_methods.join('\n')
      : (business.payment_methods || ''),
    dashboard_url: business.dashboard_url || null,
    location_id: business.location_id || null,
    gbp_verified: business.gbp_verified || false,
    gbp_verified_at: business.gbp_verified_at || null,
    gbp_verified_by: business.gbp_verified_by || null,
  });

  const handleImageUpdate = (field: string, value: string | null) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const processFieldsBeforeSubmit = () => {
    // Process all the text fields to their array versions
    const processedData = { ...formData };
    
    // Process the keywords from the text field
    if (processedData.desired_keywords_text) {
      processedData.desired_keywords = processedData.desired_keywords_text
        .split(/[\n,]/)
        .map(keyword => keyword.trim())
        .filter(keyword => keyword.length > 0);
    }
    
    // Process business services from the text field
    if (processedData.business_services_text) {
      processedData.business_services = processedData.business_services_text
        .split(/[\n,]/)
        .map(service => service.trim())
        .filter(service => service.length > 0);
    }
    
    // Process payment methods from the text field
    if (processedData.payment_methods_text) {
      processedData.payment_methods = processedData.payment_methods_text
        .split(/[\n,]/)
        .map(method => method.trim())
        .filter(method => method.length > 0);
    }
    
    console.log("Admin processing fields before submit:", {
      desiredKeywords: processedData.desired_keywords,
      businessServices: processedData.business_services,
      paymentMethods: processedData.payment_methods
    });
    
    return processedData;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Process all text fields before submitting
      const processedData = processFieldsBeforeSubmit();
      
      // Format the data for database update
      // Create a copy without the text fields
      const { 
        desired_keywords_text, 
        business_services_text, 
        payment_methods_text,
        ...dataToSubmit 
      } = processedData;
      
      const updatedData = {
        ...dataToSubmit,
        opening_hours_json: JSON.stringify(dataToSubmit.opening_hours_json),
      };

      console.log("Submitting business profile update:", updatedData);

      const { data, error: updateError } = await supabase
        .from('business_profiles')
        .update({
          business_name: updatedData.business_name,
          business_email: updatedData.business_email,
          website_url: updatedData.website_url,
          phone_number: updatedData.phone_number,
          street_address: updatedData.street_address,
          city: updatedData.city,
          state: updatedData.state,
          zip_code: updatedData.zip_code,
          category: updatedData.category,
          opening_hours: updatedData.opening_hours,
          opening_hours_json: updatedData.opening_hours_json,
          opening_date: updatedData.opening_date,
          short_description: updatedData.short_description,
          long_description: updatedData.long_description,
          facebook_url: updatedData.facebook_url,
          instagram_url: updatedData.instagram_url,
          business_services: updatedData.business_services,
          desired_keywords: updatedData.desired_keywords,
          seo_campaign_goal: updatedData.seo_campaign_goal,
          promotions: updatedData.promotions,
          payment_methods: updatedData.payment_methods,
          admin_notes: updatedData.admin_notes,
          dashboard_url: updatedData.dashboard_url,
          location_id: updatedData.location_id,
          logo_url: updatedData.logo_url,
          square_logo_url: updatedData.square_logo_url,
          primary_image_url: updatedData.primary_image_url,
          gbp_verified: updatedData.gbp_verified,
          gbp_verified_at: updatedData.gbp_verified_at,
          gbp_verified_by: updatedData.gbp_verified_by,
        })
        .eq('id', business.id);

      console.log("Update response:", { data, error: updateError });

      if (updateError) throw updateError;

      // Log the admin activity
      try {
        const currentUser = await supabase.auth.getUser();
        const { error: activityError } = await supabase
          .from('admin_activities')
          .insert({
            admin_id: currentUser.data.user?.id,
            activity_type: updatedData.gbp_verified !== business.gbp_verified ? 'update_gbp_verification' : 'update_business',
            business_id: business.id,
            description: updatedData.gbp_verified !== business.gbp_verified 
              ? `${updatedData.gbp_verified ? 'Verified' : 'Unverified'} Google Business Profile access`
              : 'Updated business profile information',
            metadata: {
              updated_fields: Object.keys(updatedData).filter(key => 
                updatedData[key as keyof typeof updatedData] !== 
                business[key as keyof typeof business]
              ),
              gbp_verification_changed: updatedData.gbp_verified !== business.gbp_verified
            }
          });

        if (activityError) {
          console.error('Error logging admin activity:', activityError);
          // Don't throw here, we still want to show success even if activity logging fails
        }
      } catch (activityErr) {
        console.error('Unexpected error logging admin activity:', activityErr);
      }

      toast({
        title: "Success",
        description: "Business profile updated successfully",
      });
      
      onSuccess();
    } catch (error: any) {
      console.error('Error updating business:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to update business profile",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-8">
        <BasicInfoFields profile={formData} setProfile={setFormData} />
        <AddressFields profile={formData} setProfile={setFormData} />
        <OpeningHoursFields profile={formData} setProfile={setFormData} />
        <SeoFields profile={formData} setProfile={setFormData} />
        <AdditionalInfoFields profile={formData} setProfile={setFormData} />
        <GbpVerificationFields profile={formData} setProfile={setFormData} />
        <AdminFields profile={formData} setProfile={setFormData} />

        <ImageGallery
          businessId={formData.id}
          logoUrl={formData.logo_url}
          squareLogoUrl={formData.square_logo_url}
          primaryImageUrl={formData.primary_image_url}
          onUpdate={handleImageUpdate}
        />

        <div className="flex justify-end gap-4">
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </div>
    </form>
  );
};
