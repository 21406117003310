
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Skeleton } from "@/components/ui/skeleton";
import { AlertCircle, Info } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TrendChart } from "@/components/trends/TrendChart";
import { TrendsGuide } from "@/components/trends/TrendsGuide";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "@/components/LanguageSelector";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

interface TrendData {
  date: string;
  value: number;
}

interface BusinessProfileData {
  category: string;
  state: string;
  last_trends_check?: string | null;
}

export default function GoogleTrends() {
  const { toast } = useToast();
  const { t } = useTranslation("googletrends");
  const [nationalTrendData, setNationalTrendData] = useState<TrendData[]>([]);
  const [stateTrendData, setStateTrendData] = useState<TrendData[]>([]);
  const [lastCategory, setLastCategory] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const { data: businessProfile, isLoading: profileLoading, error: profileError } = useQuery({
    queryKey: ['business-profile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;

      const { data, error } = await supabase
        .from('business_profiles')
        .select('category, state, last_trends_check')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) throw error;
      return data as BusinessProfileData;
    },
  });

  useEffect(() => {
    const fetchTrends = async () => {
      if (!businessProfile?.category || !businessProfile?.state) return;

      try {
        setIsLoading(true);
        setApiError(null);
        
        const { error: updateError } = await supabase
          .from('business_profiles')
          .update({ last_trends_check: new Date().toISOString() })
          .eq('category', businessProfile.category);

        if (updateError) {
          console.error('Error updating last_trends_check:', updateError);
        }

        const { data: nationalData, error: nationalError } = await supabase.functions.invoke('google-trends', {
          body: { 
            keyword: businessProfile.category,
            geo: 'US'
          }
        });

        if (nationalError) throw nationalError;
        
        if (nationalData.error) {
          throw new Error(nationalData.error);
        }
        
        setNationalTrendData(nationalData);

        const { data: stateData, error: stateError } = await supabase.functions.invoke('google-trends', {
          body: { 
            keyword: businessProfile.category,
            geo: `US-${businessProfile.state}`
          }
        });

        if (stateError) throw stateError;
        
        if (stateData.error) {
          throw new Error(stateData.error);
        }
        
        setStateTrendData(stateData);

        if (lastCategory && lastCategory !== businessProfile.category) {
          toast({
            title: "Category Change Detected",
            description: `Your business category has been updated from "${lastCategory}" to "${businessProfile.category}". The trends data has been refreshed accordingly.`,
          });
        }
        setLastCategory(businessProfile.category);

      } catch (error: any) {
        console.error('Error fetching trends:', error);
        setApiError(error.message || "Unknown error occurred");
        toast({
          title: "Error fetching trend data",
          description: error.message || "Unknown error occurred",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrends();
  }, [businessProfile?.category, businessProfile?.state, toast, lastCategory]);

  const renderContent = () => {
    if (profileLoading) {
      return (
        <Skeleton className="h-[400px] w-full" />
      );
    }

    if (profileError) {
      return (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            Unable to load business profile. Please try again later.
          </AlertDescription>
        </Alert>
      );
    }

    if (apiError) {
      return (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>API Error</AlertTitle>
          <AlertDescription>
            {apiError.includes("API key configuration") 
              ? "The Trends API is currently unavailable. Our team has been notified and is working to resolve this issue."
              : apiError}
          </AlertDescription>
        </Alert>
      );
    }

    if (!businessProfile?.category) {
      return (
        <Alert>
          <Info className="h-4 w-4" />
          <AlertTitle>Missing Information</AlertTitle>
          <AlertDescription>
            Please set up your business profile with a category to view trends.
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <div>
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">{t('title')}</h1>
            <p className="text-sm text-gray-500 mt-1">
              {t('subtitle').replace('nail salon', businessProfile.category)}
            </p>
          </div>
          <LanguageSelector />
        </div>

        <TrendsGuide />

        <Tabs defaultValue="national" className="space-y-6">
          <TabsList>
            <TabsTrigger value="national">{t('nationalTrends.title')}</TabsTrigger>
            <TabsTrigger value="state">{t('stateTrends.title')}</TabsTrigger>
          </TabsList>
          
          <TabsContent value="national">
            <TrendChart 
              data={nationalTrendData}
              title={t('nationalTrends.subtitle')}
              description={t('nationalTrends.description').replace('nail salon', businessProfile.category)}
              isLoading={isLoading}
            />
          </TabsContent>
          
          <TabsContent value="state">
            <TrendChart 
              data={stateTrendData}
              title={t('stateTrends.subtitle').replace('Texas', businessProfile.state)}
              description={t('stateTrends.description').replace('nail salon', businessProfile.category).replace('Texas', businessProfile.state)}
              isLoading={isLoading}
            />
          </TabsContent>
        </Tabs>
      </div>
    );
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-7xl mx-auto space-y-8">
              {renderContent()}
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
}
