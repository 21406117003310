
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

interface MetricsChartProps {
  chartData: Array<{
    date: string;
    mobileImpressions: number;
    desktopImpressions: number;
  }>;
}

export function MetricsChart({ chartData }: MetricsChartProps) {
  // Group data by device type for visualization
  const formattedData = chartData.map(item => ({
    date: item.date,
    Mobile: item.mobileImpressions,
    Desktop: item.desktopImpressions,
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle>Impressions by Date</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={formattedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="Mobile" fill="#F97316" />
              <Bar dataKey="Desktop" fill="#2563EB" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
}
