import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, ExternalLink } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const ReportsPage = () => {
  const { toast } = useToast();
  const [hasOpened, setHasOpened] = useState(false);
  
  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const { data: businessProfile, isLoading } = useQuery({
    queryKey: ['businessProfile', profile?.id],
    queryFn: async () => {
      if (!profile?.id) return null;

      const { data, error } = await supabase
        .from('business_profiles')
        .select('*')
        .eq('user_id', profile.id)
        .single();
      
      if (error) {
        console.error("Error fetching business profile:", error);
        throw error;
      }
      
      console.log("Retrieved business profile:", data);
      return data;
    },
    enabled: !!profile?.id,
  });
  
  useEffect(() => {
    if (businessProfile?.dashboard_url && !hasOpened) {
      window.open(businessProfile.dashboard_url, '_blank', 'noopener,noreferrer');
      setHasOpened(true);
      
      toast({
        title: "Dashboard Opened",
        description: "Your marketing dashboard has been opened in a new tab.",
      });
    }
  }, [businessProfile?.dashboard_url, hasOpened, toast]);

  const openInNewTab = () => {
    if (businessProfile?.dashboard_url) {
      window.open(businessProfile.dashboard_url, '_blank', 'noopener,noreferrer');
      toast({
        title: "Dashboard Opened",
        description: "Your marketing dashboard has been opened in a new tab.",
      });
    }
  };

  if (isLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 overflow-x-auto md:pl-[12rem]">
            <div className="w-full px-8 py-6">
              <div className="max-w-4xl mx-auto">
                <div className="w-full animate-pulse space-y-4">
                  <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  if (!businessProfile?.dashboard_url) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 overflow-x-auto md:pl-[12rem]">
            <div className="w-full px-8 py-6">
              <div className="max-w-4xl mx-auto">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 mb-6">Reports Dashboard</h1>
                <div className="bg-white p-8 rounded-lg shadow-sm border border-gray-100">
                  <div className="flex flex-col items-center justify-center gap-4">
                    <div className="rounded-full bg-orange-100 p-3">
                      <AlertCircle className="h-6 w-6 text-orange-500" />
                    </div>
                    <h3 className="text-lg font-medium">Your dashboard is being prepared</h3>
                    <p className="text-gray-500 text-center max-w-md">
                      Our team is currently building your personalized reports dashboard. 
                      We'll notify you when it's ready to view your marketing performance metrics.
                    </p>
                    <div className="mt-4 w-full max-w-md">
                      <Skeleton className="h-8 w-full mb-2" />
                      <Skeleton className="h-24 w-full mb-2" />
                      <Skeleton className="h-32 w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-4xl mx-auto">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 mb-6">Reports Dashboard</h1>
              <Card>
                <CardHeader>
                  <CardTitle>Your Marketing Dashboard</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col items-center text-center gap-4 py-6">
                    <div className="rounded-full bg-green-100 p-3">
                      <ExternalLink className="h-6 w-6 text-green-500" />
                    </div>
                    <h3 className="text-lg font-medium">Your dashboard has been opened externally</h3>
                    <p className="text-gray-500 max-w-md mb-4">
                      Your marketing dashboard has been opened in a new browser tab. If you don't see it, 
                      please check if your browser has blocked pop-ups.
                    </p>
                    <Button 
                      onClick={openInNewTab}
                      className="flex items-center gap-2"
                    >
                      <ExternalLink className="h-4 w-4" />
                      Open Dashboard Again
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default ReportsPage;
