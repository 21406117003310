
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { BillingTabs } from "./billing/BillingTabs";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SubscriptionFixer } from "./SubscriptionFixer";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle, RefreshCcw, Info } from "lucide-react";
import { Button } from "@/components/ui/button";

export const ClientBillingManager = () => {
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("active");
  const [activeToolTab, setActiveToolTab] = useState("subscriptions");
  const [policiesSetup, setPoliciesSetup] = useState(false);
  const [isSettingUpPolicies, setIsSettingUpPolicies] = useState(false);
  const [schemaError, setSchemaError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [setupResults, setSetupResults] = useState<{success: boolean; results?: string[]; errors?: any[]}>({success: false});

  // Set up admin policies when component mounts
  useEffect(() => {
    const setupAdminPolicies = async () => {
      try {
        setIsSettingUpPolicies(true);
        console.log('Setting up admin policies...');
        
        const { data, error } = await supabase.functions.invoke('admin-policies');
        
        console.log('Admin policies response:', { data, error });
        
        if (error) {
          console.error('Error setting up admin policies:', error);
          toast({
            title: "Warning",
            description: "Could not set up admin policies: " + error.message,
            variant: "destructive",
          });
          
          if (retryCount < 2) {
            // Auto-retry for errors
            setTimeout(() => {
              setRetryCount(prev => prev + 1);
            }, 1000);
          } else {
            // After retries, just set policies as setup anyway to allow data fetching
            setPoliciesSetup(true);
          }
        } else {
          console.log('Admin policies setup:', data);
          setPoliciesSetup(true);
          setSetupResults(data);
          
          if (data?.success) {
            toast({
              title: "Success",
              description: data?.message || "Admin policies have been configured",
            });
          } else {
            // Partial success or failure
            toast({
              title: "Warning",
              description: data?.message || "Some admin policies could not be configured",
              variant: "destructive",
            });
            
            // Even with partial success, allow data fetching
            setPoliciesSetup(true);
          }
        }
      } catch (err) {
        console.error('Exception setting up admin policies:', err);
        toast({
          title: "Error",
          description: "Failed to set up admin policies: " + (err.message || "Unknown error"),
          variant: "destructive",
        });
        
        // Even with errors, allow data fetching after max retries
        if (retryCount >= 2) {
          setPoliciesSetup(true);
        }
      } finally {
        setIsSettingUpPolicies(false);
      }
    };

    if (!policiesSetup && !isSettingUpPolicies) {
      setupAdminPolicies();
    }
  }, [toast, policiesSetup, isSettingUpPolicies, retryCount]);

  const { 
    data: subscriptionData, 
    isLoading: isLoadingSubscriptions, 
    refetch, 
    error: subscriptionError 
  } = useQuery({
    queryKey: ["admin-subscriptions", retryCount],
    queryFn: async () => {
      console.log('Fetching subscriptions as admin...');
      
      try {
        // First get Supabase subscriptions with improved error handling
        const { data: supabaseSubscriptions, error: supabaseError } = await supabase
          .from("subscriptions")
          .select(`
            *,
            profiles:profiles(email, first_name, last_name, id)
          `);

        if (supabaseError) {
          console.error('Supabase subscriptions error:', supabaseError);
          setSchemaError(supabaseError.message);
          throw new Error(`Database error: ${supabaseError.message}`);
        }

        console.log('Supabase subscriptions:', supabaseSubscriptions?.length || 0, 'found');

        // Get payment methods in a separate query to avoid join issues
        const { data: paymentMethods, error: paymentMethodsError } = await supabase
          .from("payment_methods")
          .select("*");

        if (paymentMethodsError) {
          console.error('Payment methods error:', paymentMethodsError);
          // Don't throw - continue with available data
        }

        // Get transactions in a separate query to avoid join issues
        const { data: transactions, error: transactionsError } = await supabase
          .from("payment_transactions")
          .select("*");

        if (transactionsError) {
          console.error('Transactions error:', transactionsError);
          // Don't throw - continue with available data
        }

        // Also get subscriptions from Authorize.net
        console.log('Fetching Authorize.net subscriptions...');
        const { data: authorizeData, error: authorizeError } = await supabase.functions.invoke('authorize-subscriptions');
          
        if (authorizeError) {
          console.error('Authorize.net error:', authorizeError);
          // Don't throw - continue with Supabase data
        }
        
        console.log('Authorize.net subscriptions:', authorizeData?.subscriptions?.length || 0, 'found');

        // Begin with Supabase subscriptions
        let enrichedSubscriptions = supabaseSubscriptions?.map(sub => {
          // Find payment method for this subscription
          const paymentMethod = paymentMethods?.find(pm => pm.id === sub.payment_method_id);
          
          // Find transactions for this subscription
          const subTransactions = transactions?.filter(tx => tx.subscription_id === sub.id) || [];
          
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          
          // Default profile if not found
          const profile = sub.profiles || { 
            email: "Unknown", 
            first_name: "Unknown", 
            last_name: "User",
            id: sub.user_id 
          };
          
          return {
            ...sub,
            profiles: profile,
            payment_methods: paymentMethod,
            payment_transactions: subTransactions,
            authorize_status: sub.status || 'unknown',
            source: 'database',
            has_expiring_card: paymentMethod ? 
              new Date(`20${paymentMethod.expiry_year}-${paymentMethod.expiry_month}-01`) < new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) 
              : false,
            has_declined_transaction: subTransactions.some(tx => tx.status === 'failed'),
            next_billing_date: sub.next_billing_date || tomorrow.toISOString()
          };
        }) || [];

        // Add Authorize.net subscriptions if available
        if (authorizeData?.subscriptions && Array.isArray(authorizeData.subscriptions)) {
          const authorizeSubscriptions = authorizeData.subscriptions.map(sub => {
            // Check if this Authorize.net subscription already exists in our database
            const existingSubscription = enrichedSubscriptions.find(
              dbSub => dbSub.external_subscription_id === sub.id
            );

            // Skip if we already have this subscription in our enriched list
            if (existingSubscription) {
              // Update the existing subscription with the latest Authorize.net status
              existingSubscription.authorize_status = sub.status;
              return null; // Skip this one since we're updating the existing record
            }

            // Map Authorize.net subscription to our app format
            return {
              id: sub.id || `auth_${Date.now()}_${Math.random().toString(36).substring(2, 7)}`,
              external_subscription_id: sub.id,
              status: sub.status || 'unknown',
              price: sub.amount || 0,
              profiles: { 
                email: sub.email || "Unknown", 
                first_name: sub.firstName || "Unknown", 
                last_name: sub.lastName || "User",
                id: sub.customerId || null
              },
              authorize_status: sub.status,
              source: 'authorize.net',
              next_billing_date: sub.nextBillingDate || new Date().toISOString(),
              payment_transactions: [],
              has_expiring_card: authorizeData?.expiringCards?.some(card => card.id === sub.id) || false,
              has_declined_transaction: authorizeData?.declinedTransactions?.some(tx => tx.subscriptionId === sub.id) || false,
              package_type: sub.name || "Unknown Package"
            };
          }).filter(Boolean); // Remove null entries (already existing subscriptions)

          // Add unique Authorize.net subscriptions to our enriched list
          enrichedSubscriptions = [...enrichedSubscriptions, ...authorizeSubscriptions];
        }

        console.log('Combined subscriptions:', enrichedSubscriptions?.length || 0, 'total');
        setSchemaError(null);
        return enrichedSubscriptions;
      } catch (error) {
        console.error('Failed to get data from either source:', error);
        setSchemaError(error.message || "Unknown error fetching subscription data");
        return []; // Return empty array to prevent rendering errors
      }
    },
    enabled: true, // Always enable the query now for better UX 
    retry: 2,
    refetchOnWindowFocus: false
  });

  const handleRetryPolicies = async () => {
    setSchemaError(null);
    setPoliciesSetup(false);
    setRetryCount(prev => prev + 1);
    await new Promise(resolve => setTimeout(resolve, 500));
    refetch();
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <h2 className="text-2xl font-bold mb-6">Client Billing Management</h2>
        
        {schemaError && (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Database Error</AlertTitle>
            <AlertDescription className="space-y-2">
              <p>There was an issue with the database: {schemaError}</p>
              <Button 
                size="sm" 
                onClick={handleRetryPolicies}
                variant="outline"
                className="flex items-center gap-2"
              >
                <RefreshCcw className="h-4 w-4" /> Retry Setup
              </Button>
            </AlertDescription>
          </Alert>
        )}
        
        {!schemaError && setupResults && !setupResults.success && setupResults.errors && setupResults.errors.length > 0 && (
          <Alert variant="warning" className="mb-6">
            <Info className="h-4 w-4" />
            <AlertTitle>Setup Warning</AlertTitle>
            <AlertDescription className="space-y-2">
              <p>Some admin policies couldn't be set up properly. This might affect data visibility:</p>
              <ul className="list-disc pl-5 space-y-1 text-sm">
                {setupResults.errors.map((err, i) => (
                  <li key={i}>{err.step}: {err.error}</li>
                ))}
              </ul>
              <Button 
                size="sm" 
                onClick={handleRetryPolicies}
                variant="outline"
                className="flex items-center gap-2"
              >
                <RefreshCcw className="h-4 w-4" /> Retry Setup
              </Button>
            </AlertDescription>
          </Alert>
        )}
        
        <Tabs value={activeToolTab} onValueChange={setActiveToolTab} className="mb-6">
          <TabsList>
            <TabsTrigger value="subscriptions">Subscriptions</TabsTrigger>
            <TabsTrigger value="tools">Admin Tools</TabsTrigger>
          </TabsList>
          
          <TabsContent value="subscriptions">
            <BillingTabs
              activeTab={activeTab}
              onTabChange={setActiveTab}
              subscriptionData={subscriptionData || []}
              isLoadingSubscriptions={isLoadingSubscriptions || isSettingUpPolicies}
            />
          </TabsContent>
          
          <TabsContent value="tools">
            <SubscriptionFixer />
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
};
