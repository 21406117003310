
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Plus, Edit, Trash2, Eye } from "lucide-react";
import { Link } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { CourseForm } from "@/components/academy/CourseForm";
import { AcademyCourse } from "@/integrations/supabase/types/academy";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

interface CourseWithModules extends AcademyCourse {
  academy_modules: { id: string }[];
}

const CourseManagement = () => {
  const { toast } = useToast();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [editingCourse, setEditingCourse] = useState<AcademyCourse | null>(null);
  
  const { data: courses, isLoading } = useQuery({
    queryKey: ['admin_academy_courses'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('academy_courses')
        .select(`
          *,
          academy_modules (
            id
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      return (data as CourseWithModules[]).map(course => ({
        ...course,
        status: course.status as 'draft' | 'published'
      }));
    },
  });

  const handleDelete = async (courseId: string) => {
    try {
      const { error } = await supabase
        .from('academy_courses')
        .delete()
        .eq('id', courseId);

      if (error) throw error;

      toast({
        title: "Course Deleted",
        description: "The course has been successfully deleted.",
      });
    } catch (error) {
      console.error('Error deleting course:', error);
      toast({
        title: "Error",
        description: "Failed to delete the course. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-6xl mx-auto space-y-8">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-2xl font-semibold text-gray-900">Course Management</h1>
                  <p className="text-sm text-gray-500">Create and manage academy courses</p>
                </div>
                <Button onClick={() => setIsCreateDialogOpen(true)}>
                  <Plus className="h-4 w-4 mr-2" />
                  New Course
                </Button>
              </div>

              {isLoading ? (
                <div className="space-y-4">
                  {[1, 2, 3].map((i) => (
                    <Card key={i} className="p-6 animate-pulse">
                      <div className="h-6 bg-gray-200 rounded w-1/4 mb-2" />
                      <div className="h-4 bg-gray-200 rounded w-1/2" />
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="space-y-4">
                  {courses?.map((course) => (
                    <Card key={course.id} className="p-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="font-semibold text-lg">{course.title}</h3>
                          <p className="text-sm text-gray-500">{course.description}</p>
                          <div className="flex items-center gap-4 mt-2">
                            <span className="text-xs px-2 py-1 rounded-full bg-gray-100">
                              {course.status}
                            </span>
                            <span className="text-xs text-gray-500">
                              {course.academy_modules?.length || 0} modules
                            </span>
                            {course.is_free ? (
                              <span className="text-xs text-green-600">Free</span>
                            ) : (
                              <span className="text-xs text-gray-600">${course.price}</span>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Button variant="ghost" size="icon" asChild>
                            <Link to={`/academy/courses/${course.id}`}>
                              <Eye className="h-4 w-4" />
                            </Link>
                          </Button>
                          <Button 
                            variant="ghost" 
                            size="icon"
                            onClick={() => setEditingCourse(course)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <Button 
                            variant="ghost" 
                            size="icon"
                            onClick={() => handleDelete(course.id)}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              )}
            </div>

            {/* Create Course Dialog */}
            <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
              <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                  <DialogTitle>Create New Course</DialogTitle>
                </DialogHeader>
                <CourseForm
                  onSuccess={() => setIsCreateDialogOpen(false)}
                  onCancel={() => setIsCreateDialogOpen(false)}
                />
              </DialogContent>
            </Dialog>

            {/* Edit Course Dialog */}
            <Dialog open={!!editingCourse} onOpenChange={() => setEditingCourse(null)}>
              <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                  <DialogTitle>Edit Course</DialogTitle>
                </DialogHeader>
                {editingCourse && (
                  <CourseForm
                    course={editingCourse}
                    onSuccess={() => setEditingCourse(null)}
                    onCancel={() => setEditingCourse(null)}
                  />
                )}
              </DialogContent>
            </Dialog>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default CourseManagement;
