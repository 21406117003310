
export const common = {
  dashboard: "Panel de Control",
  resources: "Recursos",
  videos: "Videos",
  academy: "Academia",
  googleTrends: "Tendencias de Google",
  myAccount: "Mi Cuenta",
  businessProfile: "Perfil de Negocio",
  integrations: "Integraciones",
  billing: "Facturación",
  settings: "Configuración",
  support: "Soporte",
  logout: "Cerrar Sesión",
  logoutSuccess: "Has cerrado sesión exitosamente.",
  adminPanel: "Panel de Administración",
  partner: "Socio",
  reports: "Informes",
  reviews: "Mis Reseñas"
};
