
import { supabase } from '@/integrations/supabase/client';

export const authOperations = {
  signIn: async (email: string, password: string) => {
    console.log('Attempting sign in for:', email);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error('Sign in error:', error);
      throw error;
    }

    console.log('Sign in successful:', data);
    return data;
  },

  signUp: async (email: string, password: string, metadata?: any) => {
    console.log('Attempting sign up for:', email);
    
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: metadata,
      },
    });
    
    if (error) throw error;
    
    console.log('Sign up successful:', data);
    return data;
  },

  signOut: async () => {
    console.log('[authOperations] Starting sign out process');
    try {
      // First, clear all query cache and local storage
      localStorage.clear();
      
      // Try to delete any remaining IndexedDB data
      try {
        const req = window.indexedDB.deleteDatabase('supabase');
        req.onerror = () => console.error('Error deleting IndexedDB');
        req.onsuccess = () => console.log('IndexedDB deleted successfully');
      } catch (err) {
        console.error('IndexedDB deletion error:', err);
      }

      // Sign out from Supabase (this will clear auth tokens)
      const { error } = await supabase.auth.signOut({ scope: 'global' });
      if (error) throw error;

      console.log('[authOperations] Sign out successful');

      // Force a complete page reload to clear all state
      window.location.href = '/login';
    } catch (error) {
      console.error('[authOperations] Sign out error:', error);
      // Even if there's an error, try to force logout
      window.location.href = '/login';
      throw error;
    }
  }
};
