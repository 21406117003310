
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { GraduationCap } from "lucide-react";
import { Link } from "react-router-dom";

interface PartnerLesson {
  id: string;
  title: string;
  description: string | null;
  video_url: string | null;
  document_url: string | null;
  order_number: number;
}

export const PartnerLessons = () => {
  const { data: lessons, isLoading } = useQuery({
    queryKey: ['partner_lessons'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('partner_lessons')
        .select('*')
        .order('order_number');
      if (error) throw error;
      return data as PartnerLesson[];
    },
  });

  if (isLoading) {
    return (
      <section>
        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <GraduationCap className="h-5 w-5 text-[#F97316]" />
          Partner Training
        </h2>
        <div className="space-y-4">
          {Array(3).fill(0).map((_, i) => (
            <Card key={i} className="p-6 animate-pulse">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-3 bg-gray-200 rounded w-1/2"></div>
            </Card>
          ))}
        </div>
      </section>
    );
  }

  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
        <GraduationCap className="h-5 w-5 text-[#F97316]" />
        Partner Training
      </h2>
      <div className="space-y-4">
        {lessons?.map((lesson) => (
          <Card key={lesson.id} className="p-6">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">{lesson.title}</h3>
                <p className="text-sm text-gray-500">{lesson.description}</p>
              </div>
              <Button asChild className="w-full md:w-auto">
                <Link to={`/partner/lessons/${lesson.id}`}>
                  Start Lesson
                </Link>
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
};
