
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Loader2, Download } from 'lucide-react';
import { format } from 'date-fns';
import { formatCurrency } from '@/lib/utils';
import { DatePickerWithRange } from '@/components/ui/date-range-picker';
import { addMonths, startOfMonth, endOfMonth } from 'date-fns';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

export function CommissionRecords() {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startOfMonth(addMonths(new Date(), -1)),
    to: endOfMonth(new Date())
  });

  const { data: commissionRecords, isLoading } = useQuery({
    queryKey: ['commission-records', dateRange],
    queryFn: async () => {
      if (!dateRange.from || !dateRange.to) return [];
      
      const { data, error } = await supabase
        .from('partner_commission_records')
        .select(`
          *,
          partner_codes (
            code,
            commission_percentage
          )
        `)
        .gte('month_year', dateRange.from.toISOString())
        .lte('month_year', dateRange.to.toISOString())
        .order('month_year', { ascending: false });
      
      if (error) throw error;
      return data;
    },
  });

  const totalCommission = commissionRecords?.reduce((sum, record) => 
    sum + Number(record.commission_amount), 0) || 0;

  const handleExportCSV = () => {
    if (!commissionRecords?.length) return;

    const headers = [
      'Month',
      'Partner Code',
      'Total Revenue',
      'Commission Amount',
      'Status',
      'Payment Date'
    ];

    const rows = commissionRecords.map(record => [
      format(new Date(record.month_year), 'MMMM yyyy'),
      record.partner_codes.code,
      record.total_revenue,
      record.commission_amount,
      record.payment_status,
      record.paid_at ? format(new Date(record.paid_at), 'PP') : '-'
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `commission-records-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h3 className="text-lg font-semibold">Monthly Commission Records</h3>
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
          <DatePickerWithRange 
            date={dateRange}
            onDateChange={setDateRange}
          />
          <Button
            variant="outline"
            onClick={handleExportCSV}
            disabled={!commissionRecords?.length}
          >
            <Download className="mr-2 h-4 w-4" />
            Export CSV
          </Button>
        </div>
      </div>

      {commissionRecords?.length === 0 ? (
        <Alert>
          <AlertDescription>
            No commission records found for the selected date range.
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <div className="bg-muted/50 p-4 rounded-lg">
            <p className="text-sm font-medium">
              Total Commission for Selected Period: {formatCurrency(totalCommission)}
            </p>
          </div>
          
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Month</TableHead>
                <TableHead>Partner Code</TableHead>
                <TableHead>Total Revenue</TableHead>
                <TableHead>Commission Amount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Payment Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {commissionRecords?.map((record) => (
                <TableRow key={record.id}>
                  <TableCell>
                    {format(new Date(record.month_year), 'MMMM yyyy')}
                  </TableCell>
                  <TableCell className="font-medium">
                    {record.partner_codes.code}
                  </TableCell>
                  <TableCell>{formatCurrency(record.total_revenue)}</TableCell>
                  <TableCell>{formatCurrency(record.commission_amount)}</TableCell>
                  <TableCell>
                    <Badge 
                      variant={
                        record.payment_status === 'paid' ? 'default' :
                        record.payment_status === 'pending' ? 'secondary' :
                        'destructive'
                      }
                    >
                      {record.payment_status}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {record.paid_at ? format(new Date(record.paid_at), 'PP') : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
}

