
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Check, Package, Award, HeadsetIcon, FileX } from "lucide-react";

export const WhyChoose = () => {
  const { t } = useTranslation();

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    pricingSection?.scrollIntoView({ behavior: 'smooth' });
  };

  const benefitIcons = [
    { key: 0, icon: Package },
    { key: 1, icon: Award },
    { key: 2, icon: HeadsetIcon },
    { key: 3, icon: FileX },
  ];

  // Force the translation to be treated as an array
  const benefits = t('landing.whyChoose.benefits', { returnObjects: true }) as any[];

  return (
    <section className="py-28 px-4 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl font-bold mb-6 text-[#221F26]">{t('landing.whyChoose.title')}</h2>
          <p className="text-xl text-[#403E43]/80 leading-relaxed">
            {t('landing.whyChoose.subtitle')}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto mb-16">
          {benefits.map((benefit, index) => {
            const IconComponent = benefitIcons[index].icon;
            
            return (
              <div 
                key={index} 
                className="flex items-start p-6 rounded-xl bg-white border border-gray-100 shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="mr-5 mt-1 bg-primary/10 p-3 rounded-lg">
                  <IconComponent className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-[#221F26] mb-2">
                    {benefit.title}
                  </h3>
                  <p className="text-[#403E43] leading-relaxed">
                    {benefit.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="text-center">
          <p className="text-xl font-medium text-gray-800 mb-6">
            {t('landing.whyChoose.tagline')}
          </p>
          <Button 
            onClick={scrollToPricing}
            className="bg-primary hover:bg-primary/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
          >
            {t('landing.whyChoose.cta')}
          </Button>
        </div>
      </div>
    </section>
  );
};
