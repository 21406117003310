import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { Task, UserTask } from "./todo/types";
import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { format, addMonths, isSameMonth, isPast } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export const TodoList = () => {
  const { t } = useTranslation();

  const { data: session } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      return session;
    },
  });

  const { data: profile } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      //const arrayObject = [{"id":"528f8288-4181-4139-8ceb-865560d6e037","user_id":"9ff2a196-20c0-4332-921c-c5c535659a4d","business_name":"codius technologies","website_url":null,"opening_date":null,"street_address":"Hissar Ambala Road","city":"Pehowa","state":"Haryana","zip_code":"132128","category":"codius technologies","phone_number":"08528343038","opening_hours":"9:00-17:00","desired_keywords":null,"promotions":null,"payment_methods":null,"seo_campaign_goal":"To be defined","created_at":"2024-12-17T06:46:06.768047+00:00","updated_at":"2024-12-17T06:46:06.768047+00:00","business_email":null,"opening_hours_json":{},"short_description":null,"long_description":null,"facebook_url":null,"instagram_url":null,"business_services":[],"admin_notes":null,"last_trends_check":null,"dashboard_url":null,"logo_url":null,"square_logo_url":null,"primary_image_url":null}];
      const arrayObjectQury =  await testQuery1();
      const arrayObjectJson = JSON.parse(JSON.stringify(arrayObjectQury, null, 2));
      const arrayObject = [ arrayObjectJson.data];


      console.log("testQueryHkam123:   ",arrayObject);
      // Extract the first object from the array
      const { data = arrayObject[0], error = null } = arrayObject.length > 0 ? { data: arrayObject[0], error: null } : { data: null, error: "No data found" };
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user?.id,
  });
  async function testQuery1() {
    try {
      alert(authToken.user.id)
              // Retrieve the auth token from localStorage
              var authTokenString = localStorage.getItem('sb-bzyguzdxfvnzcddghqfl-auth-token');

              // Check if the auth token exists and parse it to an object
              if (authTokenString) {
                var authToken = JSON.parse(authTokenString);
                // Now you can safely access the user property
                console.log('User:', authToken.user.id);
                const supabaseUrl = "https://bzyguzdxfvnzcddghqfl.supabase.co";
                const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ6eWd1emR4ZnZuemNkZGdocWZsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM4NjczOTksImV4cCI6MjA0OTQ0MzM5OX0.tW7_0ToMEHJe-ROPvPJjI1ThtkAkt92dYxuhXnAh6aM";
                const tableName = "profiles";
                const userId = authToken.user.id; // Replace with dynamic ID
            
                const headers = {
                  "Content-Type": "application/json",
                  "apikey": supabaseKey,
                  "Authorization": `Bearer ${supabaseKey}`,
                };
            
                const url = `${supabaseUrl}/rest/v1/${tableName}?id=eq.${userId}`;
            
                const response = await fetch(url, { method: "GET", headers: headers });
                const data = await response.json(); // ✅ Wait for JSON conversion
            
                const transformedObject = {
                  data: data.length > 0 ? data[0] : null,
                  error: null,
                };
            
                console.log("[testQuery] Profile fetched:", transformedObject);
                return transformedObject; // ✅ Return data
                        } else {
                          console.log('Auth token not found');
                        }
                
            
              } catch (err) {
                console.error("[testQuery] Unexpected error:", err);
                return { data: null, error: err };
              }
  }

  // Query to check if user has an active subscription
  const { data: subscription } = useQuery({
    queryKey: ['subscription', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', session.user.id)
        .eq('status', 'active')
        .maybeSingle();
      if (error && error.code !== 'PGRST116') throw error;
      return data;
    },
    enabled: !!session?.user?.id,
  });

  const { data: userTasks, isLoading, error } = useQuery({
    queryKey: ['userTasks', "9ff2a196-20c0-4332-921c-c5c535659a4d"],
    queryFn: async () => {
      if (!session?.user?.id) return [];
      
      // First, update tasks that are past their due date to completed
      const { error: updateError } = await supabase
        .from('user_tasks')
        .update({ 
          status: 'completed',
          completed_at: new Date().toISOString()
        })
        .eq('user_id', session.user.id)
        .eq('status', 'in_progress')
        .lt('due_date', new Date().toISOString());

      if (updateError) {
        console.error('Error updating overdue tasks:', updateError);
      }
      
      const { data: tasks, error } = await supabase
        .from('user_tasks')
        .select(`
          *,
          task:tasks (*)
        `)
        .eq('user_id', session.user.id)
        .order('due_date', { ascending: true });

      if (error) {
        console.error('Error fetching tasks:', error);
        throw error;
      }

      // Create a Map to store unique tasks, using task title as the key
      const uniqueTasksMap = new Map();
      tasks.forEach((task: UserTask) => {
        const taskTitle = task.task?.title;
        
        // Special handling for "Create an account" task
        if (taskTitle === 'Create an account') {
          task.status = 'completed';
        }
        
        // Special handling for "Signup for a membership" task
        if (taskTitle === 'Signup for a membership') {
          task.status = subscription ? 'completed' : 'in_progress';
        }
        
        // Mark task as completed if due date has passed
        if (task.due_date && isPast(new Date(task.due_date)) && task.status === 'in_progress') {
          task.status = 'completed';
        }
        
        // Only store the most recent task for each unique title
        if (!uniqueTasksMap.has(taskTitle) || 
            new Date(task.created_at) > new Date(uniqueTasksMap.get(taskTitle).created_at)) {
          uniqueTasksMap.set(taskTitle, task);
        }
      });

      // Convert the Map back to an array
      return Array.from(uniqueTasksMap.values()) as UserTask[];
    },
    enabled: !!session?.user?.id,
    refetchInterval: 60000, // Refetch every minute to check for status updates
  });

  if (isLoading) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">{t('common.loading')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-500">
        <p>{t('common.error')}</p>
      </div>
    );
  }

  if (!userTasks?.length) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">{t('dashboard.noTasks')}</p>
      </div>
    );
  }

  // Calculate which month's tasks to show based on profile creation date
  const userCreationDate = new Date(profile?.created_at || new Date());
  const currentDate = new Date();
  const monthsSinceCreation = Math.floor(
    (currentDate.getTime() - userCreationDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
  );
  const targetMonth = addMonths(userCreationDate, monthsSinceCreation);

  // Filter tasks for the current month based on due_date
  const currentMonthTasks = userTasks?.filter(task => 
    task.due_date && isSameMonth(new Date(task.due_date), targetMonth)
  );

  const renderTaskTitle = (userTask: UserTask) => {
    if (userTask.task?.title === 'Connect Google Business Profile') {
      return (
        <Link to="/integrations" className="text-[#F97316] hover:underline">
          {userTask.task.title}
        </Link>
      );
    }
    return userTask.task?.title;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'in_progress':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-blue-100 text-blue-800'; // Default to in_progress styling
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'completed':
        return 'Completed';
      default:
        return 'In Progress'; // Default to "In Progress" for all non-completed tasks
    }
  };

  return (
    <Card className="p-6">
      <h2 className="text-xl font-semibold mb-4">
        What we're working on:
      </h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-12"></TableHead>
            <TableHead>Task</TableHead>
            <TableHead className="w-32">Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {currentMonthTasks?.map((userTask) => (
            <TableRow key={userTask.id}>
              <TableCell>
                <Checkbox
                  checked={userTask.status === 'completed'}
                  disabled
                />
              </TableCell>
              <TableCell>
                <div>
                  <h3 className={`font-medium ${userTask.status === 'completed' ? 'line-through text-gray-500' : ''}`}>
                    {renderTaskTitle(userTask)}
                  </h3>
                  <p className={`text-sm ${userTask.status === 'completed' ? 'text-gray-400' : 'text-gray-500'}`}>
                    {userTask.task?.description}
                  </p>
                </div>
              </TableCell>
              <TableCell>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(userTask.status)}`}>
                  {getStatusText(userTask.status)}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};