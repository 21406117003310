
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { ExternalLink, Video } from "lucide-react";
import { useState } from "react";

interface PartnerVideo {
  id: string;
  title: string;
  description: string | null;
  video_url: string;
}

export const PartnerVideos = () => {
  const [videoErrors, setVideoErrors] = useState<Record<string, boolean>>({});
  
  const { data: videos, isLoading } = useQuery({
    queryKey: ['partner_videos'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('partner_videos')
        .select('*');
      if (error) throw error;
      return data as PartnerVideo[];
    },
  });

  const handleVideoError = (videoId: string) => {
    setVideoErrors(prev => ({ ...prev, [videoId]: true }));
    console.error(`YouTube video ${videoId} failed to load. Check Content Security Policy`);
  };

  // Extract YouTube video ID from URL
  const getYoutubeVideoId = (url: string) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  if (isLoading) {
    return (
      <section>
        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Video className="h-5 w-5 text-[#F97316]" />
          Sales Videos
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {Array(3).fill(0).map((_, i) => (
            <Card key={i} className="p-6 animate-pulse">
              <div className="aspect-video bg-gray-200 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-3 bg-gray-200 rounded w-1/2"></div>
            </Card>
          ))}
        </div>
      </section>
    );
  }

  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
        <Video className="h-5 w-5 text-[#F97316]" />
        Sales Videos
      </h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {videos?.map((video) => {
          const videoId = getYoutubeVideoId(video.video_url);
          const watchUrl = videoId ? `https://www.youtube.com/watch?v=${videoId}` : video.video_url;
          
          return (
            <Card key={video.id} className="overflow-hidden">
              <div className="aspect-video">
                {!videoErrors[video.id] ? (
                  <iframe
                    src={video.video_url}
                    title={video.title}
                    className="w-full h-full"
                    allowFullScreen
                    onError={() => handleVideoError(video.id)}
                    sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                    <a 
                      href={watchUrl}
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-primary hover:text-primary/80"
                    >
                      <ExternalLink className="h-4 w-4" />
                      Watch on YouTube
                    </a>
                  </div>
                )}
              </div>
              <div className="p-4">
                <h3 className="font-semibold text-gray-900 mb-2">{video.title}</h3>
                <p className="text-sm text-gray-500">{video.description}</p>
              </div>
            </Card>
          );
        })}
      </div>
    </section>
  );
};
