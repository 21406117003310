
import React, { useEffect, useRef, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { LoadingSpinner } from "@/components/routing/LoadingSpinner";

interface AuditWidgetProps {
  widgetId: string;
  height?: string;
}

export const AuditWidget = ({ widgetId, height = "650px" }: AuditWidgetProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isScriptError, setIsScriptError] = useState(false);
  
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = "https://www.local-marketing-reports.com/m/assets-v2/lead-gen/js/external/widget-builder.js";
    script.async = true;
    script.setAttribute('data-widget-id', widgetId);
    
    // Handle script loading events
    script.onload = () => {
      setIsScriptLoaded(true);
    };
    
    script.onerror = () => {
      setIsScriptError(true);
    };
    
    // Append script to container
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
    
    // Cleanup function
    return () => {
      if (containerRef.current && containerRef.current.contains(script)) {
        containerRef.current.removeChild(script);
      }
    };
  }, [widgetId]);
  
  if (isScriptError) {
    return (
      <div className="text-center p-6 bg-gray-100 rounded-lg">
        <p className="text-red-500 font-medium">Failed to load the audit widget. Please try again later.</p>
      </div>
    );
  }
  
  return (
    <div className="relative w-full" style={{ height }}>
      {!isScriptLoaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50 rounded-lg">
          <div className="space-y-4">
            <Skeleton className="h-8 w-[250px] mx-auto" />
            <Skeleton className="h-6 w-[200px] mx-auto" />
            <Skeleton className="h-4 w-[180px] mx-auto" />
          </div>
        </div>
      )}
      <div ref={containerRef} className="w-full h-full"></div>
    </div>
  );
};
