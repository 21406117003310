
export const businessprofile = {
  title: "Perfil del Negocio",
  subtitle: "Administra los detalles y la presencia de tu negocio",
  form: {
    businessName: "Nombre del Negocio",
    businessEmail: "Correo Electrónico del Negocio",
    websiteUrl: "URL del Sitio Web",
    phoneNumber: "Número de Teléfono",
    category: "Categoría",
    openingDate: "Fecha de Apertura",
    openingHours: "Horario de Apertura",
    streetAddress: "Dirección",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    shortDescription: "Breve descripción de tu negocio",
    longDescription: "Descripción detallada de tu negocio",
    facebookUrl: "URL de Facebook",
    instagramUrl: "URL de Instagram",
    businessServices: "Ingresa los servicios separados por comas",
    seoCampaignGoal: "Meta de la Campaña SEO",
    desiredKeywords: "Palabras Clave Deseadas",
    keywordsPlaceholder: "Ingresa palabras clave separadas por comas",
    keywordsHelp: "Cada palabra clave debe estar en una nueva línea o separada por comas",
    paymentMethods: "Métodos de Pago",
    paymentMethodsPlaceholder: "Ingresa métodos de pago separados por comas",
    promotions: "Promociones",
    promotionsPlaceholder: "Ingresa tus promociones",
    seoTitle: "Información SEO",
    businessImages: "Imágenes del Negocio",
    uploadInstructions: "Sube el logotipo e imágenes de tu negocio. Formatos soportados: JPG, JPEG, PNG",
    businessLogo: "Logotipo del Negocio",
    uploadNewImage: "Subir Nueva Imagen",
    squareLogo: "Logotipo Cuadrado",
    primaryHeaderImage: "Imagen Principal de Encabezado",
    saveChanges: "Guardar Cambios",
    saving: "Guardando...",
    success: "Perfil de negocio actualizado con éxito",
    error: "Error al actualizar el perfil del negocio"
  },
  website_credentials: {
    title: "Credenciales de Administrador del Sitio Web",
    description: "Almacena de forma segura tus credenciales de administrador del sitio web",
    add_new: "Agregar Nueva Credencial",
    add_title: "Agregar Nueva Credencial de Sitio Web",
    edit_title: "Editar Credencial de Sitio Web",
    admin_url: "URL de Administrador",
    username: "Nombre de Usuario",
    password: "Contraseña",
    new_password_optional: "Nueva Contraseña (dejar en blanco para mantener la existente)",
    leave_blank: "Dejar en blanco para mantener la contraseña actual",
    enter_password: "Ingresa la contraseña",
    expiration_date: "Fecha de Vencimiento (opcional)",
    notes: "Notas",
    notes_placeholder: "Agrega notas adicionales o información relevante",
    last_updated: "Última Actualización",
    expiration: "Vencimiento",
    no_expiration: "Sin vencimiento",
    view_notes: "Ver Notas",
    no_notes: "Sin notas",
    confirm_delete: "Confirmar Eliminación",
    delete_warning: "¿Estás seguro de que deseas eliminar esta credencial? Esta acción no se puede deshacer.",
    added: "Credencial Agregada",
    add_success: "La credencial del sitio web ha sido agregada exitosamente.",
    updated: "Credencial Actualizada",
    update_success: "La credencial del sitio web ha sido actualizada exitosamente.",
    deleted: "Credencial Eliminada",
    delete_success: "La credencial del sitio web ha sido eliminada exitosamente.",
    error: "Error",
    add_error: "Ocurrió un error al agregar la credencial. Por favor, intenta de nuevo.",
    update_error: "Ocurrió un error al actualizar la credencial. Por favor, intenta de nuevo.",
    delete_error: "Ocurrió un error al eliminar la credencial. Por favor, intenta de nuevo.",
    load_error: "Error al cargar las credenciales",
    no_credentials: "No hay credenciales agregadas. Haz clic en 'Agregar Nueva Credencial' para comenzar.",
    copied: "{type} copiado al portapapeles",
    copy_username: "Copiar nombre de usuario"
  }
};
