
import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { Receipt } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@/components/ui/skeleton";

export const PaymentHistory = () => {
  const { t } = useTranslation();
  
  // Get payment transactions for current user
  const { data: transactions, isLoading, error } = useQuery({
    queryKey: ['payment-transactions'],
    queryFn: async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error("Not authenticated");

        // Get transaction history for the user
        const { data, error } = await supabase
          .from('payment_transactions')
          .select('*')
          .eq('user_id', user.id)
          .eq('user_type', 'profile')
          .order('created_at', { ascending: false })
          .limit(10);

        if (error) throw error;
        return data || [];
      } catch (err) {
        console.error("Error fetching payment history:", err);
        return [];
      }
    }
  });

  const formatDate = (dateString: string) => {
    try {
      // Validate date before formatting
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "Invalid date";
      }
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid date";
    }
  };

  if (isLoading) {
    return (
      <Card className="p-6 bg-white shadow-sm border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-3 rounded-full bg-primary/10">
            <Receipt className="h-6 w-6 text-primary" />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">{t('billing.paymentHistory.title')}</h2>
            <p className="text-sm text-gray-500">{t('billing.paymentHistory.subtitle')}</p>
          </div>
        </div>
        <Skeleton className="h-40 w-full" />
      </Card>
    );
  }

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <Receipt className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{t('billing.paymentHistory.title')}</h2>
          <p className="text-sm text-gray-500">{t('billing.paymentHistory.subtitle')}</p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b border-gray-100">
              <th className="pb-3 text-sm font-medium text-gray-500">{t('billing.paymentHistory.date')}</th>
              <th className="pb-3 text-sm font-medium text-gray-500">{t('billing.paymentHistory.amount')}</th>
              <th className="pb-3 text-sm font-medium text-gray-500">{t('billing.paymentHistory.status')}</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {transactions && transactions.length > 0 ? (
              transactions.map((payment) => (
                <tr key={payment.id}>
                  <td className="py-4 text-gray-900">{formatDate(payment.created_at)}</td>
                  <td className="py-4 text-gray-900">${payment.amount}</td>
                  <td className="py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      payment.status === 'completed' || payment.status === 'paid' ? 
                        'bg-green-100 text-green-800' : 
                        'bg-yellow-100 text-yellow-800'
                    }`}>
                      {payment.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="py-4 text-center text-gray-500">
                  {t('billing.paymentHistory.noPayments', 'No payment history available')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
