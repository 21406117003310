
import { common } from "./en/common";
import { dashboard } from "./en/dashboard";
import { landing } from "./en/landing";
import { settings } from "./en/settings";
import { businessprofile } from "./en/businessprofile";
import { googleTrends } from "./en/googletrends";
import { integrations } from "./en/integrations";
import { billing } from "./en/billing";
import { account } from "./en/account";
import { resources } from "./en/resources";
import { videos } from "./en/videos";

export const enTranslations = {
  common,
  dashboard,
  landing,
  settings,
  business: businessprofile,
  googletrends: googleTrends,
  integrations,
  billing,
  account,
  resources,
  videos
};
