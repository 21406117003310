
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Textarea } from "@/components/ui/textarea";
import { useTranslation } from "react-i18next";
import { useState, useRef, FormEvent } from "react";
import { toast } from "sonner";
import { sanitizeInput } from "@/utils/securityUtils";

export const ZohoContactForm = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState("no");
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Get form data
      const formData = new FormData(e.target as HTMLFormElement);
      // Sanitize all inputs to prevent XSS
      const sanitizedData: Record<string, string> = {};
      formData.forEach((value, key) => {
        sanitizedData[key] = sanitizeInput(value.toString());
      });
      
      // Include customer status
      sanitizedData.isExistingCustomer = isExistingCustomer;
      
      // Simulate form submission
      await new Promise((resolve) => setTimeout(resolve, 1500));
      
      // Show success message
      toast.success(t('landing.contact.successTitle'), {
        description: t('landing.contact.successMessage'),
      });
      
      // Reset form
      if (formRef.current) {
        formRef.current.reset();
      }
      setIsExistingCustomer("no");
    } catch (error) {
      // Show error message
      toast.error(t('landing.contact.errorTitle'), {
        description: t('landing.contact.errorMessage'),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label htmlFor="name">{t('landing.contact.nameLabel')}</Label>
          <Input 
            id="name" 
            name="name" 
            placeholder={t('landing.contact.namePlaceholder')} 
            required 
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="email">{t('landing.contact.emailLabel')}</Label>
          <Input 
            id="email" 
            name="email" 
            type="email" 
            placeholder={t('landing.contact.emailPlaceholder')} 
            required 
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="phone">{t('landing.contact.phoneLabel')}</Label>
          <Input 
            id="phone" 
            name="phone" 
            type="tel" 
            placeholder={t('landing.contact.phonePlaceholder')} 
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="business">{t('landing.contact.businessNameLabel')}</Label>
          <Input 
            id="business" 
            name="business" 
            placeholder={t('landing.contact.businessNamePlaceholder')} 
            required 
          />
        </div>
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="message">{t('landing.contact.messageLabel')}</Label>
        <Textarea 
          id="message" 
          name="message" 
          placeholder={t('landing.contact.messagePlaceholder')} 
          rows={5} 
          required 
        />
      </div>
      
      <div className="space-y-2">
        <Label>{t('landing.contact.customerStatusLabel')}</Label>
        <RadioGroup 
          value={isExistingCustomer} 
          onValueChange={setIsExistingCustomer}
          className="flex space-x-4"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="yes" id="customer-yes" />
            <Label htmlFor="customer-yes" className="cursor-pointer">
              {t('landing.contact.customerStatusYes')}
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="no" id="customer-no" />
            <Label htmlFor="customer-no" className="cursor-pointer">
              {t('landing.contact.customerStatusNo')}
            </Label>
          </div>
        </RadioGroup>
      </div>
      
      <Button 
        type="submit" 
        className="w-full bg-primary hover:bg-primary/90" 
        disabled={isSubmitting}
      >
        {isSubmitting ? t('landing.contact.submitting') : t('landing.contact.submit')}
      </Button>
    </form>
  );
};
