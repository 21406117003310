import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { BillingInfoFields } from "./payment/BillingInfoFields";
import { CardFields } from "./payment/CardFields";
import { PaymentFormHeader } from "./payment/PaymentFormHeader";
import { Input } from "@/components/ui/input";
import { usePartnerCode } from "./payment/usePartnerCode";
import { usePaymentSubmission } from "@/hooks/usePaymentSubmission";
import { AlertCircle, Shield } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { sanitizeInput } from "@/utils/securityUtils";

interface PaymentFormProps {
  packageType: "maps_seo" | "maps_website_seo";
  amount: number;
  onPaymentStart: (refId: string) => void;
  isSignupFlow?: boolean;
  customerInfo?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
  };
  businessInfo?: {
    name: string;
    category: string;
    websiteUrl?: string;
    phoneNumber?: string;
  };
}

export const PaymentForm = ({ 
  packageType, 
  amount, 
  onPaymentStart,
  isSignupFlow = false,
  customerInfo,
  businessInfo
}: PaymentFormProps) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingInfo, setBillingInfo] = useState({
    firstName: sanitizeInput(customerInfo?.firstName || ""),
    lastName: sanitizeInput(customerInfo?.lastName || ""),
    email: sanitizeInput(customerInfo?.email || ""),
    phoneNumber: sanitizeInput(customerInfo?.phoneNumber || ""),
    street: sanitizeInput(customerInfo?.street || ""),
    city: sanitizeInput(customerInfo?.city || ""),
    state: sanitizeInput(customerInfo?.state || ""),
    zipCode: sanitizeInput(customerInfo?.zipCode || ""),
  });
  const [secureConnection, setSecureConnection] = useState(true);

  useEffect(() => {
    if (window.location.protocol !== 'https:' && !window.location.hostname.includes('localhost')) {
      setSecureConnection(false);
      console.error('Insecure connection detected for payment form!');
    }
  }, []);

  useEffect(() => {
    if (isSignupFlow) {
      try {
        const tempUserId = sessionStorage.getItem('temp_user_id');
        const paymentRefId = sessionStorage.getItem('payment_ref_id');
        
        if (tempUserId && paymentRefId) {
          console.log('Found previous payment info in session storage:', {
            tempUserId,
            paymentRefId
          });
          // Could use this to recover from a previous incomplete signup
        }
      } catch (error) {
        console.error('Error accessing session storage:', error);
      }
    }
  }, [isSignupFlow]);

  const { 
    partnerCode, 
    discountedAmount, 
    isValidatingCode, 
    handlePartnerCodeChange,
    validatePartnerCode,
    isFreeTrialCode,
    trialDurationMonths,
    postTrialDiscountPercentage
  } = usePartnerCode(amount, packageType);

  const { handlePayment, isProcessing } = usePaymentSubmission(
    packageType,
    discountedAmount || amount,
    (refId: string) => {
      onPaymentStart(refId);
    },
    isSignupFlow,
    isFreeTrialCode,
    trialDurationMonths,
    postTrialDiscountPercentage,
    partnerCode
  );

  const handleBillingInfoChange = (field: string, value: string) => {
    setBillingInfo(prev => ({
      ...prev,
      [field]: sanitizeInput(value)
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!secureConnection && !window.location.hostname.includes('localhost')) {
      console.error('Blocked payment submission on insecure connection');
      return;
    }
    
    const sanitizedBusinessInfo = businessInfo ? {
      name: sanitizeInput(businessInfo.name),
      category: sanitizeInput(businessInfo.category),
      websiteUrl: businessInfo.websiteUrl ? sanitizeInput(businessInfo.websiteUrl) : undefined,
      phoneNumber: businessInfo.phoneNumber ? sanitizeInput(businessInfo.phoneNumber) : undefined
    } : undefined;
    
    await handlePayment({
      cardNumber,
      expiryDate,
      cvv,
      billingInfo,
      businessInfo: sanitizedBusinessInfo
    });
  };

  const postTrialAmount = postTrialDiscountPercentage 
    ? Number((amount * (1 - postTrialDiscountPercentage / 100)).toFixed(2)) 
    : null;

  if (!secureConnection && !window.location.hostname.includes('localhost')) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          This payment form requires a secure connection (HTTPS). Please refresh the page or contact support.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="text-center mb-8">
          <div className="flex items-center justify-center mb-2">
            <Shield className="w-6 h-6 text-primary mr-2" />
            <h2 className="text-xl font-semibold">Secure Checkout</h2>
          </div>
          <p className="text-sm text-gray-500">Please enter your billing information below that matches your card.</p>
        </div>

        <BillingInfoFields
          billingInfo={billingInfo}
          onChange={handleBillingInfoChange}
          isSubmitting={isProcessing}
        />
        
        {isFreeTrialCode && (
          <Alert className="bg-blue-50 border-blue-100">
            <AlertCircle className="h-4 w-4 text-blue-600" />
            <AlertDescription className="text-blue-700">
              {postTrialAmount 
                ? `You're signing up for a ${trialDurationMonths}-month free trial of the ${packageType === 'maps_seo' ? 'Maps SEO' : 'Maps & Website SEO'} package. After your trial, you'll be charged $${postTrialAmount}/month. Please provide your payment details for verification and future billing purposes.`
                : `You're signing up for a free trial. Please provide your payment details for verification purposes only. You won't be charged during the trial period.`
              }
            </AlertDescription>
          </Alert>
        )}

        <CardFields
          cardNumber={cardNumber}
          expiryDate={expiryDate}
          cvv={cvv}
          onChange={{
            setCardNumber,
            setExpiryDate,
            setCvv
          }}
          isSubmitting={isProcessing}
        />

        <div className="space-y-2">
          <label htmlFor="partnerCode" className="text-sm font-medium">
            Partner Code
          </label>
          <div className="flex gap-2">
            <Input
              id="partnerCode"
              value={partnerCode}
              onChange={(e) => handlePartnerCodeChange(e.target.value)}
              placeholder="Enter partner code"
              className="flex-1"
              disabled={isValidatingCode}
            />
            <Button
              type="button"
              variant="outline"
              onClick={() => validatePartnerCode(partnerCode)}
              disabled={isValidatingCode || !partnerCode}
            >
              {isValidatingCode ? "Validating..." : "Apply"}
            </Button>
          </div>
        </div>

        {discountedAmount !== null && (
          <div className="p-4 bg-green-50 rounded-md space-y-2">
            <div className="flex items-center justify-between text-green-600">
              <span className="font-medium">Partner Code Applied!</span>
              <span>${amount - discountedAmount} discount</span>
            </div>
            {postTrialDiscountPercentage && isFreeTrialCode ? (
              <>
                <div className="flex items-center justify-between text-green-700 font-medium">
                  <span>{trialDurationMonths}-Month Free Trial</span>
                  <span>$0</span>
                </div>
                <div className="flex items-center justify-between text-gray-600">
                  <span>After trial ({postTrialDiscountPercentage}% off):</span>
                  <span>${postTrialAmount}/month</span>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-between text-green-700 font-medium">
                <span>Monthly Total:</span>
                <span>${discountedAmount}</span>
              </div>
            )}
          </div>
        )}

        <Button 
          type="submit" 
          className="w-full"
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : 
            isFreeTrialCode ? `Start ${trialDurationMonths}-Month Free Trial` : `Pay $${discountedAmount || amount} per month`}
        </Button>

        <div className="text-center text-sm text-gray-500 mt-4">
          <p>Your payment is always safe & secure.</p>
          <div className="flex justify-center gap-4 mt-2">
            <img src="/lovable-uploads/11f6cce7-2c1a-48b8-9c9d-45e5f389676d.png" alt="Visa" className="h-6" />
            <img src="/lovable-uploads/8c1b15b2-a031-4900-92de-60e46132ff20.png" alt="Mastercard" className="h-6" />
            <img src="/lovable-uploads/af9096dd-e781-41ae-9933-d19a0ee95e4c.png" alt="American Express" className="h-6" />
          </div>
        </div>
      </form>
    </Card>
  );
};
