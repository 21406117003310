import { useState } from "react";
import { LogOut } from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarFooter,
} from "@/components/ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from "./LanguageSelector";
import { useQuery } from "@tanstack/react-query";
import { Button } from "./ui/button";
import { useToast } from "@/hooks/use-toast";
import { baseMenuItems, adminMenuItem, partnerMenuItem, reportsMenuItem } from "./sidebar/menuItems";
import { authOperations } from "@/contexts/auth/authOperations";
import { supabase } from "@/integrations/supabase/client";

const formatMenuTitle = (key: string): string => {
  const specialCases: Record<string, string> = {
    myAccount: "My Account",
    businessProfile: "Business Profile",
    googleTrends: "Google Trends",
    adminPanel: "Admin Panel"
  };

  if (specialCases[key]) {
    return specialCases[key];
  }

  return key.charAt(0).toUpperCase() + key.slice(1);
};

export function AppSidebar() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { toast } = useToast();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const { data: profile, isLoading: profileLoading } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) {
          console.log('No authenticated user session found');
          return null;
        }

        const userId = session.user.id;
        console.log('Fetching profile for user:', userId);
        
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', userId)
          .single();
          
        if (error) {
          console.error('Error fetching profile:', error);
          throw error;
        }
        
        console.log('Profile data fetched:', data);
        
        const { data: businessProfile, error: businessError } = await supabase
          .from('business_profiles')
          .select('dashboard_url')
          .eq('user_id', userId)
          .single();
          
        if (businessError && businessError.code !== 'PGRST116') {
          console.error('Error fetching business profile:', businessError);
        }
        
        return {
          ...data,
          dashboard_url: businessProfile?.dashboard_url || null
        };
      } catch (err) {
        console.error('Error in profile query:', err);
        return null;
      }
    },
  });

  const handleLogout = async () => {
    if (isLoggingOut) return;
    
    setIsLoggingOut(true);
    try {
      await authOperations.signOut();
      toast({
        title: t('common:logout'),
        description: t('common:logoutSuccess'),
      });
    } catch (error) {
      console.error('Logout error:', error);
      toast({
        title: "Error",
        description: "Failed to log out. Please try again.",
        variant: "destructive",
      });
      setIsLoggingOut(false);
    }
  };

  const menuItems = [...baseMenuItems];
  
  const dashboardIndex = menuItems.findIndex(item => item.titleKey === "dashboard");
  const insertPos = dashboardIndex !== -1 ? dashboardIndex + 1 : 0;
  menuItems.splice(insertPos, 0, reportsMenuItem);
  
  if (profile?.is_partner || profile?.is_admin) {
    menuItems.push(partnerMenuItem);
  }
  
  if (profile?.is_admin) {
    menuItems.push(adminMenuItem);
  }

  const isActive = (url: string) => {
    if (url.startsWith('#')) return false;
    return location.pathname === url;
  };

  if (profileLoading) {
    return (
      <Sidebar className="border-r border-gray-100 bg-white">
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      </Sidebar>
    );
  }

  return (
    <Sidebar className="border-r border-gray-100 bg-white">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel className="px-6 py-4 mt-4">
            <img 
              src="https://woobound.com/wp-content/uploads/2024/12/woobound-marketing-logo-black-250.png" 
              alt="Woobound Marketing"
              className="h-8 mb-8"
            />
          </SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item, index) => (
                item.divider ? (
                  <hr key={index} className="my-4 border-gray-100" />
                ) : (
                  <SidebarMenuItem key={item.titleKey} className="px-3">
                    <SidebarMenuButton asChild>
                      <Link 
                        to={item.url!} 
                        className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors duration-200 ${
                          isActive(item.url!)
                            ? 'bg-orange-50 text-[#F97316] hover:bg-orange-100'
                            : 'text-[#8E9196] hover:bg-gray-50 hover:text-gray-900'
                        }`}
                      >
                        {item.icon && <item.icon className={`h-5 w-5 ${
                          isActive(item.url!)
                            ? 'text-[#F97316]'
                            : 'text-gray-500'
                        }`} />}
                        <span className="text-sm font-medium">
                          {formatMenuTitle(t(item.titleKey!, { ns: 'common' }))}
                        </span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="p-4 mt-auto border-t border-gray-100">
        <Button
          variant="ghost"
          className="w-full mb-4 text-[#8E9196] hover:text-gray-900 hover:bg-gray-50"
          onClick={handleLogout}
          disabled={isLoggingOut}
        >
          <LogOut className="w-5 h-5 mr-2" />
          {isLoggingOut ? "Logging out..." : t('logout', { ns: 'common' })}
        </Button>
        <LanguageSelector />
      </SidebarFooter>
    </Sidebar>
  );
}
