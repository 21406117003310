
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Loader2 } from 'lucide-react';
import { formatCurrency } from '@/lib/utils';
import { useState } from 'react';
import { DatePickerWithRange } from '@/components/ui/date-range-picker';
import { DateRange } from 'react-day-picker';
import { addMonths, startOfMonth, endOfMonth } from 'date-fns';

export function PartnerCodesList() {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startOfMonth(addMonths(new Date(), -3)),
    to: endOfMonth(new Date())
  });

  const { data: partnerCodes, isLoading, error } = useQuery({
    queryKey: ['partner-codes-analytics', dateRange],
    queryFn: async () => {
      if (!dateRange.from || !dateRange.to) return [];
      
      const { data, error } = await supabase
        .rpc('get_partner_code_analytics', {
          from_date: dateRange.from.toISOString(),
          to_date: dateRange.to.toISOString()
        });
      
      if (error) {
        console.error('Error fetching partner codes:', error);
        throw error;
      }
      return data || [];
    },
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          Error loading partner codes: {error instanceof Error ? error.message : 'Unknown error'}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-4 mb-4">
        <h3 className="text-lg font-semibold">Partner Codes Overview</h3>
        <DatePickerWithRange 
          date={dateRange}
          onDateChange={setDateRange}
        />
      </div>
      
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Code</TableHead>
            <TableHead>Commission %</TableHead>
            <TableHead>Total Uses</TableHead>
            <TableHead>Revenue (Before Discount)</TableHead>
            <TableHead>Revenue (After Discount)</TableHead>
            <TableHead>Unique Customers</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {partnerCodes?.length > 0 ? (
            partnerCodes.map((code) => (
              <TableRow key={code.code}>
                <TableCell className="font-medium">{code.code}</TableCell>
                <TableCell>{code.commission_percentage}%</TableCell>
                <TableCell>{code.total_uses}</TableCell>
                <TableCell>{formatCurrency(code.total_revenue_before_discount)}</TableCell>
                <TableCell>{formatCurrency(code.total_revenue_after_discount)}</TableCell>
                <TableCell>{code.unique_customers}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center py-4">
                No partner codes found for the selected date range
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
