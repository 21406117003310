
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { differenceInDays } from "date-fns";
import { Card } from "@/components/ui/card";
import { X, Hand } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useState } from "react";

export const GettingStarted = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [videoError, setVideoError] = useState(false);

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const dismissMutation = useMutation({
    mutationFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) throw new Error('No session found');

      const { error } = await supabase
        .from('profiles')
        .update({ getting_started_dismissed_at: new Date().toISOString() })
        .eq('id', session.user.id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      toast({
        title: "Getting Started section dismissed",
        description: "You can find helpful resources in the Resources section",
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to dismiss the Getting Started section",
        variant: "destructive",
      });
    },
  });

  // If no profile or created_at date, don't show the section
  if (!profile?.created_at) return null;

  // Calculate days since account creation
  const daysSinceCreation = differenceInDays(
    new Date(),
    new Date(profile.created_at)
  );

  // Don't show if more than 30 days old or if dismissed
  if (daysSinceCreation > 30 || profile.getting_started_dismissed_at) return null;

  const handleDismiss = () => {
    dismissMutation.mutate();
  };

  const handleVideoError = () => {
    setVideoError(true);
    console.error("YouTube video failed to load. Check Content Security Policy");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gradient-to-r from-orange-50 to-orange-100 border border-orange-200 rounded-lg relative">
      {/* Welcome Message Column */}
      <Card className="p-6 bg-transparent border-0 shadow-none">
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 md:hidden"
          onClick={handleDismiss}
        >
          <X className="h-4 w-4" />
        </Button>

        <div className="space-y-4">
          <div className="flex items-start gap-2">
            <Hand className="h-5 w-5 text-orange-500 mt-1 flex-shrink-0" />
            <h2 className="text-xl font-semibold text-gray-900">Getting Started with Woobound</h2>
          </div>
          
          <p className="text-gray-600">
            Welcome to Woobound! We're excited to help you grow your business online. 
            Watch this introduction video to learn how to make the most of your account.
          </p>

          <div className="mt-4 p-4 bg-white/50 rounded-lg border border-orange-200">
            <p className="text-gray-700">
              <strong>Next Steps:</strong> Our account manager will be reaching out to you in the next 1-2 business days 
              to review your business information. Once we have all your details, our team will get to work!
            </p>
          </div>

          <div className="text-xs text-gray-500 mt-auto">
            This guide will be available for {30 - daysSinceCreation} more days.
          </div>
        </div>
      </Card>

      {/* Video Column */}
      <Card className="p-6 bg-transparent border-0 shadow-none relative">
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 hidden md:flex"
          onClick={handleDismiss}
        >
          <X className="h-4 w-4" />
        </Button>

        <div className="h-full flex flex-col">
          <div className="aspect-video flex-1 rounded-lg overflow-hidden shadow-md">
            {!videoError ? (
              <iframe
                className="w-full h-full rounded-lg"
                src="https://www.youtube.com/embed/wmU2aDi9q94"
                title="Getting Started with Woobound"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                onError={handleVideoError}
                sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation"
              />
            ) : (
              <div className="w-full h-full rounded-lg bg-gray-100 flex items-center justify-center">
                <p className="text-gray-500">Video unavailable. <a href="https://www.youtube.com/watch?v=wmU2aDi9q94" target="_blank" rel="noopener noreferrer" className="text-primary">Watch on YouTube</a></p>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
