
import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export const usePartnerCode = (amount: number, packageType: string) => {
  const [partnerCode, setPartnerCode] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState<number | null>(null);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [isFreeTrialCode, setIsFreeTrialCode] = useState(false);
  const [trialDurationMonths, setTrialDurationMonths] = useState<number>(0);
  const [postTrialDiscountPercentage, setPostTrialDiscountPercentage] = useState<number | null>(null);
  const { toast } = useToast();

  const validatePartnerCode = async (code: string) => {
    if (!code) return;
    
    console.log('Validating partner code:', code, 'for package type:', packageType);
    setIsValidatingCode(true);
    setIsFreeTrialCode(false);
    setTrialDurationMonths(0);
    setPostTrialDiscountPercentage(null);
    
    try {
      // Query the database for the partner code
      const { data, error } = await supabase
        .from('partner_codes')
        .select('*')
        .eq('code', code.toUpperCase())
        .maybeSingle();

      console.log('Partner code query result:', { data, error });

      if (error) {
        console.error('Error validating partner code:', error);
        toast({
          title: "Error",
          description: "Failed to validate partner code. Please try again.",
          variant: "destructive"
        });
        return;
      }

      if (!data) {
        console.log('No partner code found for:', code);
        toast({
          title: "Invalid Partner Code",
          description: "The partner code you entered is not valid. Please check and try again.",
          variant: "destructive"
        });
        setDiscountedAmount(null);
        return;
      }

      console.log('Partner code found:', data);

      // Check if code is expired
      if (data.expires_at && new Date(data.expires_at) < new Date()) {
        toast({
          title: "Invalid Partner Code",
          description: "This partner code has expired.",
          variant: "destructive"
        });
        return;
      }

      // Check if code has reached usage limit
      if (data.usage_limit && data.usage_count >= data.usage_limit) {
        toast({
          title: "Invalid Partner Code",
          description: "This partner code has reached its usage limit.",
          variant: "destructive"
        });
        return;
      }

      // Check if code is valid for this package type
      if (data.valid_package_types && data.valid_package_types.length > 0 && !data.valid_package_types.includes(packageType)) {
        console.log('Package type validation failed:', {
          validTypes: data.valid_package_types,
          currentType: packageType
        });
        
        toast({
          title: "Invalid Partner Code",
          description: `This partner code is not valid for the ${packageType === 'maps_seo' ? 'Maps SEO' : 'Maps & Website SEO'} package.`,
          variant: "destructive"
        });
        return;
      }

      const discounted = Number((amount * (1 - data.discount_percentage / 100)).toFixed(2));
      setDiscountedAmount(discounted);
      
      // Set trial duration if available
      if (data.trial_duration_months > 0) {
        setTrialDurationMonths(data.trial_duration_months);
      }
      
      // Set post-trial discount percentage if available
      if (data.post_trial_discount_percentage > 0) {
        setPostTrialDiscountPercentage(data.post_trial_discount_percentage);
      }
      
      // Check if this is a 100% discount code (free trial)
      if (data.discount_percentage === 100) {
        setIsFreeTrialCode(true);
        
        if (data.post_trial_discount_percentage) {
          const postTrialAmount = Number((amount * (1 - data.post_trial_discount_percentage / 100)).toFixed(2));
          
          toast({
            title: `${data.trial_duration_months}-Month Free Trial Code Applied`,
            description: `You're eligible for a ${data.trial_duration_months}-month free trial! After your trial, you'll be charged $${postTrialAmount}/month (${data.post_trial_discount_percentage}% off).`,
            variant: "default"
          });
        } else {
          toast({
            title: "Free Trial Code Applied",
            description: "You're eligible for a free trial! No payment required.",
            variant: "default"
          });
        }
      } else {
        toast({
          title: "Partner Code Applied",
          description: `A ${data.discount_percentage}% discount has been applied. Your new monthly total is $${discounted}`,
          variant: "default"
        });
      }
      
    } catch (error) {
      console.error('Error validating partner code:', error);
      setDiscountedAmount(null);
      toast({
        title: "Error",
        description: "There was a problem validating your partner code. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsValidatingCode(false);
    }
  };

  const handlePartnerCodeChange = (code: string) => {
    setPartnerCode(code.toUpperCase());
  };

  return {
    partnerCode,
    discountedAmount,
    isValidatingCode,
    isFreeTrialCode,
    trialDurationMonths,
    postTrialDiscountPercentage,
    handlePartnerCodeChange,
    validatePartnerCode
  };
};
