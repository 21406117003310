
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { AlertCircle, CheckCircle, XCircle, Clock, Webhook, RefreshCw, Ban } from 'lucide-react';
import { format } from 'date-fns';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";

interface TransactionWithProfile {
  id: string;
  created_at: string;
  status: string;
  amount: number;
  event_type: string | null;
  fraud_status: string | null;
  user_type: 'profile' | 'temp';
  user_id: string;
  addon_id: string | null;
  api_response_message: string | null;
  customer_payment_profile_id: string | null;
  customer_profile_id: string | null;
  error_message: string | null;
  is_addon: boolean;
  metadata: Record<string, any> | null;
  payment_type: string | null;
  ref_id: string | null;
  retry_count: number;
  subscription_id: string | null;
  transaction_id: string | null;
  webhook_received: boolean;
  webhook_received_at: string | null;
  webhook_event_type: string | null;
  updated_at: string;
  profiles?: {
    email: string | null;
    first_name: string | null;
    last_name: string | null;
  } | null;
  temp_user_data?: {
    email: string;
    first_name: string | null;
    last_name: string | null;
  } | null;
}

export function PaymentMonitoring() {
  const { toast } = useToast();
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [isCancellationVerifying, setIsCancellationVerifying] = useState(false);
  const [cancellationResult, setCancellationResult] = useState<any>(null);
  
  const { data: transactions, isLoading, error, refetch } = useQuery({
    queryKey: ['admin-payment-transactions'],
    queryFn: async () => {
      console.log('Fetching payment transactions as admin...');
      try {
        // First try to get profile-related transactions
        const { data: profileTransactions, error: profileError } = await supabase
          .from('payment_transactions')
          .select(`
            *,
            profiles:profiles(id, email, first_name, last_name)
          `)
          .eq('user_type', 'profile')
          .order('created_at', { ascending: false });

        if (profileError) {
          console.error('Error fetching profile transactions:', profileError);
          throw profileError;
        }

        // Then get temp user transactions
        const { data: tempTransactions, error: tempError } = await supabase
          .from('payment_transactions')
          .select(`
            *,
            temp_user_data:temp_user_data(id, email, first_name, last_name)
          `)
          .eq('user_type', 'temp')
          .order('created_at', { ascending: false });

        if (tempError) {
          console.error('Error fetching temp transactions:', tempError);
          throw tempError;
        }

        // Combine and sort all transactions
        const allTransactions = [...(profileTransactions || []), ...(tempTransactions || [])];
        allTransactions.sort((a, b) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        
        return allTransactions.slice(0, 100) as unknown as TransactionWithProfile[];
      } catch (error) {
        console.error('Error fetching payment transactions:', error);
        throw error;
      }
    },
  });

  const simulateWebhook = async (refId: string) => {
    try {
      const { error } = await supabase.functions.invoke('authorize-webhook', {
        body: {
          simulateSuccess: true,
          refId
        }
      });

      if (error) throw error;

      toast({
        title: "Webhook Simulated",
        description: "Payment marked as completed",
      });

      refetch();
    } catch (error: any) {
      console.error('Error simulating webhook:', error);
      toast({
        title: "Error",
        description: "Failed to simulate webhook",
        variant: "destructive",
      });
    }
  };

  const verifyCancellation = async () => {
    if (!subscriptionId.trim()) {
      toast({
        title: "Missing Information",
        description: "Please enter a subscription ID",
        variant: "destructive",
      });
      return;
    }

    setIsCancellationVerifying(true);
    setCancellationResult(null);

    try {
      const { data, error } = await supabase.functions.invoke('fix-trial-subscription', {
        body: {
          subscriptionId,
          fixType: 'verify_cancellation'
        }
      });

      if (error) throw error;

      setCancellationResult(data);

      if (!data.success) {
        toast({
          title: "Verification Failed",
          description: data.message,
          variant: "destructive",
        });
      }
    } catch (error: any) {
      console.error('Error verifying cancellation:', error);
      toast({
        title: "Error",
        description: "Failed to verify cancellation",
        variant: "destructive",
      });
    } finally {
      setIsCancellationVerifying(false);
    }
  };

  const manualCancelSubscription = async () => {
    if (!subscriptionId.trim()) {
      toast({
        title: "Missing Information",
        description: "Please enter a subscription ID",
        variant: "destructive",
      });
      return;
    }

    if (!confirm("Are you sure you want to manually cancel this subscription?")) {
      return;
    }

    setIsCancellationVerifying(true);

    try {
      const { data, error } = await supabase.functions.invoke('fix-trial-subscription', {
        body: {
          subscriptionId,
          fixType: 'manual_cancel'
        }
      });

      if (error) throw error;

      if (data.success) {
        toast({
          title: "Subscription Cancelled",
          description: "The subscription has been manually cancelled in our system",
        });
        // Refresh the verification data
        verifyCancellation();
      } else {
        toast({
          title: "Cancellation Failed",
          description: data.message,
          variant: "destructive",
        });
      }
    } catch (error: any) {
      console.error('Error cancelling subscription:', error);
      toast({
        title: "Error",
        description: "Failed to cancel subscription",
        variant: "destructive",
      });
    } finally {
      setIsCancellationVerifying(false);
    }
  };

  const getFraudStatusIcon = (status: string | null) => {
    switch (status) {
      case 'approved':
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case 'declined':
        return <XCircle className="h-4 w-4 text-red-500" />;
      case 'review':
        return <Clock className="h-4 w-4 text-yellow-500" />;
      default:
        return null;
    }
  };

  const getEventTypeBadge = (type: string | null) => {
    const styles = {
      payment: "bg-green-100 text-green-800",
      refund: "bg-yellow-100 text-yellow-800",
      void: "bg-gray-100 text-gray-800",
      fraud_approved: "bg-green-100 text-green-800",
      fraud_declined: "bg-red-100 text-red-800",
      subscription_suspended: "bg-orange-100 text-orange-800",
      subscription_expired: "bg-red-100 text-red-800",
      subscription_cancellation: "bg-purple-100 text-purple-800",
    };

    return (
      <Badge className={styles[type as keyof typeof styles] || "bg-gray-100 text-gray-800"}>
        {type?.replace(/_/g, ' ')}
      </Badge>
    );
  };

  const getCustomerEmail = (transaction: TransactionWithProfile) => {
    if (transaction.user_type === 'profile' && transaction.profiles?.email) {
      return transaction.profiles.email;
    } else if (transaction.user_type === 'temp' && transaction.temp_user_data?.email) {
      return transaction.temp_user_data.email;
    }
    return 'N/A';
  };

  if (isLoading) {
    return (
      <Alert>
        <AlertDescription>
          Loading payment transactions...
        </AlertDescription>
      </Alert>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          Error loading payment transactions: {error instanceof Error ? error.message : 'Unknown error'}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <Tabs defaultValue="transactions" className="w-full">
        <TabsList className="mb-4">
          <TabsTrigger value="transactions">Payment Transactions</TabsTrigger>
          <TabsTrigger value="webhooks">Webhook Verification</TabsTrigger>
        </TabsList>
        
        <TabsContent value="transactions">
          <Card>
            <CardHeader>
              <CardTitle>Payment Event Monitoring</CardTitle>
              <CardDescription>
                Monitor payment transactions, fraud alerts, and subscription events
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>Customer</TableHead>
                    <TableHead>Event Type</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Webhook</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {transactions && transactions.length > 0 ? (
                    transactions.map((tx) => (
                      <TableRow key={tx.id}>
                        <TableCell>
                          {tx.created_at ? format(new Date(tx.created_at), 'MMM d, yyyy HH:mm') : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {getCustomerEmail(tx)}
                        </TableCell>
                        <TableCell>
                          {getEventTypeBadge(tx.payment_type)}
                        </TableCell>
                        <TableCell>
                          ${tx.amount?.toFixed(2) || '0.00'}
                        </TableCell>
                        <TableCell>
                          <Badge variant={tx.status === 'completed' ? 'default' : tx.status === 'pending' ? 'outline' : 'destructive'}>
                            {tx.status || 'unknown'}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          {tx.webhook_received ? (
                            <div className="flex items-center gap-2">
                              <Webhook className="h-4 w-4 text-green-500" />
                              <span className="text-sm text-gray-500">
                                {tx.webhook_received_at ? format(new Date(tx.webhook_received_at), 'HH:mm:ss') : 'Received'}
                              </span>
                            </div>
                          ) : (
                            <Badge variant="outline" className="text-yellow-600">
                              Pending
                            </Badge>
                          )}
                        </TableCell>
                        <TableCell>
                          {!tx.webhook_received && tx.status === 'pending' && (
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => tx.ref_id && simulateWebhook(tx.ref_id)}
                            >
                              Simulate Webhook
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} className="text-center py-4">
                        No payment transactions found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="webhooks">
          <Card>
            <CardHeader>
              <CardTitle>Webhook Verification Tools</CardTitle>
              <CardDescription>
                Verify and troubleshoot webhook events from Authorize.net
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <h3 className="text-lg font-medium">Cancellation Verification</h3>
                <p className="text-sm text-gray-500">
                  Verify if a subscription cancellation webhook was properly received and processed
                </p>
                <div className="flex gap-2">
                  <Button 
                    variant="outline" 
                    onClick={() => setIsVerifyDialogOpen(true)}
                  >
                    Verify Cancellation
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
      
      <Dialog open={isVerifyDialogOpen} onOpenChange={setIsVerifyDialogOpen}>
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle>Verify Subscription Cancellation</DialogTitle>
            <DialogDescription>
              Check if a cancellation webhook was received and processed correctly
            </DialogDescription>
          </DialogHeader>
          
          <div className="space-y-4 py-2">
            <div className="space-y-2">
              <label htmlFor="subscription-id" className="text-sm font-medium">
                Subscription ID
              </label>
              <div className="flex gap-2">
                <Input
                  id="subscription-id"
                  placeholder="Enter Authorize.net subscription ID"
                  value={subscriptionId}
                  onChange={(e) => setSubscriptionId(e.target.value)}
                />
                <Button 
                  variant="outline" 
                  onClick={verifyCancellation}
                  disabled={isCancellationVerifying}
                >
                  {isCancellationVerifying ? <RefreshCw className="h-4 w-4 animate-spin" /> : 'Verify'}
                </Button>
              </div>
            </div>
            
            {cancellationResult && (
              <div className="border rounded-md p-4 bg-gray-50">
                <h4 className="font-medium mb-2">Verification Results</h4>
                
                {cancellationResult.subscription ? (
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <strong className="text-sm">Status:</strong> 
                      <Badge variant={cancellationResult.isCancelled ? 'default' : 'outline'}>
                        {cancellationResult.subscription.status}
                      </Badge>
                    </div>
                    
                    <div className="flex items-center gap-2">
                      <strong className="text-sm">Cancellation Record:</strong>
                      {cancellationResult.hasCancellationRecord ? (
                        <span className="text-green-600 flex items-center gap-1">
                          <CheckCircle className="h-4 w-4" /> Found
                        </span>
                      ) : (
                        <span className="text-red-600 flex items-center gap-1">
                          <XCircle className="h-4 w-4" /> Not Found
                        </span>
                      )}
                    </div>
                    
                    {cancellationResult.cancellationRecord && (
                      <div>
                        <strong className="text-sm">Webhook Event:</strong>
                        <Badge variant="outline" className="ml-2">
                          {cancellationResult.cancellationRecord.webhook_event_type}
                        </Badge>
                        <div className="text-xs text-gray-500 mt-1">
                          Received at: {cancellationResult.cancellationRecord.webhook_received_at ? 
                            format(new Date(cancellationResult.cancellationRecord.webhook_received_at), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}
                        </div>
                      </div>
                    )}
                    
                    {!cancellationResult.isCancelled && (
                      <Button 
                        className="w-full mt-2"
                        onClick={manualCancelSubscription}
                        disabled={isCancellationVerifying}
                        variant="destructive"
                      >
                        <Ban className="h-4 w-4 mr-2" />
                        Manually Cancel Subscription
                      </Button>
                    )}
                  </div>
                ) : (
                  <Alert>
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>
                      No subscription found with ID: {subscriptionId}
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
