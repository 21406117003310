import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Star } from "lucide-react";
import { testimonials } from "@/data/testimonials";
import { useNavigate } from "react-router-dom";
import { MainNav } from "@/components/MainNav";

const CaseStudies = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="border-b">
        <div className="container flex h-16 items-center">
          <MainNav />
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Client Success Stories
          </h1>
          <p className="text-xl text-gray-600">
            See how businesses like yours have transformed their local presence with our SEO expertise
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-16">
          {testimonials.map((testimonial) => (
            <Card key={testimonial.id} className="p-6 hover:shadow-lg transition-all">
              <div className="flex mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-primary fill-current" />
                ))}
              </div>
              <p className="text-gray-700 mb-6">{testimonial.content}</p>
              <div className="border-t pt-4">
                <p className="font-semibold text-gray-900">{testimonial.name}</p>
                <p className="text-sm text-gray-500">{testimonial.role}</p>
                <p className="text-sm text-gray-500">{testimonial.business}</p>
                <p className="text-sm text-gray-500">{testimonial.location}</p>
                <p className="text-sm font-medium text-primary mt-2">
                  {testimonial.improvement}
                </p>
              </div>
            </Card>
          ))}
        </div>

        <div className="text-center bg-primary/5 rounded-2xl p-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Ready to Transform Your Local Presence?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Join these success stories and boost your business visibility today.
          </p>
          <Button 
            size="lg"
            className="bg-primary hover:bg-primary/90 text-white px-8"
            onClick={() => navigate('/signup/maps_seo')}
          >
            Start Your SEO Journey
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;