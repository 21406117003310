
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";

interface Review {
  id: string;
  location_id: string;
  review_id: string;
  rating: number;
  comment: string;
  reviewer_name: string;
  review_source: string;
  review_date: string;
}

interface ReviewsListProps {
  reviews: Review[];
}

export const ReviewsList = ({ reviews }: ReviewsListProps) => {
  return (
    <Card className="shadow-sm border border-gray-200 overflow-hidden">
      <ScrollArea className="h-[580px] w-full">
        {reviews?.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-center text-gray-500 py-8">
              No reviews found. Click "Refresh Reviews" to fetch the latest reviews.
            </p>
          </div>
        ) : (
          <div className="divide-y divide-gray-100">
            {reviews?.map((review) => (
              <div key={review.review_id} className="p-4 hover:bg-gray-50">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="font-medium">{review.reviewer_name || "Anonymous"}</h3>
                    <div className="flex items-center mt-1">
                      <div className="flex">
                        {[...Array(5)].map((_, i) => (
                          <svg
                            key={i}
                            className={`w-4 h-4 ${i < review.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                      <span className="text-gray-500 text-xs ml-2">
                        {review.review_date ? new Date(review.review_date).toLocaleDateString() : 'No date'}
                      </span>
                    </div>
                  </div>
                  <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
                    {review.review_source}
                  </span>
                </div>
                <p className="text-gray-600 text-sm mt-2">{review.comment || "No comment provided"}</p>
              </div>
            ))}
          </div>
        )}
      </ScrollArea>
    </Card>
  );
};
