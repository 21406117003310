
import { useState, useEffect } from "react";
import { FormBusinessProfile } from "@/components/business/types";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";

interface GbpVerificationFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const GbpVerificationFields = ({ profile, setProfile }: GbpVerificationFieldsProps) => {
  const [adminName, setAdminName] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchAdminName = async () => {
      if (profile.gbp_verified_by) {
        const { data, error } = await supabase
          .from('profiles')
          .select('first_name, last_name')
          .eq('id', profile.gbp_verified_by)
          .single();
          
        if (!error && data) {
          setAdminName(`${data.first_name || ''} ${data.last_name || ''}`.trim());
        }
      }
    };
    
    fetchAdminName();
  }, [profile.gbp_verified_by]);

  const handleVerificationToggle = async (checked: boolean) => {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) return;
    
    setProfile({
      ...profile,
      gbp_verified: checked,
      gbp_verified_at: checked ? new Date().toISOString() : null,
      gbp_verified_by: checked ? user.id : null
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Google Business Profile Verification</h3>
      
      <div className="flex items-center justify-between bg-muted p-4 rounded-md">
        <div className="space-y-0.5">
          <Label htmlFor="gbp-verified" className="text-base">
            GBP Access Verified
          </Label>
          <p className="text-sm text-muted-foreground">
            Mark when business has shared Google Business Profile access
          </p>
        </div>
        <Switch
          id="gbp-verified"
          checked={profile.gbp_verified || false}
          onCheckedChange={handleVerificationToggle}
        />
      </div>
      
      {profile.gbp_verified && profile.gbp_verified_at && (
        <div className="text-sm text-muted-foreground">
          <Badge variant="outline" className="font-normal">Verified</Badge>
          <p className="mt-1">
            Verified on {format(new Date(profile.gbp_verified_at), 'PPP')}
            {adminName && <> by {adminName}</>}
          </p>
        </div>
      )}
    </div>
  );
};
