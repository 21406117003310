import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { ReviewsHeader } from "@/components/reviews/ReviewsHeader";
import { ReviewsOverview } from "@/components/reviews/ReviewsOverview";
import { ReviewsList } from "@/components/reviews/ReviewsList";
import { ReviewsLoading } from "@/components/reviews/ReviewsLoading";
import { ReviewsError } from "@/components/reviews/ReviewsError";
import { ReviewsComingSoon } from "@/components/reviews/ReviewsComingSoon";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

interface Review {
  id: string;
  location_id: string;
  review_id: string;
  rating: number;
  comment: string;
  reviewer_name: string;
  review_source: string;
  review_date: string;
}

export default function Reviews() {
  const { toast } = useToast();
  const [locationId, setLocationId] = useState<string | null>(null);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    async function fetchBusinessProfile() {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          console.error("[Reviews] No authenticated user found");
          return;
        }

        console.log("[Reviews] Fetching business profile for user:", user.id);
        const { data: business, error } = await supabase
          .from('business_profiles')
          .select('location_id')
          .eq('user_id', user.id)
          .maybeSingle();

        if (error) {
          console.error("[Reviews] Error fetching business profile:", error);
          toast({
            title: "Error",
            description: "Failed to fetch business profile",
            variant: "destructive",
          });
          return;
        }

        if (business?.location_id) {
          console.log("[Reviews] Found location_id:", business.location_id);
          setLocationId(business.location_id);
        } else {
          console.log("[Reviews] No location_id found for business profile");
          toast({
            title: "No Location ID",
            description: "Our team is working on setting up your campaign. Please check back soon.",
            variant: "destructive",
          });
        }
      } catch (error) {
        console.error("[Reviews] Unexpected error:", error);
        toast({
          title: "Error",
          description: "An unexpected error occurred",
          variant: "destructive",
        });
      }
    }
    fetchBusinessProfile();
  }, [toast]);

  const refreshReviews = async () => {
    if (!locationId) {
      console.log("[Reviews] Cannot refresh reviews - no location_id");
      toast({
        title: "Error",
        description: "No location ID available. Please ensure your business profile is set up.",
        variant: "destructive",
      });
      return null;
    }
    
    try {
      console.log("[Reviews] Refreshing reviews for location:", locationId);
      const { data, error } = await supabase.functions.invoke('uberall-reviews', {
        body: { locationId }
      });

      if (error) throw error;
      
      console.log("[Reviews] Refresh response:", data);
      
      toast({
        title: "Reviews refreshed",
        description: "Your reviews have been updated from Woobound Listings.",
      });

      await reviewsQuery.refetch();
    } catch (error) {
      console.error('[Reviews] Error refreshing reviews:', error);
      toast({
        title: "Error refreshing reviews",
        description: "There was a problem updating your reviews. Please try again.",
        variant: "destructive",
      });
    }
  };

  const reviewsQuery = useQuery({
    queryKey: ['reviews', locationId],
    queryFn: async () => {
      if (!locationId) {
        console.log("[Reviews] Cannot fetch reviews - no location_id");
        return [];
      }
      
      console.log("[Reviews] Fetching reviews for location:", locationId);
      const { data, error } = await supabase
        .from('location_reviews')
        .select('*')
        .eq('location_id', locationId)
        .order('review_date', { ascending: false });

      if (error) {
        console.error("[Reviews] Error fetching reviews:", error);
        throw error;
      }
      console.log("[Reviews] Fetched reviews:", data);
      return data as Review[];
    },
    enabled: !!locationId
  });

  const calculateAverageRating = (reviews: Review[]) => {
    if (reviews.length === 0) return 0;
    const sum = reviews.reduce((acc, review) => acc + review.rating, 0);
    return sum / reviews.length;
  };

  const handleFilterChange = (value: string) => {
    setFilter(value);
  };

  const handleDownload = () => {
    toast({
      title: "Coming Soon",
      description: "CSV export functionality will be available soon.",
    });
  };

  console.log("[Reviews] Component state:", {
    hasLocationId: !!locationId,
    isLoading: reviewsQuery.isLoading,
    isError: reviewsQuery.isError,
    reviewsCount: reviewsQuery.data?.length
  });

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="w-full px-8 py-6">
            <div className="max-w-5xl mx-auto">
              <div className="flex flex-col gap-6">
                {!locationId ? (
                  <ReviewsComingSoon />
                ) : reviewsQuery.isLoading ? (
                  <ReviewsLoading />
                ) : reviewsQuery.isError ? (
                  <ReviewsError message="Error loading reviews. Please try again." />
                ) : (
                  <>
                    <ReviewsHeader 
                      onRefresh={refreshReviews}
                      isDisabled={reviewsQuery.isFetching}
                    />
                    <ReviewsOverview
                      totalReviews={reviewsQuery.data?.length || 0}
                      averageRating={calculateAverageRating(reviewsQuery.data || [])}
                      onFilterChange={handleFilterChange}
                      onDownload={handleDownload}
                    />
                    <ReviewsList reviews={reviewsQuery.data || []} />
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
}
