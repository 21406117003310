import { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { CreditCard, ShieldCheck, AlertCircle } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const UpdateCard = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [secureConnection, setSecureConnection] = useState(true);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (window.location.protocol !== 'https:' && !window.location.hostname.includes('localhost')) {
      setSecureConnection(false);
      console.error('Insecure connection detected for payment form!');
      toast({
        title: "Security Warning",
        description: "Payment information should only be entered on a secure connection.",
        variant: "destructive",
      });
      
      if (process.env.NODE_ENV === 'production') {
        window.location.href = window.location.href.replace('http:', 'https:');
      }
    }
  }, [toast]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!secureConnection && !window.location.hostname.includes('localhost')) {
      toast({
        title: "Payment Failed",
        description: "Payment processing requires a secure connection.",
        variant: "destructive",
      });
      return;
    }
    
    setIsProcessing(true);

    try {
      if (!validateCardNumber(cardNumber) || !validateExpiryFormat(expiryDate) || !validateCVV(cvv)) {
        throw new Error("Please check your card information and try again.");
      }
      
      if (!(window as any).Accept) {
        const script = document.createElement("script");
        script.src = "https://js.authorize.net/v1/Accept.js";
        script.async = true;
        script.crossOrigin = "anonymous";
        
        if ((window as any).__cspNonce) {
          script.nonce = (window as any).__cspNonce;
        }
        
        document.body.appendChild(script);
        await new Promise((resolve) => script.onload = resolve);
      }

      const [month, year] = expiryDate.split('/');
      
      const secureData = {
        cardData: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          month,
          year: '20' + year,
          cardCode: cvv
        }
      };

      const response = await new Promise((resolve, reject) => {
        (window as any).Accept.dispatchData(secureData, (response: any) => {
          if (response.messages.resultCode === 'Error') {
            reject(new Error(response.messages.message[0].text));
          }
          resolve(response);
        });
      });

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { data: subscription } = await supabase
        .from('subscriptions')
        .select('external_subscription_id')
        .eq('user_id', user.id)
        .single();

      if (!subscription?.external_subscription_id) {
        throw new Error("No active subscription found");
      }

      const { error } = await supabase.functions.invoke('update-card', {
        body: {
          subscriptionId: subscription.external_subscription_id,
          paymentNonce: (response as any).opaqueData.dataValue,
        }
      });

      if (error) throw error;

      toast({
        title: "Card Updated",
        description: "Your card has been successfully updated.",
      });

      queryClient.invalidateQueries({ queryKey: ['subscription'] });
      
      setCardNumber("");
      setExpiryDate("");
      setCvv("");
    } catch (error: any) {
      console.error('Card update error:', error);
      toast({
        title: "Update Failed",
        description: error.message || "Failed to update card",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const formatCardNumber = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  const formatExpiryDate = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    if (v.length >= 2) {
      return v.slice(0, 2) + '/' + v.slice(2, 4);
    }
    return v;
  };

  const validateCardNumber = (number: string): boolean => {
    const sanitized = number.replace(/\s/g, '');
    return /^\d{15,16}$/.test(sanitized);
  };

  const validateExpiryFormat = (expiry: string): boolean => {
    return /^\d{2}\/\d{2}$/.test(expiry);
  };

  const validateCVV = (cvv: string): boolean => {
    return /^\d{3,4}$/.test(cvv);
  };

  if (!secureConnection && process.env.NODE_ENV === 'production') {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          This payment form requires a secure connection (HTTPS). Please refresh the page using HTTPS.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <CreditCard className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Update Payment Method</h2>
          <p className="text-sm text-gray-500">Update your card information</p>
        </div>
      </div>

      {secureConnection && (
        <div className="flex items-center gap-2 mb-4 text-sm text-green-600">
          <ShieldCheck className="h-4 w-4" />
          <span>Secure TLS 1.2+ connection</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="cardNumber">Card Number</Label>
          <Input
            id="cardNumber"
            type="text"
            value={cardNumber}
            onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
            placeholder="1234 5678 9012 3456"
            maxLength={19}
            required
            disabled={isProcessing}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="expiryDate">Expiry Date</Label>
            <Input
              id="expiryDate"
              type="text"
              value={expiryDate}
              onChange={(e) => setExpiryDate(formatExpiryDate(e.target.value))}
              placeholder="MM/YY"
              maxLength={5}
              required
              disabled={isProcessing}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="cvv">CVV</Label>
            <Input
              id="cvv"
              type="text"
              value={cvv}
              onChange={(e) => setCvv(e.target.value.replace(/\D/g, '').slice(0, 4))}
              placeholder="123"
              maxLength={4}
              required
              disabled={isProcessing}
            />
          </div>
        </div>

        <Button 
          type="submit" 
          className="w-full"
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Update Card"}
        </Button>
      </form>
    </Card>
  );
};
