
import { dashboard } from "./es/dashboard";
import { landing } from "./es/landing";
import { settings } from "./es/settings";
import { businessprofile } from "./es/businessprofile";
import { googleTrends } from "./es/googletrends";
import { common } from "./es/common";
import { billing } from "./es/billing";

export const esTranslations = {
  common,
  dashboard,
  landing,
  settings,
  business: businessprofile,
  googletrends: googleTrends,
  billing
};
