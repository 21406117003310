
import { format } from "date-fns";
import { Badge } from "@/components/ui/badge";
import { TableCell, TableRow } from "@/components/ui/table";
import { AlertCircle, CreditCard, ExternalLink, Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface SubscriptionRowProps {
  subscription: any;
}

export const SubscriptionRow = ({ subscription }: SubscriptionRowProps) => {
  if (!subscription) {
    return null; // Handle null subscription gracefully
  }
  
  const getStatusBadgeColor = (status: string) => {
    if (!status) return "bg-gray-500";
    
    switch (status.toLowerCase()) {
      case "active":
        return "bg-green-500";
      case "pending":
        return "bg-yellow-500";
      case "trial":
        return "bg-blue-500";
      case "cancelled":
      case "canceled":
        return "bg-red-500";
      case "suspended":
        return "bg-orange-500";
      case "expired":
        return "bg-purple-500";
      default:
        return "bg-gray-500";
    }
  };

  const formatCurrency = (amount: number) => {
    if (amount === null || amount === undefined) return "$0.00";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return "-";
    try {
      return format(new Date(dateString), "MMM d, yyyy");
    } catch (e) {
      console.error("Invalid date:", dateString);
      return "-";
    }
  };

  const lastPayment = subscription.payment_transactions?.[0];
  const profileName = subscription.profiles 
    ? `${subscription.profiles.first_name || ''} ${subscription.profiles.last_name || ''}`.trim() || 'Unknown' 
    : 'Unknown';
  const profileEmail = subscription.profiles?.email || 'No email';

  const getSourceBadge = () => {
    if (subscription.source === 'authorize.net') {
      return (
        <Badge variant="outline" className="ml-2 bg-blue-50 text-blue-800 border-blue-200 flex items-center gap-1">
          <ExternalLink className="h-3 w-3" />
          Authorize.net
        </Badge>
      );
    }
    return null;
  };

  return (
    <TableRow>
      <TableCell>
        <div>
          <div className="font-medium">{profileName}</div>
          <div className="text-sm text-gray-500">{profileEmail}</div>
        </div>
      </TableCell>
      <TableCell>{subscription.package_type || "Unknown package"}</TableCell>
      <TableCell>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="flex items-center gap-1">
              <Badge className={getStatusBadgeColor(subscription.status || "")}>
                {subscription.status || "unknown"}
              </Badge>
              {getSourceBadge()}
            </TooltipTrigger>
            <TooltipContent>
              <p>Source: {subscription.source === 'authorize.net' ? 'Authorize.net' : 'Database'}</p>
              {subscription.authorize_status && subscription.authorize_status !== subscription.status && (
                <p>Authorize.net status: {subscription.authorize_status}</p>
              )}
              {subscription.external_subscription_id && (
                <p>External ID: {subscription.external_subscription_id}</p>
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
      <TableCell>{subscription.price ? formatCurrency(subscription.price) : "$0.00"}</TableCell>
      <TableCell>
        {formatDate(subscription.next_billing_date)}
      </TableCell>
      <TableCell>
        <Badge
          className={getStatusBadgeColor(lastPayment?.status || "unknown")}
        >
          {lastPayment?.status || "No payments"}
        </Badge>
      </TableCell>
      <TableCell>
        <div className="space-y-1">
          {subscription.has_expiring_card && (
            <Badge className="bg-yellow-500 flex items-center gap-1">
              <CreditCard className="h-3 w-3" />
              Card Expiring Soon
            </Badge>
          )}
          {subscription.has_declined_transaction && (
            <Badge className="bg-red-500 flex items-center gap-1">
              <AlertCircle className="h-3 w-3" />
              Payment Declined
            </Badge>
          )}
          {subscription.source === 'authorize.net' && !subscription.external_subscription_id && (
            <Badge className="bg-blue-500 flex items-center gap-1">
              <Info className="h-3 w-3" />
              External Only
            </Badge>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
