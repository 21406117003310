
import { Header } from "@/components/landing/Header";
import { LogoSlider } from "@/components/landing/LogoSlider";
import { DoneForYouSection } from "@/components/landing/DoneForYouSection";
import { Features } from "@/components/landing/Features";
import { Pricing } from "@/components/landing/Pricing";
import { TestimonialSlider } from "@/components/landing/TestimonialSlider";
import { WhyChoose } from "@/components/landing/WhyChoose";
import { ContactSection } from "@/components/landing/ContactSection";
import { Footer } from "@/components/landing/Footer";
import { MainNav } from "@/components/MainNav";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { LanguageSelector } from "@/components/LanguageSelector";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation();
  
  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing');
    pricingSection?.scrollIntoView({ behavior: 'smooth' });
  };
  
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    contactSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen relative">
      <div className="border-b bg-background">
        <div className="container flex h-16 items-center px-4">
          <MainNav />
          <div className="ml-auto flex items-center space-x-4">
            <button 
              onClick={scrollToContact}
              className="text-sm font-medium transition-colors hover:text-primary"
            >
              {t('landing.nav.contact')}
            </button>
            <Link to="/login">
              <Button variant="ghost">{t('landing.nav.login')}</Button>
            </Link>
            <Button 
              className="bg-[#e0933c] hover:bg-[#e0933c]/90"
              onClick={scrollToPricing}
            >
              {t('landing.nav.signup')}
            </Button>
          </div>
        </div>
      </div>
      <Header onGetStartedClick={scrollToPricing} />
      <LogoSlider />
      <DoneForYouSection onBoostBusinessClick={scrollToPricing} />
      <Features />
      <TestimonialSlider />
      <Pricing />
      <WhyChoose />
      <ContactSection />
      <Footer />
      <div className="fixed bottom-4 left-4 z-50">
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Landing;
