
/**
 * Security utility functions to prevent XSS and other injection attacks
 */

import { supabase } from "@/integrations/supabase/client";

/**
 * Sanitizes URL parameters to prevent XSS attacks
 * @param url The URL to sanitize
 * @returns A sanitized version of the URL
 */
export const sanitizeUrl = (url: string): string => {
  if (!url) return '';
  
  // If it's an absolute URL, ensure it's from our domain
  if (url.includes('://')) {
    const allowedDomains = [
      'woobound.com',
      'portal.woobound.com',
      'www.woobound.com',
      'localhost',
      'lovable.app',
      'lovable.dev',
      'lovableproject.com',
      'brightlocal.com',
      'app.brightlocal.com',
      'brightlocal.co.uk'
    ];
    
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname;
      
      // Check if the hostname matches any of our allowed domains or their subdomains
      if (!allowedDomains.some(domain => 
        hostname === domain || 
        hostname.endsWith('.' + domain)
      )) {
        console.warn('Potentially unsafe redirect blocked:', url);
        logSecurityEvent('unsafe-redirect-blocked', {
          url,
          reason: 'domain-not-allowed'
        }, 'medium');
        return '/';
      }
    } catch (e) {
      console.error('Invalid URL:', url);
      logSecurityEvent('invalid-url', {
        url,
        error: e.message
      }, 'low');
      return '/';
    }
  }
  
  // Check for potentially malicious URL components
  if (
    url.toLowerCase().includes('javascript:') ||
    url.toLowerCase().includes('data:') ||
    url.toLowerCase().includes('vbscript:') ||
    url.includes('<') ||
    url.includes('>')
  ) {
    console.warn('Malicious URL components blocked:', url);
    logSecurityEvent('malicious-url-components', {
      url,
      patterns: ['javascript:', 'data:', 'vbscript:', '<', '>'].filter(pattern => 
        url.toLowerCase().includes(pattern.toLowerCase())
      )
    }, 'high');
    return '/';
  }
  
  // For relative URLs, sanitize any parameters
  const urlParts = url.split('?');
  if (urlParts.length > 1) {
    try {
      // We need to create a full URL to use the URL API
      const dummyUrl = new URL(`https://example.com${url}`);
      const sanitizedParams = new URLSearchParams();
      
      dummyUrl.searchParams.forEach((value, key) => {
        // Sanitize the parameter values
        const sanitizedValue = value
          .replace(/[<>]/g, '')
          .replace(/javascript:/gi, '')
          .replace(/on\w+=/gi, '')
          .replace(/data:/gi, '')
          .replace(/vbscript:/gi, '');
        
        // Check if the value was modified during sanitization
        if (sanitizedValue !== value) {
          console.warn(`Sanitized suspicious parameter value in URL: ${key}=${value}`);
          logSecurityEvent('url-param-sanitized', {
            url,
            param: key,
            originalValue: value,
            sanitizedValue
          }, 'medium');
        }
        
        sanitizedParams.append(key, sanitizedValue);
      });
      
      return `${urlParts[0]}?${sanitizedParams.toString()}`;
    } catch (e) {
      console.error('Error sanitizing URL parameters:', e);
      logSecurityEvent('url-param-sanitization-error', {
        url,
        error: e.message
      }, 'medium');
      return urlParts[0]; // Return just the path without parameters
    }
  }
  
  return url;
};

/**
 * Sanitizes user input to prevent XSS attacks
 * @param input The string to sanitize
 * @returns A sanitized version of the string
 */
export const sanitizeInput = (input: string): string => {
  if (!input) return '';
  
  const originalInput = input;
  const sanitized = input
    .replace(/[<>]/g, '')
    .replace(/javascript:/gi, '')
    .replace(/on\w+=/gi, '')
    .replace(/data:/gi, '')
    .replace(/vbscript:/gi, '');
    
  if (sanitized !== originalInput) {
    logSecurityEvent('input-sanitized', {
      originalInput,
      sanitizedInput: sanitized
    }, 'medium');
  }
  
  return sanitized;
};

/**
 * Validates if a URL is from an allowed domain
 * @param url The URL to validate
 * @returns true if the URL is valid and allowed, false otherwise
 */
export const isValidRedirectUrl = (url: string): boolean => {
  if (!url) return false;
  
  // Check for potentially malicious URL components
  if (
    url.toLowerCase().includes('javascript:') ||
    url.toLowerCase().includes('data:') ||
    url.toLowerCase().includes('vbscript:') ||
    url.includes('<') ||
    url.includes('>')
  ) {
    console.warn('Malicious URL components detected:', url);
    logSecurityEvent('redirect-validation-failed', {
      url,
      reason: 'malicious-components'
    }, 'high');
    return false;
  }
  
  const allowedDomains = [
    'woobound.com',
    'portal.woobound.com',
    'www.woobound.com',
    'lovable.app',
    'lovable.dev',
    'lovableproject.com',
    'brightlocal.com',
    'app.brightlocal.com',
    'brightlocal.co.uk'
  ];
  
  // For absolute URLs
  if (url.includes('://')) {
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname;
      
      // Check if the hostname matches any of our allowed domains or their subdomains
      const isAllowed = allowedDomains.some(domain => 
        hostname === domain || 
        hostname.endsWith('.' + domain)
      );
      
      if (!isAllowed) {
        logSecurityEvent('redirect-validation-failed', {
          url,
          hostname,
          reason: 'domain-not-allowed'
        }, 'medium');
      }
      
      return isAllowed;
    } catch (e) {
      console.error('Invalid URL format:', url, e);
      logSecurityEvent('redirect-validation-failed', {
        url,
        reason: 'invalid-url-format',
        error: e.message
      }, 'low');
      return false;
    }
  }
  
  // For relative URLs, they should start with / and not contain any suspicious patterns
  const isValid = url.startsWith('/') && 
    !url.includes('javascript:') && 
    !url.includes('data:') && 
    !url.includes('vbscript:') &&
    !url.match(/on\w+=/i);
    
  if (!isValid) {
    logSecurityEvent('redirect-validation-failed', {
      url,
      reason: 'invalid-relative-url'
    }, 'medium');
  }
  
  return isValid;
};

/**
 * Creates a nonce for use with CSP
 * @returns A random nonce string
 */
export const generateNonce = (): string => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

/**
 * Logs a security event for monitoring purposes
 * @param eventType The type of security event 
 * @param details Additional details about the event
 * @param severity The severity level of the security event (info, low, medium, high, critical)
 */
export const logSecurityEvent = async (eventType: string, details: Record<string, any> = {}, severity: 'info' | 'low' | 'medium' | 'high' | 'critical' = 'info'): Promise<void> => {
  console.warn(`Security Event [${eventType}]:`, details);
  
  // First log to console for development
  if (severity === 'high' || severity === 'critical') {
    console.error(`SECURITY ALERT [${severity}] ${eventType}:`, details);
  } else {
    console.warn(`Security Event [${severity}] ${eventType}:`, details);
  }
  
  try {
    // Send to Supabase edge function for logging
    const eventData: {
      event_type: string;
      details: Record<string, any>;
      severity: 'info' | 'low' | 'medium' | 'high' | 'critical';
      path: string;
      source_ip: null;
      user_agent: string;
      user_id?: string; // Adding user_id as an optional property
    } = {
      event_type: eventType,
      details,
      severity,
      path: window.location.href,
      source_ip: null, // Will be captured by the server
      user_agent: navigator.userAgent
    };
    
    // Try to get the current user ID if authenticated
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user?.id) {
        eventData.user_id = user.id;
      }
    } catch (e) {
      console.error('Error getting user for security log:', e);
      // Continue without user ID
    }
    
    // Send to our edge function
    await supabase.functions.invoke('log-security-event', {
      body: eventData
    });
    
  } catch (e) {
    console.error('Failed to log security event:', e);
  }
};
