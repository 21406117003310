
import { MessageSquare } from "lucide-react";
import { Button } from "@/components/ui/button";

interface ReviewsHeaderProps {
  onRefresh: () => void;
  isDisabled: boolean;
}

export const ReviewsHeader = ({ onRefresh, isDisabled }: ReviewsHeaderProps) => {
  return (
    <div className="flex items-center justify-between w-full mb-1">
      <div className="flex items-center gap-2">
        <MessageSquare className="h-6 w-6 text-primary" />
        <h1 className="text-2xl font-semibold">Reviews</h1>
      </div>
      <Button
        onClick={onRefresh}
        disabled={isDisabled}
        className="whitespace-nowrap"
      >
        Refresh Reviews
      </Button>
    </div>
  );
};
