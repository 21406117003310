
import { FormBusinessProfile } from "@/components/business/types";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useEffect } from "react";

interface SeoFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const SeoFields = ({ profile, setProfile }: SeoFieldsProps) => {
  useEffect(() => {
    // Ensure desired_keywords is at least an empty array if null
    if (!profile.desired_keywords) {
      setProfile({ ...profile, desired_keywords: [] });
    }
  }, []);

  const getKeywordsText = () => {
    if (!profile.desired_keywords) return '';
    return Array.isArray(profile.desired_keywords) 
      ? profile.desired_keywords.join('\n') 
      : String(profile.desired_keywords);
  };

  const handleKeywordsChange = (value: string) => {
    console.log("Admin raw keywords input:", value);
    setProfile({ ...profile, desired_keywords_text: value });
  };

  const processKeywords = () => {
    if (!profile.desired_keywords_text) return;
    
    // Process keywords when form is submitted or focus is lost
    const keywords = profile.desired_keywords_text
      .split(/[\n,]/)
      .map(keyword => keyword.trim())
      .filter(keyword => keyword.length > 0);
    
    console.log("Admin processed keywords:", keywords);
    setProfile({ ...profile, desired_keywords: keywords });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">SEO Information</h3>
      
      <div className="space-y-2">
        <Label htmlFor="seo_campaign_goal">SEO Campaign Goal</Label>
        <Input
          id="seo_campaign_goal"
          value={profile.seo_campaign_goal || ''}
          onChange={(e) => setProfile({ ...profile, seo_campaign_goal: e.target.value })}
          placeholder="What is the goal of the SEO campaign?"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="desired_keywords">Desired Keywords</Label>
        <Textarea
          id="desired_keywords"
          value={profile.desired_keywords_text || getKeywordsText()}
          onChange={(e) => handleKeywordsChange(e.target.value)}
          onBlur={processKeywords}
          placeholder="Enter keywords separated by commas or new lines"
          className="min-h-[120px]"
        />
        <p className="text-sm text-muted-foreground">
          Each keyword should be on a new line or separated by commas
        </p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="short_description">Short Description</Label>
        <Textarea
          id="short_description"
          value={profile.short_description || ''}
          onChange={(e) => setProfile({ ...profile, short_description: e.target.value })}
          placeholder="Brief description of the business"
          className="h-20"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="long_description">Long Description</Label>
        <Textarea
          id="long_description"
          value={profile.long_description || ''}
          onChange={(e) => setProfile({ ...profile, long_description: e.target.value })}
          placeholder="Detailed description of the business"
          className="h-32"
        />
      </div>
    </div>
  );
};
