
import { FormBusinessProfile } from "@/components/business/types";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

interface OpeningHoursFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const OpeningHoursFields = ({ profile, setProfile }: OpeningHoursFieldsProps) => {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

  const handleTimeChange = (day: keyof typeof profile.opening_hours_json, type: 'open' | 'close', value: string) => {
    const updatedHours = {
      ...profile.opening_hours_json,
      [day]: {
        ...profile.opening_hours_json[day],
        [type]: value || "--:--"
      }
    };

    setProfile({
      ...profile,
      opening_hours_json: updatedHours,
      opening_hours: `${updatedHours.monday.open}-${updatedHours.monday.close}`
    });
  };

  const formatDayLabel = (day: string) => {
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Opening Hours</h3>
      <div className="grid gap-4">
        {days.map((day) => (
          <div key={day} className="grid grid-cols-3 gap-4 items-center">
            <Label className="capitalize">{formatDayLabel(day)}</Label>
            <div className="space-y-2">
              <Label>Opening Time</Label>
              <Input
                type="time"
                value={profile.opening_hours_json[day]?.open || "--:--"}
                onChange={(e) => handleTimeChange(day, 'open', e.target.value)}
                className="w-full"
                placeholder="--:--"
              />
            </div>
            <div className="space-y-2">
              <Label>Closing Time</Label>
              <Input
                type="time"
                value={profile.opening_hours_json[day]?.close || "--:--"}
                onChange={(e) => handleTimeChange(day, 'close', e.target.value)}
                className="w-full"
                placeholder="--:--"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
