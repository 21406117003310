
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { RichTextEditor } from "@/components/admin/articles/RichTextEditor";
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { LoadingSpinner } from "@/components/routing/LoadingSpinner";

const ArticlePage = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [editedArticle, setEditedArticle] = useState<any>(null);

  // Get user profile for permission checks
  const { data: profile, isLoading: profileLoading } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      try {
        // Get current user session
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) {
          console.log('No active session found in article page');
          return null;
        }

        // Get user profile
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching profile:', error);
          throw error;
        }

        return data;
      } catch (err) {
        console.error('Unexpected error in profile query:', err);
        throw err;
      }
    },
  });

  // Get article data
  const { data: article, isLoading, isError, error } = useQuery({
    queryKey: ['article', articleId],
    queryFn: async () => {
      try {
        if (!articleId) {
          throw new Error('Article ID is required');
        }

        console.log('Fetching article with ID:', articleId);
        
        const { data, error } = await supabase
          .from('articles')
          .select('*')
          .eq('id', articleId)
          .single();

        if (error) {
          console.error('Error fetching article:', error);
          throw error;
        }

        if (!data) {
          throw new Error('Article not found');
        }

        console.log('Article fetched successfully');
        return data;
      } catch (err) {
        console.error('Error in article query:', err);
        throw err;
      }
    },
  });

  // Update article mutation (for admin use)
  const updateArticle = useMutation({
    mutationFn: async (updatedArticle: any) => {
      const { data, error } = await supabase
        .from('articles')
        .update(updatedArticle)
        .eq('id', articleId)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['article', articleId] });
      setIsEditing(false);
      toast({
        title: "Success",
        description: "Article updated successfully",
      });
    },
    onError: (error) => {
      console.error('Error updating article:', error);
      toast({
        title: "Error",
        description: "Failed to update article",
        variant: "destructive",
      });
    },
  });

  // Update article view progress
  useQuery({
    queryKey: ['trackArticleView', articleId],
    queryFn: async () => {
      try {
        // Skip if no article ID or loading
        if (!articleId || isLoading) return null;

        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) return null;

        // Check if view exists
        const { data: existingView, error: viewError } = await supabase
          .from('article_views')
          .select('*')
          .eq('user_id', session.user.id)
          .eq('article_id', articleId)
          .maybeSingle();

        if (viewError) {
          console.error('Error checking article view:', viewError);
          return null;
        }

        // Increment article view counter
        await supabase.rpc('increment_article_views', { article_id_param: articleId });

        // Create or update view
        if (existingView) {
          // Update existing view
          const { error: updateError } = await supabase
            .from('article_views')
            .update({
              progress_percent: 100,
              time_spent_seconds: existingView.time_spent_seconds + 10,
              completed: true,
              updated_at: new Date().toISOString()
            })
            .eq('id', existingView.id);

          if (updateError) console.error('Error updating article view:', updateError);
        } else {
          // Create new view
          const { error: insertError } = await supabase
            .from('article_views')
            .insert({
              user_id: session.user.id,
              article_id: articleId,
              progress_percent: 100,
              time_spent_seconds: 10,
              completed: true
            });

          if (insertError) console.error('Error creating article view:', insertError);
        }

        return { success: true };
      } catch (err) {
        console.error('Error tracking article view:', err);
        return null;
      }
    },
    enabled: !!articleId && !isLoading,
  });

  const handleEdit = () => {
    setEditedArticle(article);
    setIsEditing(true);
  };

  const handleSave = () => {
    updateArticle.mutate(editedArticle);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedArticle(null);
  };

  // Loading state
  if (isLoading || profileLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-8 flex justify-center items-center">
            <LoadingSpinner />
          </div>
        </div>
      </SidebarProvider>
    );
  }

  // Error state
  if (isError) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-8">
            <Card className="p-6 max-w-4xl mx-auto">
              <div className="text-center">
                <h1 className="text-2xl font-bold text-gray-900 mb-4">Article Not Found</h1>
                <p className="text-gray-500 mb-6">
                  {error instanceof Error ? error.message : "The article you're looking for couldn't be loaded."}
                </p>
                <Button onClick={() => navigate('/resources')}>
                  Back to Resources
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </SidebarProvider>
    );
  }

  // Editing form
  if (isEditing) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-8">
            <Card className="p-6 max-w-4xl mx-auto">
              <div className="space-y-4">
                <Input
                  value={editedArticle.title}
                  onChange={(e) => setEditedArticle({ ...editedArticle, title: e.target.value })}
                  placeholder="Title"
                  className="text-2xl font-bold"
                />
                <RichTextEditor
                  content={editedArticle.description}
                  onChange={(content) => setEditedArticle({ ...editedArticle, description: content })}
                />
                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button onClick={handleSave}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </SidebarProvider>
    );
  }

  // Display article
  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <Card className="p-6 max-w-4xl mx-auto">
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h1 className="text-3xl font-bold text-gray-900">{article.title}</h1>
                {profile?.is_admin && (
                  <Button onClick={handleEdit}>
                    Edit Article
                  </Button>
                )}
              </div>
              <div 
                className="prose prose-slate max-w-none"
                dangerouslySetInnerHTML={{ __html: article.description }} 
              />
            </div>
          </Card>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default ArticlePage;
