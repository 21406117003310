
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";

interface DoneForYouSectionProps {
  onBoostBusinessClick: () => void;
}

export const DoneForYouSection = ({ onBoostBusinessClick }: DoneForYouSectionProps) => {
  const { t } = useTranslation();

  return (
    <section className="py-16 px-4 bg-gradient-to-b from-[#F6F6F7] to-white">
      <div className="container mx-auto max-w-6xl">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="order-2 md:order-1">
            <img 
              src="/lovable-uploads/5e30a41a-da39-491b-8ef5-3b0987285af2.png" 
              alt="Business owner using tablet" 
              className="w-full h-auto max-h-[500px] object-contain rounded-lg shadow-lg"
            />
          </div>
          
          <div className="order-1 md:order-2 text-left space-y-6">
            <h2 className="text-3xl md:text-4xl font-bold text-[#221F26] leading-tight animate-fade-in">
              We Launch Your Business—<span className="text-primary">So You Can Focus on Running It</span>
            </h2>
            
            <div className="space-y-4 text-lg text-[#403E43]/80 leading-relaxed animate-fade-in delay-100">
              <p>
                Running a business is hard. Getting found online shouldn't be.
              </p>
              <p>
                Woobound Launchpad is your done-for-you growth engine, putting your business right where customers are searching—Google Maps, local search, and beyond.
              </p>
            </div>
            
            <p className="text-xl font-semibold text-[#e0933c] animate-fade-in delay-150">
              👉 No DIY. No guesswork. Just real results.
            </p>
            
            <div className="pt-4 animate-fade-in delay-200">
              <Button
                size="lg"
                className="bg-[#e0933c] hover:bg-[#e0933c]/90 text-white px-8 py-6 text-lg shadow-lg hover:shadow-xl transition-all"
                onClick={onBoostBusinessClick}
              >
                Boost My Business Now <ChevronRight className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
