
import { MapPin, Phone, Globe, TrendingUp } from 'lucide-react';
import { MetricCard } from '@/components/MetricCard';
import { MetricsData } from './types';

interface MetricsGridProps {
  metrics: MetricsData;
}

export function MetricsGrid({ metrics }: MetricsGridProps) {
  const {
    mobileImpressions,
    desktopImpressions,
    callClicks,
    websiteClicks,
    directionRequests,
  } = metrics;

  return (
    <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-5">
      <MetricCard
        title="Mobile Impressions"
        value={mobileImpressions.toLocaleString()}
        icon={<TrendingUp className="h-4 w-4 text-[#F97316]" />}
      />
      <MetricCard
        title="Desktop Impressions"
        value={desktopImpressions.toLocaleString()}
        icon={<TrendingUp className="h-4 w-4 text-[#F97316]" />}
      />
      <MetricCard
        title="Calls"
        value={callClicks.toLocaleString()}
        icon={<Phone className="h-4 w-4 text-[#F97316]" />}
      />
      <MetricCard
        title="Website Visits"
        value={websiteClicks.toLocaleString()}
        icon={<Globe className="h-4 w-4 text-[#F97316]" />}
      />
      <MetricCard
        title="Direction Requests"
        value={directionRequests.toLocaleString()}
        icon={<MapPin className="h-4 w-4 text-[#F97316]" />}
      />
    </div>
  );
}
