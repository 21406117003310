
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useEffect, useRef } from "react";

export const useProfile = (session: any) => {
  const { toast } = useToast();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { 
    data: profile, 
    error: profileError, 
    isLoading: loading,
    refetch
  } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) {
        console.log('[useProfile] No session user ID, skipping profile fetch', {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        return null;
      }
      
      console.log('[useProfile] Starting profile fetch:', {
        userId: session.user.id,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname,
        queryState: 'starting'
      });

      try {
        async function testQuery1() {
          try {
            const userId1= session?.user?.id;
            const supabaseUrl = "https://bzyguzdxfvnzcddghqfl.supabase.co";
            const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ6eWd1emR4ZnZuemNkZGdocWZsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM4NjczOTksImV4cCI6MjA0OTQ0MzM5OX0.tW7_0ToMEHJe-ROPvPJjI1ThtkAkt92dYxuhXnAh6aM";
            const tableName = "profiles";
            const userId = userId1;

            const headers = {
              "Content-Type": "application/json",
              "apikey": supabaseKey,
              "Authorization": `Bearer ${supabaseKey}`,
            };

            const url = `${supabaseUrl}/rest/v1/${tableName}?id=eq.${userId}`;

            const response = await fetch(url, { method: "GET", headers: headers });
            const data = await response.json();

            const transformedObject = {
              data: data.length > 0 ? data[0] : null,
              error: null,
            };

            console.log("[testQuery] Profile fetched:", transformedObject);
            return transformedObject;

          } catch (err) {
            console.error("[testQuery] Unexpected error:", err);
            return { data: null, error: err };
          }
        }

        const arrayObjectQury = await testQuery1();
        const arrayObjectJson = JSON.parse(JSON.stringify(arrayObjectQury, null, 2));
        const arrayObject = [arrayObjectJson.data];

        console.log("testQueryHkam123:   ", arrayObject);
        const { data = arrayObject[0], error = null } = arrayObject.length > 0 ? { data: arrayObject[0], error: null } : { data: null, error: "No data found" };

        console.log("hdhskdhs  " + JSON.stringify(data, null, 2));
        
        if (error) {
          console.error('[useProfile] Error fetching profile:', {
            error,
            userId: session.user.id,
            timestamp: new Date().toISOString(),
            currentPath: window.location.pathname,
            errorMessage: typeof error === 'object' ? JSON.stringify(error) : String(error)
          });
          throw error;
        }

        if (!data) {
          console.warn('[useProfile] No profile found for user:', {
            userId: session.user.id,
            timestamp: new Date().toISOString(),
            currentPath: window.location.pathname
          });
          return null;
        }

        console.log('[useProfile] Profile fetch response:', {
          hasData: !!data,
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname,
          queryState: 'completed'
        });
        return data;
      } catch (error: unknown) {
        console.error('[useProfile] Unexpected error in profile fetch:', {
          error,
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname,
          errorMessage: error instanceof Error ? error.message : String(error)
        });
        throw error;
      }
    },
    enabled: !!session?.user?.id,
    retry: 2,
    retryDelay: 1000,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (profileError) {
      console.error('[useProfile] Profile fetch error:', {
        error: profileError,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });
      toast({
        title: "Error",
        description: "Failed to load user profile. Please refresh the page.",
        variant: "destructive",
      });
    }
  }, [profileError, toast]);

  useEffect(() => {
    if (loading && session?.user?.id) {
      timeoutRef.current = setTimeout(() => {
        console.warn('[useProfile] Profile fetch timeout exceeded', {
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        refetch();
        toast({
          title: "Warning",
          description: "Profile is taking longer than expected to load. Retrying...",
          variant: "default",
        });
      }, 2000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [loading, session?.user?.id, toast, refetch]);

  return { profile, profileError, loading };
};
